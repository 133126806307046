import React, {useEffect, useState} from "react";
import moment from 'moment';
import {withTranslation} from "react-i18next";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Table from "semantic-ui-react/dist/commonjs/collections/Table";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";

import {deleteArticle, getAllArticlesByAdmin} from "../../../pages/blog/BlogUtil";
import history from "../../../helper/browserHistory";
import userStore from "../userManagement/userStore";
import BlogAuthorEdit from "./BlogAuthorEdit";

let useCustomEventListener = () => {

};
if (typeof document !== "undefined") {
    useCustomEventListener = require('react-custom-events').useCustomEventListener;
}

function deleteSelectedArticle(article, allArticles, setAllArticles) {
    setAllArticles(allArticles.filter(x => {
        return x.id !== article.id
    }))
    deleteArticle(article.id).then(() => {
    });
}

function updateBlogTable(i18n, allArticles, setAllArticles, language, setBlogLink, setEditMode, setCreateNew) {
    if (!userStore.isAdmin && !userStore.isContentCreator && !userStore.isAuthor) {
        history.push("/" + i18n.language + "/")
    }
    return allArticles.map(article =>
        <Table.Row
            key={`blog-management-0-${article.id}`}
            className={"admin-table"}>
            <Table.Cell
                key={`blog-management-1-${article.id}`}
                onClick={() => {
                    setBlogLink(article.blogLink);
                    setEditMode(true);
                    setCreateNew(false);
                }}>
                {moment(article.date).format("DD.MM.YYYY")}
            </Table.Cell>
            <Table.Cell
                key={`blog-management-2-${article.id}`}
                onClick={() => {
                    setBlogLink(article.blogLink);
                    setEditMode(true);
                    setCreateNew(false);
                }}>
                {article.author}
            </Table.Cell>
            <Table.Cell
                key={`blog-management-3-${article.id}`}
                onClick={() => {
                    setBlogLink(article.blogLink);
                    setEditMode(true);
                    setCreateNew(false);
                }}>
                {article.authorName}
            </Table.Cell>
            <Table.Cell
                key={`blog-management-4-${article.id}`}
                onClick={() => {
                    setBlogLink(article.blogLink);
                    setEditMode(true);
                    setCreateNew(false);
                }}>
                {article.header}
            </Table.Cell>
            <Table.Cell
                key={`blog-management-5-${article.id}`}
                onClick={() => {
                    setBlogLink(article.blogLink);
                    setEditMode(true);
                    setCreateNew(false);
                }}>
                {article.blogLink}
            </Table.Cell>
            <Table.Cell
                key={`blog-management-6-${article.id}`}
                onClick={() => {
                    setBlogLink(article.blogLink);
                    setEditMode(true);
                    setCreateNew(false);
                }}>
                {
                    article.published ?
                        <Icon
                            key={`blog-management-7-${article.id}`}
                            name={"check"}/>
                        : <Icon
                            key={`blog-management-8-${article.id}`}
                            name={"times"}/>
                }
            </Table.Cell>
            <Table.Cell key={`blog-management-9-${article.id}`}>
                <Button
                    key={`blog-management-10-${article.id}`}
                    className={"admin-button-warn"}
                    onClick={() => deleteSelectedArticle(article, allArticles, setAllArticles)}>
                    {i18n.t('blog:blog.button_delete')}
                </Button>
            </Table.Cell>
        </Table.Row>
    )
}

const BlogManagement = (props) => {
    const {i18n} = props;
    const [allArticles, setAllArticles] = useState([]);
    const [editMode, setEditMode] = useState(false);
    const [createNew, setCreateNew] = useState(false);
    const [blogLink, setBlogLink] = useState('');

    useEffect(() => {
        getAllArticlesByAdmin().then(result => {
            setAllArticles(result.articles);
        })
        if (typeof window !== "undefined") {
            window.scrollTo(0, 0);
        }
    }, [editMode]);

    if (editMode){
        return <BlogAuthorEdit blogLink={blogLink} newArticle={createNew} onClose={_ => {
            setEditMode(false)
        }}/>
    }else{
        return (
            <div
                key={`blog-management-11`}
                className={"admin-editor"}>
                <Grid key={`blog-management-12`}>
                    <Grid.Row
                        key={`blog-management-13`}
                        columns={2}>
                        <Grid.Column key={`blog-management-14`}>
                            <h1
                                key={`blog-management-15`}
                                className={"admin-primary admin-edit-header"}>
                                {i18n.t("blog:blog.title")}
                            </h1>
                        </Grid.Column>
                        <Grid.Column
                            key={`blog-management-16`}
                            textAlign={"right"}>
                            <Button
                                key={`blog-management-17`}
                                className={"admin-button-primary"}
                                icon
                                labelPosition='right'
                                positive
                                onClick={() => {
                                    setBlogLink('new');
                                    setEditMode(true);
                                    setCreateNew(true);
                                }}>
                                <Icon
                                    key={`blog-management-18`}
                                    name='add'/>
                                {i18n.t("blog:blog.new_article")}
                            </Button>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row key={`blog-management-19`}>
                        <Grid.Column key={`blog-management-20`}>
                            <Table
                                key={`blog-management-21`}
                                basic={"very"}>
                                <Table.Header key={`blog-management-22`}>
                                    <Table.Row key={`blog-management-23`}>
                                        <Table.HeaderCell
                                            key={`blog-management-24`}
                                            className={'admin-primary'}>
                                            {i18n.t("blog:blog.date")}
                                        </Table.HeaderCell>
                                        <Table.HeaderCell
                                            key={`blog-management-25`}
                                            className={'admin-primary'}>
                                            {i18n.t("blog:blog.user")}
                                        </Table.HeaderCell>
                                        <Table.HeaderCell
                                            key={`blog-management-26`}
                                            className={'admin-primary'}>
                                            {i18n.t("blog:blog.author_name")}
                                        </Table.HeaderCell>
                                        <Table.HeaderCell
                                            key={`blog-management-27`}
                                            className={'admin-primary'}>
                                            {i18n.t("blog:blog.header")}
                                        </Table.HeaderCell>
                                        <Table.HeaderCell
                                            key={`blog-management-28`}
                                            className={'admin-primary'}>
                                            {i18n.t("blog:blog.blog_link")}
                                        </Table.HeaderCell>
                                        <Table.HeaderCell
                                            key={`blog-management-29`}
                                            className={'admin-primary'}>
                                            {i18n.t("blog:blog.published")}
                                        </Table.HeaderCell>
                                        <Table.HeaderCell
                                            key={`blog-management-30`}
                                            className={'admin-primary'}>
                                            {i18n.t("blog:blog.button_delete")}
                                        </Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body
                                    key={`blog-management-31`}
                                    className={"admin-primary"}>
                                    {updateBlogTable(i18n, allArticles, setAllArticles, i18n.language, setBlogLink, setEditMode, setCreateNew)}
                                </Table.Body>
                            </Table>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>)
    }
}

export default withTranslation(['blog'])(BlogManagement)
