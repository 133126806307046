import config from "../../../config/main.config";
import {getFromLocalStorage} from "../../../helper/util";

export{
    getTemplate,
    handleSubmit
}

function getTemplate(){
    let returnVal = 0;
    fetch(config.BASE_URL + "component/template", {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": "Bearer " + getFromLocalStorage("token")
        }
    }).then(response => {
        return response.blob();
    }).then((blob) => {
        const href = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', `Template.zip`); //or any other extension
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }).catch(error => {
        console.error("error exporting: ", error);
        returnVal = -1;
    })
    return returnVal;
}

async function handleSubmit(selectedFile){
    const formData = new FormData();

    formData.append('file', selectedFile);

    let result = await fetch(config.BASE_URL + 'component/upload', {
            method: 'POST',
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + getFromLocalStorage("token")
            },
            body: formData,
        }
    );

    result = await result.json();

    return result
}

