export default {
    "MAILER": {
        "USER": "",
        "PASS": "",
        "TO_USER": "",
        "HOST": "",
        "PORT": 465
    },
    "IMAGE_SIZES": {
        "LARGE": 1920,
        "MEDIUM": 1024,
        "SMALL": 320
    },
    "categories": [
        "all",
        "events",
        "hiking",
        "bikeRides",
        "eating",
        "trips",
    ],
    "rooms": [
        "Chambre Romantique",
        "Les Deux Lions",
        "Bellevue",
        "Louis XIV",
        "Napoléon"
    ]
}