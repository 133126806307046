import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import Modal from "semantic-ui-react/dist/commonjs/modules/Modal";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import config from "../../../../config/main.config";
import { Icon } from "semantic-ui-react";

const ColorPreview = (props) => {
	const { theme } = props;
	const [open, setOpen] = useState(false);

	return (
		<Modal
			dimmer={"blurring"}
			open={open}
			onOpen={() => {
				setOpen(true);
			}}
			onClose={() => {
				setOpen(false);
			}}
			trigger={<Button className={"admin-button-primary"}>Preview</Button>}
		>
			<Modal.Header className={`background-${theme}`}>
				<h1>{theme}</h1>
			</Modal.Header>
			{theme === "other" ? (
				<Modal.Content>
					<Grid>
						<Grid.Row>
							<Grid.Column className={`border-highlight text-warn`}>
								Highlight
							</Grid.Column>
						</Grid.Row>
						<Grid.Row>
							<Grid.Column>
								<h2>Scrollup</h2>
								<Icon
									className={"scroll_up_button_image"}
									circular
									size={"big"}
									name={"chevron up"}
								/>
							</Grid.Column>
						</Grid.Row>
					</Grid>
				</Modal.Content>
			) : (
				<Modal.Content className={`background-${theme}`}>
					<Grid>
						<Grid.Row columns={2}>
							<Grid.Column verticalAlign={"middle"}>
								<img
									className={`border-${theme}`}
									key={`logo-1`}
									alt={"SIMPL CMS Logo"}
									style={{ height: "100%", width: "auto" }}
									src={config.BASE_URL_IMAGES + "simpl_rocket.png"}
								/>
							</Grid.Column>
							<Grid.Column>
								<h1 className={`header-${theme}`}>h1</h1>
								<h2 className={`header-${theme}`}>h2</h2>
								<h3 className={`header-${theme}`}>h3</h3>
								<h4 className={`header-${theme}`}>h4</h4>
								<h5 className={`header-${theme}`}>h5</h5>
								<h6 className={`header-${theme}`}>h6</h6>
							</Grid.Column>
						</Grid.Row>
						<Grid.Row>
							<Grid.Column>
								<p className={`text-${theme}`}>
									Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
									diam nonumy eirmod tempor invidunt ut labore et dolore magna
									aliquyam erat, sed diam voluptua. At vero eos et accusam et
									justo duo dolores et ea rebum. Stet clita kasd gubergren, no
									sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem
									ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
									nonumy eirmod tempor invidunt ut labore et dolore magna
									aliquyam erat, sed diam voluptua. At vero eos et accusam et
									justo duo dolores et ea rebum. Stet clita kasd gubergren, no
									sea takimata sanctus est Lorem ipsum dolor sit amet.
								</p>
							</Grid.Column>
						</Grid.Row>
						<Grid.Row>
							<Grid.Column>
								<a className={`link-${theme}`} href={"#"}>
									Hyperlink
								</a>
							</Grid.Column>
						</Grid.Row>
						<Grid.Row>
							<Grid.Column className={`border-highlight text-warn`}>
								Highlight
							</Grid.Column>
						</Grid.Row>
					</Grid>
				</Modal.Content>
			)}
			<Modal.Actions className={`background-${theme}`}>
				<Button className={`button-warn`} onClick={() => setOpen(false)}>
					warning
				</Button>
				<Button className={`button-basic`} onClick={() => setOpen(false)}>
					basic
				</Button>
				<Button className={`button-${theme}`} onClick={() => setOpen(false)}>
					{theme}
				</Button>
			</Modal.Actions>
		</Modal>
	);
};

export default withTranslation(["common", "adminManagement"])(ColorPreview);
