import React from "react";
import PropsList from "../PropsList";
import HeaderButtons from "../HeaderButtons";
const OverlayItem = ({
	elements,
	_key,
	index,
	_props,
	siblings,
	element,
	horizontal,
}) => {
	return (
		<div key={`draggable.0.${_key}.${index}`}>
			<div key={`div.0.${_key}.${index}`} className={"item"}>
				<div
					key={`div.1.${_key}.${index}`}
					className={"dndHeader admin-primary admin-border-secondary"}
				>
					<div className={"grabbing"}>
						<h2 className={"admin-primary"} key={`h2.0.${_key}.${index}`}>
							{element.type}
						</h2>
						<PropsList _props={_props} />
						<br />
					</div>
					<HeaderButtons
						_key={_key}
						index={index}
						elements={elements}
						siblings={siblings}
					/>
				</div>
				<div
					key={`div.2.${_key}.${index}`}
					className={
						(horizontal && element.type.includes("menu")) ||
						element.type === "row"
							? "dndContentHorizontal admin-primary admin-border-secondary"
							: "dndContentVertical admin-primary admin-border-secondary"
					}
				>
					<h3>child elements...</h3>
				</div>
			</div>
		</div>
	);
};

export default OverlayItem;
