import config from "../../../../config/main.config";
import {getFromLocalStorage} from "../../../../helper/util";

export {
    saveEmailConfig,
    saveNewsletterConfig
}

function saveEmailConfig(newMailConfig, setIsLoading) {
    setIsLoading(true);
    fetch(config.BASE_URL + "theme/email", {
        method: "PUT",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": "Bearer " + getFromLocalStorage("token")
        },
        body: JSON.stringify({email: newMailConfig.to, host: newMailConfig.host, user: newMailConfig.user, hash: newMailConfig.password, port: newMailConfig.port})
    }).then(response => {
        if (response.status >= 200 && response.status < 300) {
            response.json().then(json => {
                setIsLoading(false);
                return json;
            })
        } else {
            setIsLoading(false);
            //Error
        }
    }).catch(err => {
        if (process.env.NODE_ENV === "production") {
            setIsLoading(false);
        }
        console.error(err);
    });
}

function saveNewsletterConfig(newNewsletterConfig, setIsLoading) {
    setIsLoading(true);
    fetch(config.BASE_URL + "theme/newsletter", {
        method: "PUT",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": "Bearer " + getFromLocalStorage("token")
        },
        body: JSON.stringify({client: newNewsletterConfig.clientId, login: newNewsletterConfig.login, hash: newNewsletterConfig.password, send_confirm: newNewsletterConfig.sendConfirm, group:newNewsletterConfig.groupId})
    }).then(response => {
        if (response.status >= 200 && response.status < 300) {
            response.json().then(json => {
                setIsLoading(false);
                return json;
            })
        } else {
            setIsLoading(false)
            //Error
        }
    }).catch(err => {
        if (process.env.NODE_ENV === "production") {
            setIsLoading(false);
        }
        console.error(err);
    });
}