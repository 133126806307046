import React from "react";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Modal from "semantic-ui-react/dist/commonjs/modules/Modal";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";

import config from "../../../../config/main.config";
import {getLogo, getFromLocalStorage} from "../../../../helper/util";

export {
    onLogoSelected,
    saveLogo,
    showSelectImage
}

function onLogoSelected(e, setSrc,setError) {
    if (e.target.files && e.target.files.length > 0) {
        if(e.target.files[0].size <= 5000000){
            const fileReader = new FileReader();
            let logoName = e.target.files[0].name;
            let extension = logoName.split('.').pop();
            fileReader.addEventListener(
                'load',
                () => {
                    fetch(config.BASE_URL + 'theme/logo', {
                        method: "POST",
                        headers: {
                            "Accept": "application/json",
                            "Content-Type": "application/json",
                            "Access-Control-Allow-Origin": "*",
                            "Authorization": "Bearer " + getFromLocalStorage("token")
                        },
                        body: JSON.stringify({logo: logoName, file: fileReader.result, fileExtension: extension})
                    }).then(response => {
                        if (response.status >= 200 && response.status < 300) {
                            return response.json().then(json => {
                                if (json) {
                                    setSrc(config.URL + 'images/logos/logo_tmp.' + extension + '?time' + (Date.now()));
                                }
                            });
                        }
                    }).catch(error => console.error(error))
                    //  saveLogo(setSrc, fileReader.result, true);
                },
                false
            )
            fileReader.readAsDataURL(e.target.files[0]);
            setError("")
        }else
            setError("filesize")

    }
}

function saveLogo(setSrc, imageSrc, isUpload, setIsLoading) {
    setIsLoading(true);
    let imageName;
    let timestamp = imageSrc.split('?').pop();
    if (isUpload) {
        imageName = imageSrc.replace(`${config.URL}images/logos/`, '').replace(`?${timestamp}`, '');
    } else {
        imageName = imageSrc.replace(`${config.URL}images/all-images/`, '').replace(`?${timestamp}`, '');
    }
    fetch(config.BASE_URL + "theme/logo", {
        method: "PUT",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": "Bearer " + getFromLocalStorage("token")
        },
        body: JSON.stringify({
            file: imageName,
            isUpload: isUpload
        })
    }).then(response => {
        if (response.status >= 200 && response.status < 300) {
            getLogo('logo').then(logo => {
                setSrc(config.URL + 'images/logos/' + logo + '?time' + (Date.now()));
                setIsLoading(false);
            })
        } else {
            setIsLoading(false);
        }
    }).catch(e => {
        if (process.env.NODE_ENV === "production") {

        }
        console.error(e);
    })
}

function showSelectImage(selectModalOpen, setSelectModalOpen, allImages, setSrc, t) {
    let showAllImages = allImages.map((images, index) =>
        <Grid.Column
            key={`edit-logo-16-${index}`}
            className={"admin-primary"}
            width={4}>
            <img
                key={`edit-logo-17-${index}`}
                className={'ui image'}
                src={config.URL + 'images/all-images/' + images.link}
                alt={'images' + index}
                onClick={_ => {
                    setSrc(config.URL + 'images/all-images/' + images.link);
                    setSelectModalOpen(false);
                }}/>
        </Grid.Column>
    )

    return (
        <Modal
            key={`edit-logo-18`}
            open={selectModalOpen}>
            <Modal.Header
                key={`edit-logo-19`}
                className={"admin-primary"}>
                {t("adminManagement:logo.select_image")}
            </Modal.Header>
            <Modal.Content
                key={`edit-logo-20`}
                className={"admin-primary"}>
                <Grid key={`edit-logo-21`}>
                    <Grid.Row key={`edit-logo-22`}>
                        {showAllImages}
                    </Grid.Row>
                </Grid>
            </Modal.Content>
            <Modal.Actions
                key={`edit-logo-23`}
                className={"admin-primary"}>
                <Button
                    key={`edit-logo-24`}
                    className={"admin-button-primary"}>
                    {t("common:common.select")}
                </Button>
                <Button
                    key={`edit-logo-25`}
                    className={"admin-button-basic"}
                    onClick={_ => setSelectModalOpen(false)}>
                    {t("common:common.cancel")}
                </Button>
            </Modal.Actions>
        </Modal>
    )
}