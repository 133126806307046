import config from "../../config/main.config";
import { getFromLocalStorage } from "../../helper/util";

export {
    getAllArticlesByAdmin,
    getImagesInFolder,
    getAllImages,
    addArticle,
    deleteArticle,
    getFromLocalStorage,
    getArticleByBlogLink,
    getArticleByBlogLinkAdmin,
    getArticlesAtPage,
    uploadImageCallBack
}

function getImagesInFolder(folder) {
    return new Promise(result => fetch(config.BASE_URL + "admin/images/folder-images/" + folder, {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": "Bearer " + getFromLocalStorage("token")
        },
    }).then(function (response) {
        if (response.status >= 200 && response.status < 300) {
            response.json().then(json => {
                return result(json);
            });
        } else {
            console.error(response.status);
        }
    }).catch(() => {
        console.error("error");
    }));
}

function getArticlesAtPage(page, search) {
    let query = "page=" + page;
    if (typeof search !== "undefined" && search.length > 0) {
        query += "&search=" + search;
    }

    return new Promise(result => fetch(config.BASE_URL + "blog/articles?" + query, {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Access-Control-Allow-Origin": "*",
            "Authorization": "Bearer " + getFromLocalStorage("token")
        }
    }).then((response) => response.json()).then(json => {
        return result(json);
    }).catch(error => {
        console.error("error: ", error);
    }))
}

function getAllArticlesByAdmin() {
    return new Promise(resolve => fetch(config.BASE_URL + "blog/admin-articles", {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": "Bearer " + getFromLocalStorage("token")
        }
    }).then(response => {
        if (response.status >= 200 && response.status < 300) {
            response.json().then(json => {
                return resolve(json);
            });
        } else {
            console.error("couldn't fetch articles!")
        }
    }).catch(
        error => {
            console.error(error);
            /*if (error.status === 401) {
                history.replace("/admin-login");
            }*/
        }
    ));
}

async function getArticleByBlogLink(link) {
    const response = await fetch(config.BASE_URL + "blog/article/" + link, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'pragma': "no-cache",
            'cache-control': "no-cache",
            'Access-Control-Allow-Origin': "*",
            "Authorization": "Bearer "
        }
    })

    if (response.status >= 200 && response.status < 300) {
        const data = await response.json()
        return data;
    } else {
        return {
            error: "400"
        };
    }
}

function getArticleByBlogLinkAdmin(link) {
    return new Promise(resolve => fetch(config.BASE_URL + "blog/admin-article/" + link, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'pragma': "no-cache",
            'cache-control': "no-cache",
            'Access-Control-Allow-Origin': "*",
            "Authorization": "Bearer " + getFromLocalStorage("token")
        }
    }).then((response) => {
        if (response.status >= 200 && response.status < 400) {
            response.json().then(json => {
                return resolve(json);
            });
        } else {
            return {
                error: "400"
            };
        }
    }).catch(error => {
        console.error(error);
        return {
            error: "400"
        };
    }));
}

function deleteArticle(articleId) {
    return new Promise(() => fetch(config.BASE_URL + "blog/article/" + articleId, {
        method: "DELETE",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": "Bearer " + getFromLocalStorage("token")
        }
    }).then(response => {
        if (response.status >= 200 && response.status < 300) {
            response.json().then(() => {
                return "delete Okay";
            });
        } else {
            alert("Error deleting article");
        }
    }).catch(
        error => {
            console.error(error);
            /*if (error.status === 401) {
                history.replace("/admin-login");
            }*/
        }
    ));
}

function addArticle(newArticle, isNewArticle) {
    let httpMethod = "PUT";
    if (isNewArticle) {
        httpMethod = "POST";
    }
    return new Promise(resolve => fetch(config.BASE_URL + "blog/article", {
        method: httpMethod,
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": "Bearer " + getFromLocalStorage("token")
        },
        body: JSON.stringify({ article: newArticle })
    }).then(response => {
        if (response.status >= 200 && response.status < 300) {
            return resolve(response);
        } else {
            alert("Error saving article");
        }
    }).catch(
        error => {
            console.error(error);
            /*if (error.status === 401) {
                history.replace("/admin-login");
            }*/
        }
    ));
}

function getAllImages() {
    return new Promise(result => fetch(config.BASE_URL + "blog/images", {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": "Bearer " + getFromLocalStorage("token")
        }
    }).then(response => {
        if (response.status >= 200 && response.status < 300) {
            response.json().then(json => {
                return result(json);
            });
        } else {
            alert("getting images failed");
        }
    }).catch(
        error => {
            console.error(error);
        }
    ));
}

function uploadImageCallBack(file) {
    return new Promise(
        (resolve, reject) => {
            let data = new FormData();
            data.append('image', file);
            fetch(config.BASE_URL + "blog/image-text", {
                method: "POST",
                headers: {
                    "Accept": "application/json",
                    // "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": "Bearer " + getFromLocalStorage("token")
                },
                body: data
            }).then(response => {
                if (response.status >= 200 && response.status < 300) {
                    response.json().then(json => {
                        resolve(json);
                    });

                } else {
                    alert("getting images failed(callback)");
                }
            }).catch(
                error => {
                    console.error(error);
                    reject();
                    alert("Fehler beim Bild-Upload");
                }
            );
            /*        request.post('/blog/image')
                        .send(data)
                        .then(function (res) {
                            console.log(res);
                            let response = JSON.parse(res.text);
                            resolve(response);
                        })
                        .catch(() => {
                            reject();
                        });*/

        }
    );
}