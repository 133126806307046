import React, { useState } from "react";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import PropsList from "../PropsList";
import Menu from "semantic-ui-react/dist/commonjs/collections/Menu";

let emitCustomEvent = () => {};

let useCustomEventListener = () => {};
if (typeof document !== "undefined") {
	emitCustomEvent = require("react-custom-events").emitCustomEvent;
	useCustomEventListener =
		require("react-custom-events").useCustomEventListener;
}

const Item = ({ element, index, _props, siblings, active }) => {
	const [itemCount, setItemCount] = useState(1);
	const { attributes, listeners, setNodeRef, transform, transition } =
		useSortable({ id: element.key });

	const style = {
		transform: CSS.Transform.toString(transform),
		transition,
	};
	useCustomEventListener("itemCount", (count) => {
		setItemCount(count);
	});
	if (active && active.key === element.key) {
		return (
			<div
				className={"dndActive"}
				ref={setNodeRef}
				style={style}
				key={`draggable.0.${element.key}.${index}`}
			>
				<div key={`div.0.${element.key}.${index}`}>
					<div key={`div.1.${element.key}.${index}`}>
						<div
							className={"grab admin-dnd-item-header"}
							{...attributes}
							{...listeners}
						></div>
					</div>
				</div>
			</div>
		);
	}
	return (
		<div
			ref={setNodeRef}
			style={style}
			key={`draggable.0.${element.key}.${index}`}
		>
			<div
				key={`div.0.${element.key}.${index}`}
				className={"dndItem admin-dnd-item-border"}
			>
				<div key={`div.1.${element.key}.${index}`} className={"dndHeader"}>
					<div
						className={"grab admin-dnd-item-header"}
						{...attributes}
						{...listeners}
					>
						<h2
							className={"admin-primary"}
							key={`h2.0.${element.key}.${index}`}
						>
							{element.type}
						</h2>
						<PropsList _props={_props} />
						<br />
					</div>
					<Menu secondary key={`button.group.0.${element.key}.${index}`}>
						<Menu.Item
							key={`button.1.${element.key}.${index}`}
							onClick={() => emitCustomEvent("addProps", element.key)}
						>
							<Icon name={"options"} />
						</Menu.Item>
						<Menu.Item
							key={`button.2.${index}`}
							disabled={
								(element.key.includes("row") && siblings >= 16) ||
								itemCount > 99 ||
								element.type === "adminMenu" ||
								element.type === "languageSelection"
							}
							onClick={() => emitCustomEvent("copyElement", element.key)}
						>
							<Icon key={`icon.2`} name={"copy outline"} />
						</Menu.Item>
						<Menu.Item
							key={`button.0.${element.key}.${index}`}
							disabled={element.type === "adminMenu"}
							position={"right"}
							onClick={() => emitCustomEvent("removeElement", element.key)}
						>
							<Icon name={"remove"} />
						</Menu.Item>
					</Menu>
				</div>
				<div
					key={`div.2.${element.key}.${index}`}
					className={"dndContentVertical admin-dnd-item-content"}
				>
					{element.content}
				</div>
			</div>
		</div>
	);
};

export default Item;
