import React from "react";
import isValidCSSUnit from "is-valid-css-unit";
import history from "../../../../helper/browserHistory";

import config from "../../../../config/main.config";
import {getFromLocalStorage} from "../../../../helper/util";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";

export {
    getAllDimensions,
    saveDimensions,
    // eslint-disable-next-line no-use-before-define
    showAllDimensions
}

const showAllDimensions = (dimensions, setDimension, oldDimensions) => {
    return dimensions.map((variable, index) => (
        <div
            key={`edit-dimensions-9-${index}`}
            className={'row'}>
            <div
                key={`edit-dimensions-10-${index}`}
                className={'seven wide column'}>
                <label
                    key={`edit-dimensions-11-${index}`}
                    className={"admin-primary"}><b key={`edit-dimensions-12`}>{variable.name}</b></label><br
                key={`edit-dimensions-13`}/>
                <input
                    key={`edit-dimensions-14-${index}`}
                    className={"admin-disabled admin-border-secondary"}
                    style={{
                        marginTop: '5px',
                        width: '100%',
                        borderStyle: 'dashed',
                        borderWidth: '2px',
                        borderRadius: '3px',
                        padding: '8px 4px'
                    }}
                    disabled
                    value={oldDimensions.length > 0 ? oldDimensions[index].value : ''}/>
            </div>
            <div
                key={`edit-dimensions-15-${index}`}
                style={{width: 'fit-content'}}
                className={'column'}><br key={`edit-dimensions-16`}/>
                <Icon
                    key={`edit-dimensions-17-${index}`}
                    style={{marginTop: '10px'}}
                    name={'chevron right'}/></div>
            <div
                key={`edit-dimensions-18-${index}`}
                className={'seven wide column'}>
                <br key={`edit-dimensions-19-${index}`}/>
                <input
                    key={`edit-dimensions-20-${index}`}
                    className={variable.name === "--font_family" ? 'admin-primary admin-border-secondary' : isValidCSSUnit(variable.value) ? 'admin-primary admin-border-secondary' : 'admin-primary admin-border-highlight'}
                    style={{
                        marginTop: '6px',
                        width: '100%',
                        outline: 'none',
                        borderWidth: '1px',
                        borderRadius: '3px',
                        padding: '8px 4px'
                    }}
                    value={variable.value}
                    onChange={e => {
                        let newValue = [...dimensions];
                        newValue[index].value = e.target.value;
                        setDimension(newValue);
                    }}/>
            </div>
        </div>
    ));
}

function getAllDimensions() {
    return new Promise(resolve => fetch(config.BASE_URL + "theme/dimension", {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": "Bearer " + getFromLocalStorage("token")
        }
    }).then(response => {
        if (response.status >= 200 && response.status < 300) {
            response.json().then(json => {
                return resolve(json);
            });
        }
    }).catch(e => console.error(e)));
}

function saveDimensions(dimensions, setIsLoading, setSaveWasClicked) {
    setIsLoading(true);
    fetch(config.BASE_URL + "theme/dimension", {
        method: "PUT",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": "Bearer " + getFromLocalStorage("token")
        },
        body: JSON.stringify({dimension: dimensions})
    }).then(response => {
        if (response.status === 200) {
                setIsLoading(false);
                return true;
        } else {
            setIsLoading(false);
        }
        setSaveWasClicked(false);
    }).catch(e => {
        if (process.env.NODE_ENV === "production") {
            setIsLoading(false);
            history.go(0);
        }
        console.error(e)
    })

}