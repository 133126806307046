import React from "react";
import List from "semantic-ui-react/dist/commonjs/elements/List";

const PropsList = ({_props}) => {
    return (
        <List divided horizontal size={"mini"}>
            {_props.map((prop, index) => (
                    <List.Item key={`proplist-0-${index}`}>
                        {
                            prop.value && prop.value !== '' && prop.name !== 'id' && prop.name !== 'namespace' && prop.name !== 'textStore' ?
                                <List.Content key={`proplist-1-${index}`}>{prop.name}: "{prop.value.toString()}"</List.Content>
                                : null
                        }
                    </List.Item>
                )
            )}
        </List>
    )
}

export default PropsList;