import React, {useEffect, useState} from "react";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import {withTranslation} from "react-i18next";
import {Menu} from "semantic-ui-react";

import * as utils from "./utils";
import DropdownMenu from "../../../dropdownMenu/DropdownMenu";
import DropdownItem from "../../../dropdownMenu/DropdownItem";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import ColorPreview from "../colorPreview/ColorPreview";

let emitCustomEvent = () => {
};

let useCustomEventListener = () => {
};
if (typeof document !== "undefined") {
    emitCustomEvent = require('react-custom-events').emitCustomEvent;
    useCustomEventListener = require('react-custom-events').useCustomEventListener;
}

const EditTheme = (props) => {
    const {t} = props;
    const [themeColors, setThemeColors] = useState({});
    const [presets, setPresets] = useState([]);
    const [validationError, setValidationError] = useState(false);
    const [activeTheme, setActiveTheme] = useState("primary");
    const [isLoading, setIsLoading] = useState(false);
    const [initialTheme, setInitialTheme] = useState({});
    const [update, setUpdate] = useState(false);

    useEffect(() => {
        utils.getThemes().then(allThemes => {
            setThemeColors(allThemes);
        });

        utils.getThemes().then(allThemes => {
            setInitialTheme(allThemes);
        })

        utils.getPresetNames().then(presetNames => {
            setPresets(presetNames.map((name) => <div key={name} className={"dropdown-item"} onClick={() => {
                utils.getPresets(name).then(preset => {
                    setThemeColors(preset)
                })
            }}>{name.replace(".scss", "").replace(/_/g, "").replace(/-/g, " ")}</div>))
        })

    }, []);

    useCustomEventListener("colorChanged", parameters => {
        let newColor = [...themeColors[parameters.theme]];
        newColor[parameters.index].color = parameters.color;

        if (parameters.color.length > 0) {
            newColor[parameters.index].hexError = !parameters.color.match(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/);
        }
        setThemeColors({...themeColors});
        if (!newColor[parameters.index].hexError) {
            document.documentElement.style.setProperty(parameters.name, parameters.color);
            setValidationError(false);
        } else {
            setValidationError(true);
        }
    });

    useEffect(() => {
        for (const key in themeColors) {
            for (let i = 0; i < themeColors[key].length; i++) {
                document.documentElement.style.setProperty(themeColors[key][i].name, themeColors[key][i].color)
            }
        }
        emitCustomEvent("themeChanged", themeColors);
    }, [themeColors])


    return (
        <div
            key={`edit-theme-0`}
            className={"admin-editor"}>
            <Grid key={`edit-theme-1`}>
                <Grid.Row
                    key={`edit-theme-2`}
                    columns={2}>
                    <Grid.Column key={`edit-theme-3`}>
                        <h1
                            key={`edit-theme-4`}
                            className={"admin-primary admin-edit-header"}>
                            {t("adminManagement:theme.header")}
                        </h1>
                        <p key={`edit-theme-5`}>{t("adminManagement:theme.description")}</p>
                    </Grid.Column>
                    <Grid.Column
                        key={`edit-theme-6`}
                        textAlign={"right"}>
                        <DropdownMenu
                            key={`edit-theme-7`}
                            className={"admin-button-basic"}
                            style={{marginRight: '1rem', floated: 'right'}}
                            name={t("adminManagement:theme.presets")}>
                            {
                                presets.map((preset, index) => (
                                    <DropdownItem key={`edit-theme-8-${index}`}>{preset}</DropdownItem>
                                ))
                            }
                        </DropdownMenu>
                        <Button.Group key={`edit-theme-9`}>
                            <ColorPreview theme={activeTheme}/>
                            <Button
                                key={`edit-theme-10`}
                                style={{marginRight: '1rem'}}
                                className={"admin-button-primary"}
                                loading={isLoading}
                                disabled={isLoading || validationError}
                                onClick={_ => {
                                    setThemeColors(initialTheme);
                                }}>
                                {t("common:common.reset")}
                            </Button>
                            <Button
                                key={`edit-theme-11`}
                                disabled={isLoading || validationError}
                                style={{marginRight: '1rem'}}
                                className={"admin-button-save"}
                                loading={isLoading}
                                onClick={_ => {
                                    emitCustomEvent("rebuild");
                                    utils.saveThemeColor(themeColors, setIsLoading);
                                    setInitialTheme(themeColors);
                                    setUpdate(!update);
                                }}>
                                {t("common:common.save")}
                            </Button>
                        </Button.Group>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <div key={`edit-theme-12`}>
                <Menu
                    key={`edit-theme-13`}
                    pointing
                    secondary>
                    {utils.showAllThemeGroups(themeColors, activeTheme, setActiveTheme)}
                </Menu>
                {utils.showThemes(themeColors, setThemeColors, setValidationError, activeTheme)}
            </div>
        </div>
    )
}

export default withTranslation(['common', 'adminManagement'])(EditTheme);