import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { Menu } from "semantic-ui-react";
import { useHistory, useLocation } from "react-router-dom";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import MenuComponent from "../../menu/Menu";
import userStore from "../userManagement/userStore";

const AdminSideMenu = ({ i18n }) => {
	const history = useHistory();
	const location = useLocation();
	const [activeItem, setActiveItem] = useState(
		location.search.replace("?", ""),
	);
	const [customizerOpen, setCustomizerOpen] = useState(true);
	const [editorOpen, setEditorOpen] = useState(false);

	useEffect(() => {
		if (editorOpen) {
			setCustomizerOpen(false);
		}
	}, [editorOpen]);

	useEffect(() => {
		if (customizerOpen) {
			setEditorOpen(false);
		}
	}, [customizerOpen]);

	useEffect(() => {
		setActiveItem(location.search.replace("?", ""));
	}, [location]);

	const closeTabs = () => {
		setCustomizerOpen(false);
		setEditorOpen(false);
	};

	const setActive = (active) => {
		history.push({ search: `?${active}` });
	};

	const isCustomizerActive = (activeItem) => {
		switch (activeItem) {
			case "logo":
			case "favicon":
			case "css":
			case "social":
			case "language":
			case "theme":
			case "typography":
			case "mail":
			case "newsletter":
			case "gtm":
			case "cookie":
				return true;
			default:
				return false;
		}
	};

	const isComponentActive = (activeItem) => {
		switch (activeItem) {
			case "page":
			case "edit-page":
			case "menu":
			case "edit-menu":
			case "header":
			case "edit-header":
			case "footer":
			case "edit-footer":
				return true;
			default:
				return false;
		}
	};

	return (
		<MenuComponent
			key={"side-menu-0"}
			fluid
			vertical
			className={"editMenu admin-secondary "}
		>
			{userStore.isAdmin && (
				<>
					<Menu.Item
						key={"side-menu-1"}
						fitted
						className={
							"menuItem admin-menu" +
							(isCustomizerActive(activeItem) ? "-active" : "")
						}
						active={isCustomizerActive(activeItem)}
					>
						<Menu.Header
							key={"side-menu-2"}
							style={{
								paddingTop: 13,
								paddingBottom: 20,
								marginBottom: 0,
							}}
							onClick={() => {
								let open = !customizerOpen;
								setCustomizerOpen(open);
								if (open) setActive("logo");
							}}
						>
							<Icon name={"paint brush"} />
							{i18n.t("adminManagement:adminManagement.customizer")}
						</Menu.Header>
						<Menu.Menu
							key={"side-menu-3"}
							className={isCustomizerActive(activeItem) ? "show" : "hide"}
						>
							<Menu.Item
								key={"side-menu-4"}
								className={
									"menuItem admin-menu" +
									(activeItem === "logo" ? "-active-sub" : "")
								}
								name={i18n.t("adminManagement:adminManagement.logo")}
								active={activeItem === "logo"}
								onClick={() => {
									setActive("logo");
								}}
							/>
							<Menu.Item
								key={"side-menu-5"}
								className={
									"menuItem admin-menu" +
									(activeItem === "favicon" ? "-active-sub" : "")
								}
								name={i18n.t("adminManagement:adminManagement.favicon")}
								active={activeItem === "favicon"}
								onClick={() => {
									setActive("favicon");
								}}
							/>
							<Menu.Item
								key={"side-menu-6"}
								className={
									"menuItem admin-menu" +
									(activeItem === "css" ? "-active-sub" : "")
								}
								name={i18n.t("adminManagement:adminManagement.css")}
								active={activeItem === "css"}
								onClick={() => {
									setActive("css");
								}}
							/>
							<Menu.Item
								key={"side-menu-7"}
								className={
									"menuItem admin-menu" +
									(activeItem === "social" ? "-active-sub" : "")
								}
								name={i18n.t("adminManagement:adminManagement.social")}
								active={activeItem === "social"}
								onClick={() => {
									setActive("social");
								}}
							/>
							<Menu.Item
								key={"side-menu-8"}
								className={
									"menuItem admin-menu" +
									(activeItem === "language" ? "-active-sub" : "")
								}
								name={i18n.t("adminManagement:adminManagement.language")}
								active={activeItem === "language"}
								onClick={() => {
									setActive("language");
								}}
							/>
							<Menu.Item
								key={"side-menu-9"}
								className={
									"menuItem admin-menu" +
									(activeItem === "theme" ? "-active-sub" : "")
								}
								name={i18n.t("adminManagement:adminManagement.theme")}
								active={activeItem === "theme"}
								onClick={() => {
									setActive("theme");
								}}
							/>
							<Menu.Item
								key={"side-menu-10"}
								className={
									"menuItem admin-menu" +
									(activeItem === "typography" ? "-active-sub" : "")
								}
								name={i18n.t("adminManagement:adminManagement.typography")}
								active={activeItem === "typography"}
								onClick={() => {
									setActive("typography");
								}}
							/>
							<Menu.Item
								key={"side-menu-11"}
								className={
									"menuItem admin-menu" +
									(activeItem === "mail" ? "-active-sub" : "")
								}
								name={i18n.t("adminManagement:adminManagement.mail")}
								active={activeItem === "mail"}
								onClick={() => {
									setActive("mail");
								}}
							/>
							<Menu.Item
								key={"side-menu-12"}
								className={
									"menuItem admin-menu" +
									(activeItem === "newsletter" ? "-active-sub" : "")
								}
								name={i18n.t("adminManagement:adminManagement.newsletter")}
								active={activeItem === "newsletter"}
								onClick={() => {
									setActive("newsletter");
								}}
							/>
							<Menu.Item
								key={"side-menu-13"}
								className={
									"menuItem admin-menu" +
									(activeItem === "gtm" ? "-active-sub" : "")
								}
								name={i18n.t(
									"adminManagement:adminManagement.google_tag_manager",
								)}
								active={activeItem === "gtm"}
								onClick={() => {
									setActive("gtm");
								}}
							/>
							<Menu.Item
								key={"side-menu-14"}
								className={
									"menuItem admin-menu" +
									(activeItem === "cookie" ? "-active-sub" : "")
								}
								name={i18n.t("adminManagement:adminManagement.cookie_banner")}
								active={activeItem === "cookie"}
								onClick={() => {
									setActive("cookie");
								}}
							/>
						</Menu.Menu>
					</Menu.Item>
					<Menu.Item
						key={"side-menu-15"}
						fitted
						className={
							"menuItem admin-menu" +
							(isComponentActive(activeItem) ? "-active" : "")
						}
						active={isComponentActive(activeItem)}
					>
						<Menu.Header
							key={"side-menu-16"}
							style={{
								paddingTop: 13,
								paddingBottom: 20,
								marginBottom: 0,
							}}
							onClick={() => {
								setActive("page");
							}}
						>
							<Icon key={"side-menu-17"} name={"edit"} />
							{i18n.t("adminManagement:adminManagement.site_builder")}
						</Menu.Header>
						<Menu.Menu
							key={"side-menu-18"}
							className={isComponentActive(activeItem) ? "show" : "hide"}
						>
							<Menu.Item
								key={"side-menu-19"}
								className={
									"menuItem admin-menu" +
									(activeItem === "page" || activeItem === "edit-page"
										? "-active-sub"
										: "")
								}
								name={i18n.t("adminManagement:adminManagement.page_builder")}
								active={activeItem === "page" || activeItem === "edit-page"}
								onClick={() => {
									setActive("page");
								}}
							/>
							<Menu.Item
								key={"side-menu-20"}
								className={
									"menuItem admin-menu" +
									(activeItem === "menu" || activeItem === "edit-menu"
										? "-active-sub"
										: "")
								}
								name={i18n.t("adminManagement:adminManagement.menu_builder")}
								active={activeItem === "menu" || activeItem === "edit-menu"}
								onClick={() => {
									setActive("menu");
								}}
							/>
							<Menu.Item
								key={"side-menu-21"}
								className={
									"menuItem admin-menu" +
									(activeItem === "header" || activeItem === "edit-header"
										? "-active-sub"
										: "")
								}
								name={i18n.t("adminManagement:adminManagement.header_builder")}
								active={activeItem === "header" || activeItem === "edit-header"}
								onClick={() => {
									setActive("header");
								}}
							/>
							<Menu.Item
								key={"side-menu-22"}
								className={
									"menuItem admin-menu" +
									(activeItem === "footer" || activeItem === "edit-footer"
										? "-active-sub"
										: "")
								}
								name={i18n.t("adminManagement:adminManagement.footer_builder")}
								active={activeItem === "footer" || activeItem === "edit-footer"}
								onClick={() => {
									setActive("footer");
								}}
							/>
						</Menu.Menu>
					</Menu.Item>
				</>
			)}
			{userStore.isAdmin && (
				<Menu.Item
					key={"side-menu-23"}
					className={
						"menuItem admin-menu" +
						(activeItem === "component" ? "-active" : "")
					}
					active={activeItem === "component"}
					onClick={() => {
						setActive("component");
						closeTabs();
					}}
				>
					<Menu.Header>
						<Icon key={"side-menu-24"} name={"upload"} />
						{i18n.t("adminManagement:adminManagement.component_upload")}
					</Menu.Header>
				</Menu.Item>
			)}
			{(userStore.isAdmin ||
				userStore.isContentCreator ||
				userStore.isAuthor) && (
				<Menu.Item
					key={"side-menu-25"}
					className={
						"menuItem admin-menu" + (activeItem === "blog" ? "-active" : "")
					}
					active={activeItem === "blog"}
					onClick={() => {
						setActive("blog");
						closeTabs();
					}}
				>
					<Menu.Header key={"side-menu-26"}>
						<Icon key={"side-menu-27"} name={"newspaper"} />
						{i18n.t("adminManagement:adminManagement.blog_management")}
					</Menu.Header>
				</Menu.Item>
			)}
			{userStore.isAdmin && (
				<Menu.Item
					key={"side-menu-28"}
					className={
						"menuItem admin-menu" + (activeItem === "user" ? "-active" : "")
					}
					active={activeItem === "user"}
					onClick={() => {
						setActive("user");
						closeTabs();
					}}
				>
					<Menu.Header key={"side-menu-29"}>
						<Icon key={"side-menu-30"} name={"user"} />
						{i18n.t("adminManagement:adminManagement.user_management")}
					</Menu.Header>
				</Menu.Item>
			)}
			{(userStore.isAdmin || userStore.isContentCreator) && (
				<>
					<Menu.Item
						key={"side-menu-34"}
						className={
							"menuItem admin-menu" +
							(activeItem === "mailManagement" ? "-active" : "")
						}
						active={activeItem === "mailManagement"}
						onClick={() => {
							setActive("mailManagement");
							closeTabs();
						}}
					>
						<Menu.Header key={"side-menu-35"}>
							<Icon key={"side-menu-36"} name={"mail"} />
							{i18n.t("adminManagement:adminManagement.mail_management")}
						</Menu.Header>
					</Menu.Item>
				</>
			)}
			{userStore.isAdmin ? (
				<Menu.Item
					key={"side-menu-37"}
					className={
						"menuItem admin-menu" + (activeItem === "options" ? "-active" : "")
					}
					active={activeItem === "options"}
					onClick={() => {
						setActive("options");
						closeTabs();
					}}
				>
					<Menu.Header key={"side-menu-38"}>
						<Icon key={"side-menu-39"} name={"options"} />
						{i18n.t("adminManagement:adminManagement.options")}
					</Menu.Header>
				</Menu.Item>
			) : null}
		</MenuComponent>
	);
};

export default withTranslation("adminManagement")(AdminSideMenu);
