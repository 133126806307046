import {
    handleBack,
    handleNew,
    handleEdit,
    validateName,
    createElement,
    createKey,
    updateHierarchy,
    insertIntoHierarchy,
    updateElements,
    flattenHierarchy,
    loadConfig,
    loadElements,
    buildConfig,
    getDefinitions,
    getCategories,
    validateLink,
    removeElement,
    copyElement,
    updateKey,
    updateParentKey
} from "../utils";
import config from "../../../../config/main.config";
import {getFromLocalStorage} from "../../../../helper/util";

export {
    saveHeaderConfig,
    buildHeader,
    getUserHeaders,
    deleteUserHeader,
    handleBack,
    handleNew,
    handleEdit,
    validateName,
    createElement,
    createKey,
    updateHierarchy,
    insertIntoHierarchy,
    updateElements,
    flattenHierarchy,
    loadConfig,
    loadElements,
    buildConfig,
    getDefinitions,
    getCategories,
    validateLink,
    removeElement,
    copyElement,
    updateKey,
    updateParentKey
}

async function saveHeaderConfig(title, sticky, hierarchy) {
    title = title.replace(" ", "_");
    if (title === '')
        title = 'Unnamed';
    let headerConfig = {
        title: title,
        sticky: sticky,
        components: []
    };
    let components = [];
    buildConfig(components, hierarchy[0]);
    headerConfig.components = components;

    let response = await fetch(config.BASE_URL + "headerBuilder/save", {
        method: "PUT",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": "Bearer " + getFromLocalStorage("token")
        },
        body: JSON.stringify({config: headerConfig})
    });

    if(response.status === 200)
        return 0;
    else
        return -1;
}

async function buildHeader(name) {
    let response = await fetch(config.BASE_URL + "headerBuilder/build", {
        method: "PUT",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": "Bearer " + getFromLocalStorage("token")
        },
        body: JSON.stringify({name: name})
    });

    if(response.status === 200)
        return 0;
    else
        return -1;
}

function getUserHeaders() {
    return new Promise(resolve => fetch(config.BASE_URL + "headerBuilder/userHeaders", {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": "Bearer " + getFromLocalStorage("token")
        }
    }).then(response => {
        if (response.status >= 200 && response.status < 300) {
            response.json().then(json => {
                    return resolve(json)
                }
            );
        }
    })).catch(error => console.error(error))
}

function deleteUserHeader(name) {
    return new Promise(resolve => fetch(config.BASE_URL + "headerBuilder/userHeaders/" + name, {
        method: "DELETE",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": "Bearer " + getFromLocalStorage("token")
        }
    }).then(response => {
        if (response.status >= 200 && response.status < 300) {
            response.json().then(json => {
                    return resolve(json)
                }
            );
        }
    })).catch(error => console.error(error))
}