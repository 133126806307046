import React from "react";
import {Menu} from "semantic-ui-react";
import ColorPicker from "../colorPicker";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";

import config from "../../../../config/main.config";
import {getFromLocalStorage} from "../../../../helper/util";


export {
    saveThemeColor,
    getThemes,
    getPresets,
    getPresetNames,
    // eslint-disable-next-line no-use-before-define
    showThemes,
    // eslint-disable-next-line no-use-before-define
    showAllThemeGroups
}

function saveThemeColor(themeColors, setIsLoading) {
    setIsLoading(true);
    fetch(config.BASE_URL + "theme/themes", {
        method: "PUT",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": "Bearer " + getFromLocalStorage("token")
        },
        body: JSON.stringify({changedThemes: themeColors})
    }).then(response => {
        if (response.status >= 200 && response.status < 300) {
            return response.json().then(_ => {
                setIsLoading(false);
            });
        } else {
            setIsLoading(false);
        }
    }).catch(error => {
        if (process.env.NODE_ENV === "production") {
            setIsLoading(false);
        }
        console.error(error);
    })
}

function getThemes() {
    return new Promise(resolve => fetch(config.BASE_URL + "theme/themes", {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": "Bearer " + getFromLocalStorage("token")
        }
    }).then(response => {
        if (response.status >= 200 && response.status < 300) {
            response.json().then(json => {
                    return resolve(json);
                }
            );
        }
    })).catch(error => console.error(error))
}

async function getPresets(presetName) {
    let result = await fetch(config.BASE_URL + "theme/themes/preset/" + presetName, {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": "Bearer " + getFromLocalStorage("token")
        }
    });
    if(result.status >= 200 && result.status < 300){
        return await result.json();
    }else {
        console.error("Error: ", result.status);
        return [];
    }
}

function getPresetNames() {
    return new Promise(resolve => fetch(config.BASE_URL + "theme/themes/preset", {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": "Bearer " + getFromLocalStorage("token")
        }
    }).then(response => {
        if (response.status >= 200 && response.status < 300) {
            response.json().then(json => {
                    return resolve(json);
                }
            );
        }
    })).catch(error => console.error(error))
}

const showThemes = (themeColors, setThemeColors, setValidationError, theme) => {
    if (themeColors[theme] === undefined) {
        return;
    }

    return (
        <Grid key={`edit-theme-14`}>
            <Grid.Row key={`edit-theme-15`}>
                {
                    themeColors[theme].map((value, index) => (
                        <Grid.Column
                            key={`edit-theme-16-${index}`}
                            widescreen={3}
                            computer={4}
                            tablet={5}
                            mobile={7}
                            textAlign={'center'}
                            style={{padding: 15}}
                        >
                            <h4
                                key={`edit-theme-17-${index}`}
                                className={'admin-primary'}>
                                {value.name.replace("--", "").replace(/_/g, " ")}
                            </h4>
                            {
                                value.name === '--highlight' ?
                                    <label key={`edit-theme-18-${index}`}>used for warnings, negative buttons and
                                        highlights.</label>
                                    : null
                            }
                            <ColorPicker
                                key={`edit-theme-19-${index}`}
                                index={index}
                                theme={theme}
                                value={value}/>
                        </Grid.Column>
                    ))}
            </Grid.Row>
        </Grid>
    )
}

const showAllThemeGroups = (themeColors, activeTheme, setActiveTheme) => {
    return Object.keys(themeColors).map((name, index) => (
        <Menu.Item
            key={`edit-theme-20-${index}`}
            active={activeTheme === name}
            onClick={_ => {
                setActiveTheme(name);
            }}
        >{name}
        </Menu.Item>
    ))
}