import React, {useState} from "react";
import {Grid} from "semantic-ui-react";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import {withTranslation} from "react-i18next";

import gtm from "../../../../../shared/gtm.config.json"
import * as utils from "./utils";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";

let emitCustomEvent = () => {
};

if (typeof document !== "undefined") {
    emitCustomEvent = require('react-custom-events').emitCustomEvent;
}

const EditGtm = (props) => {
    const {i18n, state} = props
    const [gtmConfig, setGtmConfig] = useState(state.gtmId)
    const [update, setUpdate] = useState(false);

    return (
        <div
            key={`edit-gtm-0`}
            className={"admin-editor"}>
            <Grid key={`edit-gtm-1`}>
                <Grid.Row
                    key={`edit-gtm-2`}
                    columns={2}>
                    <Grid.Column key={`edit-gtm-3`}>
                        <h1
                            key={`edit-gtm-4`}
                            className={"admin-primary admin-edit-header"}>
                            {i18n.t("adminManagement:gtm.header")}
                        </h1>
                        <p key={`edit-gtm-5`}>{i18n.t("adminManagement:gtm.description")}</p>
                    </Grid.Column>
                    <Grid.Column
                        key={`edit-gtm-6`}
                        textAlign={"right"}>
                        <Button
                            key={`edit-gtm-7`}
                            className={"admin-button-save"}
                            type='submit'
                            onClick={() => {
                                emitCustomEvent("rebuild");
                                emitCustomEvent('updateGtm',gtmConfig);
                                utils.saveConfig(gtmConfig);
                                setUpdate(!update);
                            }}>
                            {i18n.t("common:common.save")}
                        </Button>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row key={`edit-gtm-8`}>
                    <Grid.Column key={`edit-gtm-9`}>
                        <h4
                            key={`edit-gtm-10`}
                            className={"admin-primary"}>
                            {i18n.t("adminManagement:gtm.tag")}
                        </h4>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row key={`edit-gtm-11`}>
                    <Grid.Column
                        key={`edit-gtm-12`}
                        width={7}>
                        <input
                            key={`edit-gtm-13`}
                            className={"admin-disabled admin-border-secondary"}
                            style={{
                                marginTop: '5px',
                                width: '100%',
                                borderStyle: 'dashed',
                                borderWidth: '2px',
                                borderRadius: '3px',
                                padding: '8px 4px'
                            }}
                            disabled
                            value={gtm.gtmId}/>
                    </Grid.Column>
                    <Grid.Column key={`edit-gtm-14`}>
                        <Icon
                            key={`edit-gtm-15`}
                            style={{marginTop: '10px'}}
                            name={'chevron right'}/>
                    </Grid.Column>
                    <Grid.Column
                        key={`edit-gtm-16`}
                        width={7}>
                        <input
                            key={`edit-gtm-17`}
                            className={'admin-primary admin-border-secondary'}
                            style={{
                                marginTop: '6px',
                                width: '100%',
                                outline: 'none',
                                borderWidth: '1px',
                                borderRadius: '3px',
                                padding: '8px 4px'
                            }}
                            placeholder='GTM-XXXXXXX'
                            value={gtmConfig.gtmId}
                            onChange={(e) => {
                                setGtmConfig({
                                    gtmId: e.target.value
                                })
                            }}/>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </div>
    )
}


export default withTranslation(['common', 'adminManagement'])(EditGtm)