import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import React from "react";

export {
    closeInput,
    showIcons
}

function closeInput(setEditModalOpen, setEditValue) {
    setEditModalOpen(false);
    setEditValue("");
}

function showIcons(search, allIcons, setEditValue, ALL_ICONS_IN_ALL_CONTEXTS) {
    allIcons.current = ALL_ICONS_IN_ALL_CONTEXTS.map((name, index) => <Icon name={name} key={index}/>);
    let searchIconElements = [];
    for (let i = 0; i < allIcons.current.length; i++) {
        if (search.length === 0 || allIcons.current[i].props.name.includes(search)) {
            searchIconElements.push(
                <Grid.Column width={2}
                             key={i}
                             textAlign={"center"}
                             style={{"paddingTop": "10px"}}
                             onClick={_ => setEditValue(allIcons.current[i].props.name)}>
                    <Icon size={"huge"} name={allIcons.current[i].props.name}/>
                    <span>{allIcons.current[i].props.name}</span>
                </Grid.Column>
            );
        }
    }

    return (
        <Grid>
            <Grid.Row>
                {searchIconElements}
            </Grid.Row>
        </Grid>
    )
}