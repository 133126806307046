import React from "react";

import EditLogo from "../../components/adminComponents/customizer/EditLogo";
import EditFavicon from "../../components/adminComponents/customizer/EditFavicon";
import EditCSS from "../../components/adminComponents/customizer/EditCSS";
import EditLanguage from "../../components/adminComponents/customizer/EditLanguage";
import EditSocialMedia from "../../components/adminComponents/customizer/EditSocialMedia";
import EditTheme from "../../components/adminComponents/customizer/EditTheme";
import EditDimensions from "../../components/adminComponents/customizer/EditDimensions";
import EditMail from "../../components/adminComponents/customizer/EditMail";
import EditGtm from "../../components/adminComponents/customizer/EditGtm";
import EditCookieBanner from "../../components/adminComponents/customizer/EditCookieBanner";
import EditPage from "../../components/adminComponents/siteBuilder/EditPage";
import EditMenu from "../../components/adminComponents/siteBuilder/EditMenu";
import EditHeader from "../../components/adminComponents/siteBuilder/EditHeader";
import EditFooter from "../../components/adminComponents/siteBuilder/EditFooter";
import ComponentUpload from "../../components/adminComponents/componentUpload";
import BlogManagement from "../../components/adminComponents/blogManagement";
import MailManagement from "../../components/adminComponents/mailManagement";
import UserManagement from "../../components/adminComponents/userManagement";
import Options from "../../components/adminComponents/options";


export {
    renderComponent,
    validQuery
}

function renderComponent(active, admin, adminState) {
    switch (active) {
        case "logo":
            return <EditLogo/>
        case "favicon":
            return <EditFavicon/>
        case "css":
          return <EditCSS/>
        case "social":
            return <EditSocialMedia state={adminState.social}/>
        case "language":
            return <EditLanguage/>
        case "theme":
            return <EditTheme/>
        case "typography":
            return <EditDimensions/>
        case "mail":
            return <EditMail type={'mail'} state={adminState}/>
        case "newsletter":
            return <EditMail type={'newsletter'} state={adminState}/>
        case "gtm":
            return <EditGtm state={adminState.gtm}/>
        case "cookie":
            return <EditCookieBanner state={adminState.cookie}/>
        case "page":
            return <EditPage/>
        case "menu":
            return <EditMenu/>
        case "header":
            return <EditHeader/>
        case "footer":
            return <EditFooter/>
        case "edit-page":
            return <EditPage/>
        case "edit-menu":
            return <EditMenu/>
        case "edit-header":
            return <EditHeader/>
        case "edit-footer":
            return <EditFooter/>
        case "blog":
            return <BlogManagement/>
        case "user":
            return <UserManagement/>
        case "mailManagement":
            return <MailManagement/>
        case "component":
            return <ComponentUpload/>
        case "options":
            return <Options/>
        default:
            if (admin)
                return <EditLogo/>
            else
                return <BlogManagement/>
    }
}

function validQuery(query) {
    switch (query) {
        case '?logo':
        case '?favicon':
        case '?css':
        case '?social':
        case '?language':
        case '?theme':
        case '?typography':
        case '?mail':
        case '?newsletter':
        case '?gtm':
        case '?cookie':
        case '?page':
        case '?edit-page':
        case '?menu':
        case '?edit-menu':
        case '?header':
        case '?edit-header':
        case '?footer':
        case '?edit-footer':
        case '?component':
        case '?blog':
        case '?user':
        case '?mailManagement':
        case '?options':
            return true;
        default:
            return false;
    }

}