import React from "react";
import {withTranslation} from "react-i18next";
import Table from "semantic-ui-react/dist/commonjs/collections/Table";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Checkbox from "semantic-ui-react/dist/commonjs/modules/Checkbox";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";

import config from "../../../config/main.config"
import {getFromLocalStorage} from "../../../helper/util";
import userStore from "../userManagement/userStore";
import history from "../../../helper/browserHistory";
import Message from "semantic-ui-react/dist/commonjs/collections/Message";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";

class MailManagement extends React.Component {

    constructor(props) {
        super(props);
        if (!userStore.isAdmin && !userStore.isContentCreator) {
            history.push("/" + props.i18n.language + "/")
        }
        this.state = {
            messages: [],
            loading: false,
            id: ""
        }
    }

    componentDidMount() {
        this.getAllMessages()
    }

    getAllMessages() {
        fetch(config.BASE_URL + 'contact/all', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + getFromLocalStorage("token")
            }
        }).then(response => {
            if (response.status >= 200 && response.status < 300) {
                response.json().then(json => {
                    this.setState({...this.state, messages: json, loading: false, id: ""});

                });
            } else {
                console.error("Error getting messages!")
            }
        }).catch(err => {
            console.error(err);
        });
    }

    retrySend(message) {
        message.isRetry = true;

        fetch(config.BASE_URL + 'contact', {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + getFromLocalStorage("token")
            },
            body: JSON.stringify(message)
        }).then(response => {
            if (response.status >= 200 && response.status < 300) {
                response.json().then((json) => {
                    this.getAllMessages()
                    message.loading = false;
                    return json;
                })
            } else {
                console.error("Error sending message")
            }
        }).catch(err => {
            console.error(err);
        });
    }


    showAllMessages() {
        return this.state.messages.map((message, index) => {
            return (<Table.Row
                    key={`mail-management-0-${index}`}
                    className={"admin-table"}>
                    <Table.Cell key={`mail-management-1-${index}`}>{message.subject}</Table.Cell>
                    <Table.Cell key={`mail-management-2-${index}`}>{message.email}</Table.Cell>
                    <Table.Cell key={`mail-management-3-${index}`}>{message.name}</Table.Cell>
                    <Table.Cell key={`mail-management-4-${index}`}>{message.text}</Table.Cell>
                    <Table.Cell key={`mail-management-5-${index}`}>
                        <Checkbox
                            key={`mail-management-6-${index}`}
                            disabled
                            readOnly
                            checked={message.newsletter}
                            label={message.newsletter ? this.props.i18n.t('contact:contact.subscribed') :
                                this.props.i18n.t('contact:contact:not_subscribed')}
                        />
                    </Table.Cell>
                    <Table.Cell key={`mail-management-7-${index}`}>
                        {message.sendSuccessfull ? this.props.i18n.t('common:common.successful') : this.props.i18n.t('common:common:failed')}
                    </Table.Cell>
                    <Table.Cell key={`mail-management-8-${index}`}>
                        <Button
                            key={`mail-management-9-${index}`}
                            className={"admin-button-primary"}
                            loading={this.state.loading && message.id === this.state.id}
                            disabled={this.state.loading || message.sendSuccessfull}
                            onClick={() => {
                                this.retrySend(message);
                                this.setState({...this.state, loading: true, id: message.id})
                            }}
                            content={this.props.i18n.t('common:common.retry')}/></Table.Cell>
                </Table.Row>
            )
        })
    }

    render() {
        const {i18n} = this.props;
        if (this.state.messages.length > 0) {
            return (
                <div
                    key={`mail-management-10`}
                    className={"admin-editor"}>
                    <Grid key={`mail-management-11`}>
                        <Grid.Row key={`mail-management-12`}>
                            <Grid.Column
                                key={`mail-management-13`}
                                className={'admin-edit-top-column'}>
                                <h1
                                    key={`mail-management-14`}
                                    className={"admin-primary admin-edit-header"}>
                                    {i18n.t("contact:contact.title")}
                                </h1>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row key={`mail-management-15`}>
                            <Grid.Column key={`mail-management-16`}>
                                <Table
                                    key={`mail-management-17`}
                                    basic={'very'}>
                                    <Table.Header key={`mail-management-18`}>
                                        <Table.Row key={`mail-management-19`}>
                                            <Table.HeaderCell
                                                key={`mail-management-20`}
                                                className={'admin-primary'}>
                                                {i18n.t('contact:contact.subject')}
                                            </Table.HeaderCell>
                                            <Table.HeaderCell
                                                key={`mail-management-21`}
                                                className={'admin-primary'}>
                                                {i18n.t('contact:contact.mail')}
                                            </Table.HeaderCell>
                                            <Table.HeaderCell
                                                key={`mail-management-22`}
                                                className={'admin-primary'}>
                                                {i18n.t('contact:contact.name')}
                                            </Table.HeaderCell>
                                            <Table.HeaderCell
                                                key={`mail-management-23`}
                                                className={'admin-primary'}>
                                                {i18n.t('contact:contact.message')}
                                            </Table.HeaderCell>
                                            <Table.HeaderCell
                                                key={`mail-management-24`}
                                                className={'admin-primary'}>
                                                {i18n.t('nav:titles.newsletter')}
                                            </Table.HeaderCell>
                                            <Table.HeaderCell
                                                key={`mail-management-25`}
                                                className={'admin-primary'}>
                                                {i18n.t('contact:contact.send_mail')}
                                            </Table.HeaderCell>
                                            <Table.HeaderCell
                                                key={`mail-management-26`}
                                                className={'admin-primary'}>
                                                {i18n.t("common:common.retry")}
                                            </Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body
                                        key={`mail-management-27`}
                                        className={"admin-primary"}>
                                        {this.showAllMessages()}
                                    </Table.Body>
                                </Table>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </div>
            )
        } else {
            return (
                <div
                    key={`mail-management-28`}
                    className={"admin-editor"}>
                    <Message
                        key={`mail-management-29`}
                        info
                        style={{textAlign: "center"}}>
                        <Message.Header
                            key={`mail-management-30`}>{i18n.t("contact:contact.no_messages_header")}</Message.Header>
                        <p key={`mail-management-31`}>{i18n.t("contact:contact.no_messages_body")} <Icon
                            name={"frown outline"}/></p>
                    </Message>
                </div>
            )
        }
    }
}

export default withTranslation(['contact', 'common'])(MailManagement)
