import React, {useEffect, useState} from "react";
import {withTranslation} from "react-i18next";
//import {useCodeMirror} from "@uiw/react-codemirror";
import * as utils from "./utils";
import {css} from '@codemirror/lang-css';
import {oneDark} from '@codemirror/theme-one-dark';
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";

let emitCustomEvent = () => {
};

if (typeof document !== "undefined") {
    emitCustomEvent = require('react-custom-events').emitCustomEvent;
}

const EditCSS = (props) => {
    return(<div>Error on Mac</div>)
    /*
    let editor = React.useRef();
    const {t} = props;
    const [customCSS, setCustomCSS] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const [update, setUpdate] = useState(false);

    useEffect(() => {
        utils.getCustomCSS().then(content => {
            setCustomCSS(content);
        })
    }, [])

    let {setContainer} = useCodeMirror({
        container: editor.current,
        extensions: [css()],
        value: customCSS,
        autoFocus: true,
        theme: oneDark,
        onChange: (value => {
            setCustomCSS(value)
        })
    });


    useEffect(() => {
        if (editor.current) {
            setContainer(editor.current);
        }
    }, [editor.current]);

    return (
        <div
            key={`edit-css-0`}
            className={"admin-editor"}>
            <Grid key={`edit-css-1`}>
                <Grid.Row
                    key={`edit-css-2`}
                    columns={2}>
                    <Grid.Column key={`edit-css-3`}>
                        <h1
                            key={`edit-css-4`}
                            className={"admin-primary admin-edit-header"}>
                            {t("adminManagement:css.header")}
                        </h1>
                        <p key={`edit-css-5`}>{t("adminManagement:css.description")}</p>
                    </Grid.Column>
                    <Grid.Column
                        key={`edit-css-6`}
                        textAlign={"right"}>
                        <Button
                            key={`edit-css-7`}
                            disabled={isLoading}
                            id={'saveCSS'}
                            loading={isLoading}
                            className={"admin-button-save"}
                            onClick={_ => {
                                emitCustomEvent("rebuild");
                                utils.addCustomCSS(customCSS, setIsLoading).then(_ => {
                                    setUpdate(!update);
                                });
                            }}>
                            {t("common:common.save")}
                        </Button>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row centered>
                    <Grid.Column>
                        <h2 className={"admin-edit-header"}>Custom CSS</h2>
                        <div className={'css-code-editor admin-primary admin-border-primary'} style={{width: "100%"}}
                             ref={editor}/>
                    </Grid.Column>
                </Grid.Row>
            </Grid>

        </div>
    )

     */
}

export default withTranslation(['common', 'edit'])(EditCSS)