import React from "react";
import {SortableContext, useSortable, verticalListSortingStrategy} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';

import Item from "../Item"
import Menu from "../Menu";
import MenuItem from "../MenuItem";
import HeaderButtons from "../HeaderButtons";
import PropsList from "../PropsList";
import * as utils from "../Grid/utils";

const Dropdown = ({elements, _key, index, vertical, _props, siblings, active}) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({id: _key});

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };
    if (active && active.key === _key) {
        return utils.renderPreview(setNodeRef,style,_key,index,attributes,listeners,elements,verticalListSortingStrategy);
    }
    return (
        <div ref={setNodeRef} style={style} key={`draggable.0.${_key}.${index}`}>
            <div key={`div.0.${_key}.${index}`}
                 style={style}
                 className={"dndItem admin-dnd-item-border"}
            >
                <div
                    key={`div.1.${_key}.${index}`}
                    className={"dndHeader"}>
                    <div
                        className={"grab admin-dnd-item-header"}
                        {...attributes}
                        {...listeners}>
                        <h2 className={"admin-primary"} key={`h2.0.${_key}.${index}`}>dropdown menu</h2>
                        <PropsList _props={_props}/><br/>
                    </div>
                    <HeaderButtons _key={_key} index={index} elements={elements} siblings={siblings}/>
                </div>
                <SortableContext
                    items={elements}
                    strategy={verticalListSortingStrategy}>
                    <div
                        key={`div.2.${_key}.${index}`}
                        className={"dndContentVertical admin-dnd-item-content"}>
                        {
                            elements.map((element, index) => (
                                <div key={`div.3.${_key}.${index}`} className={"flexGrow"}>
                                    {
                                        (element.type === 'menu' || element.type === 'submenu') ?
                                            <Menu key={`menu.0.${_key}.${index}`}
                                                  elements={element.children}
                                                  index={index}
                                                  _key={element.key}
                                                  vertical={vertical}
                                                  _props={element.props}
                                                  siblings={elements.length}
                                                  active={active}/>
                                            : element.type === 'menuItem' || element.type === 'dropdownItem' ?
                                            <MenuItem key={`menuitem.0.${_key}.${index}`}
                                                      elements={element.children}
                                                      index={index}
                                                      type={element.type}
                                                      _key={element.key}
                                                      _props={element.props}
                                                      siblings={elements.length}
                                                      active={active}/>
                                            : element.type === 'dropdown' ?
                                                <Dropdown key={`dropdown.0.${_key}.${index}`}
                                                          elements={element.children}
                                                          index={index}
                                                          _key={element.key}
                                                          _props={element.props}
                                                          siblings={elements.length}
                                                          active={active}/>
                                                : <Item key={`item.0.${_key}.${index}`}
                                                        element={element}
                                                        index={index}
                                                        _props={element.props}
                                                        siblings={elements.length}
                                                        active={active}/>
                                    }
                                </div>
                            ))}
                    </div>
                </SortableContext>
            </div>
            )}
        </div>
    )
}

export default Dropdown