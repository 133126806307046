import React, {useState} from "react";
import {withTranslation} from "react-i18next";
import * as utils from "./utils";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import ComponentList from "../componentList";

const ComponentUpload = ({i18n}) => {
    const inputRef = React.useRef(null);
    const [selectedFile, setSelectedFile] = useState({type: "none", name: "none"});
    const [isSelected, setIsSelected] = useState(false);
    const [upload, setUpload] = useState('');
    const config = '' +
        '{\n' +
        '   "name": "sample",\n' +
        '   "dependencies":{\n' +
        '       "react": "^16.13.1"\n' +
        '   },\n' +
        '   "definition":{\n' +
        '       "sample": {\n' +
        '           "tag": "Sample",\n' +
        '           "import": "Sample from \'../../../userContent/userComponents/Sample\'",\n' +
        '           "childrenEnabled": false,\n' +
        '           "category": "custom",\n' +
        '           "props":{\n' +
        '               "propName":{\n' +
        '                   "type": "string",\n' +
        '                   "required": false,\n' +
        '                   "tooltip": "sample prop",\n' +
        '                   "options": [""]\n' +
        '               }\n' +
        '           }\n' +
        '       }\n' +
        '   }\n' +
        '}'

    const changeHandler = (event) => {
        setSelectedFile(event.target.files[0]);
        setIsSelected(true);
    };

    const handleUpload = () => {
        setUpload('uploading');
        setIsSelected(false);
        utils.handleSubmit(selectedFile).then(result => {
            //console.log(result)
            setUpload(result.state);
        });
    };

    return (
        <div
            key={`upload-0`}
            className={"admin-editor"}>
            <Grid key={`upload-1`}>
                <Grid.Row
                    key={`upload-2`}
                    columns={2}>
                    <Grid.Column key={`upload-3`}>
                        <h1
                            key={`upload-4`}
                            className={'admin-primary admin-edit-header'}>
                            {i18n.t("adminManagement:customComponent.heading")}
                        </h1>
                        <label className={"admin-highlight"}>After uploading component an administrator has to run npm install.</label>
                        <p key={`upload-5`}>{i18n.t("adminManagement:customComponent.description")}</p>
                    </Grid.Column>
                    <Grid.Column
                        key={`upload-6`}
                        textAlign={"right"}>
                        <Button.Group key={`upload-7`}>
                            <Button
                                key={`upload-8`}
                                style={{marginRight: "1rem"}}
                                className={"admin-button-primary"}
                                onClick={() => utils.getTemplate()}>
                                {i18n.t("adminManagement:customComponent.download_template")}
                            </Button>
                            <Button
                                key={`upload-9`}
                                className={"admin-button-save"}
                                onClick={handleUpload}
                                disabled={upload !== "" || selectedFile.type !== "application/zip" || selectedFile.name.toLowerCase().match(/navigation.\w*/) || selectedFile.name.toLowerCase().match(/footer.\w*/) || selectedFile.name.toLowerCase().match(/\w*page.\w*/) || selectedFile.name.toLowerCase().match(/\w*menu.\w*/)}
                                loading={upload === "uploading"}
                            >
                                {i18n.t("adminManagement:customComponent.upload")}
                            </Button>
                        </Button.Group>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row
                    key={`upload-10`}
                    columns={2}>
                    <Grid.Column key={`upload-11`}>
                        {
                            upload === "" ?
                                <Button
                                    key={`upload-12`}
                                    as={"label"}
                                    className={"admin-button-primary"}
                                    htmlFor={"conponent.upload"}>
                                    {i18n.t("adminManagement:customComponent.choose_file")}
                                </Button>
                                : <Button
                                    key={`upload-13`}
                                    className={"admin-button-primary"}
                                    disabled={upload === "uploading"}
                                    loading={upload === "uploading"}
                                    onClick={() => {
                                        setUpload("");
                                        setIsSelected(false);
                                        setSelectedFile({type: "none", name: "none"});
                                        inputRef.current.value = '';
                                    }}>
                                    {i18n.t("adminManagement:customComponent.dismiss")}
                                </Button>
                        }
                        <input
                            key={`upload-14`}
                            style={{display: "none"}}
                            ref={inputRef}
                            id={"conponent.upload"}
                            type="file"
                            name="file"
                            onChange={changeHandler}/>
                        {
                            isSelected ?
                                <>
                                    {
                                        selectedFile.name.toLowerCase().match(/navigation.\w*/) ?
                                            <>
                                                <h2
                                                    key={`upload-15`}
                                                    className={'admin-primary admin-edit-header'}>
                                                    {i18n.t("adminManagement:customComponent.filename")}
                                                </h2>
                                                <p
                                                    key={`upload-16`}
                                                    className={"admin-highlight"}>
                                                    {selectedFile.name}
                                                </p>
                                                <p
                                                    key={`upload-17`}
                                                    className={"admin-highlight"}>
                                                    "Navigation" {i18n.t("adminManagement:customComponent.naming_error")}
                                                </p>
                                            </>
                                            : selectedFile.name.toLowerCase().match(/footer.\w*/) ?
                                                <>
                                                    <h2
                                                        key={`upload-18`}
                                                        className={'admin-primary admin-edit-header'}>
                                                        {i18n.t("adminManagement:customComponent.filename")}
                                                    </h2>
                                                    <p
                                                        key={`upload-19`}
                                                        className={"admin-highlight"}>
                                                        {selectedFile.name}
                                                    </p>
                                                    <p
                                                        key={`upload-20`}
                                                        className={"admin-highlight"}>
                                                        "Footer" {i18n.t("adminManagement:customComponent.")}
                                                    </p>
                                                </>
                                                : selectedFile.name.toLowerCase().match(/\w*page.\w*/) ?
                                                    <>
                                                        <h2
                                                            key={`upload-21`}
                                                            className={'admin-primary admin-edit-header'}>
                                                            {i18n.t("adminManagement:customComponent.filename")}
                                                        </h2>
                                                        <p
                                                            key={`upload-22`}
                                                            className={"admin-highlight"}>
                                                            {selectedFile.name}
                                                        </p>
                                                        <p
                                                            key={`upload-23`}
                                                            className={"admin-highlight"}>
                                                            "Page" {i18n.t("adminManagement:customComponent.")}
                                                        </p>
                                                    </>
                                                    : selectedFile.name.toLowerCase().match(/\w*menu.\w*/) ?
                                                        <>
                                                            <h2
                                                                key={`upload-24`}
                                                                className={'admin-primary admin-edit-header'}>
                                                                {i18n.t("adminManagement:customComponent.filename")}
                                                            </h2>
                                                            <p
                                                                key={`upload-25`}
                                                                className={"admin-highlight"}>
                                                                {selectedFile.name}
                                                            </p>
                                                            <p
                                                                key={`upload-26`}
                                                                className={"admin-highlight"}>
                                                                "Menu" {i18n.t("adminManagement:customComponent.")}
                                                            </p>
                                                        </>
                                                        : <>
                                                            <h2
                                                                key={`upload-27`}
                                                                className={'admin-primary admin-edit-header'}>
                                                                {i18n.t("adminManagement:customComponent.filename")}
                                                            </h2>
                                                            <p key={`upload-28`}>{selectedFile.name}</p>
                                                        </>
                                    }

                                    {
                                        selectedFile.type !== "application/zip" ?
                                            <>
                                                <h2
                                                    key={`upload-29`}
                                                    className={'admin-primary admin-edit-header'}>
                                                    {i18n.t("adminManagement:customComponent.filetype")}
                                                </h2>
                                                <p
                                                    key={`upload-30`}
                                                    className={"admin-highlight"}>
                                                    {selectedFile.type} {i18n.t("adminManagement:customComponent.type_error")}</p>
                                            </>

                                            : <>
                                                <h2
                                                    key={`upload-31`}
                                                    className={'admin-primary admin-edit-header'}>
                                                    {i18n.t("adminManagement:customComponent.filetype")}
                                                </h2>
                                                <p key={`upload-32`}>{selectedFile.type}</p>
                                            </>
                                    }

                                </>
                                : null
                        }
                        {
                            upload === "uploading" ?
                                <h2
                                    key={`upload-33`}
                                    className={'admin-primary admin-edit-header'}>
                                    {i18n.t("adminManagement:customComponent.upload")}
                                </h2>
                                : upload === "success" ?
                                    <>
                                        <h2
                                            key={`upload-34`}
                                            className={'admin-primary admin-edit-header'}>
                                            {i18n.t("adminManagement:customComponent.uploading")}
                                        </h2>
                                        <p key={`upload-35`}>
                                            {upload}, {i18n.t("adminManagement:customComponent.rebuild_required")}
                                        </p>
                                    </>
                                    : upload !== "" ?
                                        <>
                                            <h2
                                                key={`upload-36`}
                                                className={'admin-primary admin-edit-header'}>
                                                {i18n.t("adminManagement:customComponent.upload_failed")}
                                            </h2>
                                            <p
                                                key={`upload-37`}
                                                className={"admin-highlight"}>
                                                {upload}
                                            </p>
                                        </>
                                        : null
                        }
                    </Grid.Column>
                    <Grid.Column key={`upload-38`}>
                        <h2
                            key={`upload-39`}
                            className={'admin-primary admin-edit-header'}>
                            {i18n.t("adminManagement:customComponentInstructions.header")}
                        </h2>
                        <h3
                            key={`upload-40`}
                            className={'admin-primary admin-edit-header'}>
                            {i18n.t("adminManagement:customComponentInstructions.structure")}
                        </h3>
                        <ul key={`upload-41`}>
                            <li key={`upload-42`}>{i18n.t("adminManagement:customComponentInstructions.component_folder")}</li>
                            <ul key={`upload-43`}>
                                <li key={`upload-44`}>{i18n.t("adminManagement:customComponentInstructions.main_component")} (Name.js)</li>
                                <li key={`upload-45`}>{i18n.t("adminManagement:customComponentInstructions.index_file")} (index.js)</li>
                                <li key={`upload-46`}>{i18n.t("adminManagement:customComponentInstructions.util_file")} (utils.js)</li>
                                <li key={`upload-47`}>{i18n.t("adminManagement:customComponentInstructions.scss_file")} (name.module.scss)</li>
                            </ul>
                            <li key={`upload-48`}>{i18n.t("adminManagement:customComponentInstructions.config_file")} (Name.config.json)</li>
                        </ul>
                        <h3
                            key={`upload-49`}
                            className={'admin-primary admin-edit-header'}>
                            {i18n.t("adminManagement:customComponentInstructions.main_component")}
                        </h3>
                        <p key={`upload-50`}>
                            {i18n.t("adminManagement:customComponentInstructions.main_component_description")}
                        </p>
                        <h3
                            key={`upload-51`}
                            className={'admin-primary admin-edit-header'}>
                            {i18n.t("adminManagement:customComponentInstructions.index_file")}
                        </h3>
                        <p key={`upload-52`}>{i18n.t("adminManagement:customComponentInstructions.index_file_description")}</p>
                        <h3
                            key={`upload-53`}
                            className={'admin-primary admin-edit-header'}>
                            {i18n.t("adminManagement:customComponentInstructions.config_file")}
                        </h3>
                        <div key={`upload-54`}>
                            <p key={`upload-54-1`}>
                                {i18n.t("adminManagement:customComponentInstructions.config_file_description")}
                            </p><br key={`upload-55`}/>
                            <pre key={`upload-56`}>
                                {config}
                            </pre>
                        </div>
                        <ul key={`upload-57`}>
                            <li key={`upload-58`}>{i18n.t("adminManagement:customComponentInstructions.config_name")}</li>
                            <li key={`upload-59`}>{i18n.t("adminManagement:customComponentInstructions.config_tag")}</li>
                            <li key={`upload-60`}>{i18n.t("adminManagement:customComponentInstructions.config_import")}</li>
                            <li key={`upload-61`}>{i18n.t("adminManagement:customComponentInstructions.config_prop_type")}</li>
                            <ul key={`upload-62`}>
                                <li key={`upload-63`}>string</li>
                                <li key={`upload-64`}>number</li>
                                <li key={`upload-65`}>boolean</li>
                                <li key={`upload-66`}>enum</li>
                            </ul>
                            <li key={`upload-67`}>{i18n.t("adminManagement:customComponentInstructions.config_prop_enum")}</li>
                            <li key={`upload-68`}>{i18n.t("adminManagement:customComponentInstructions.config_prop_options")}</li>
                        </ul>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row key={`upload-69`}>
                    <Grid.Column key={`upload-70`}>
                        <ComponentList key={`upload-71`}/>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </div>
    );
}

export default withTranslation('adminManagement')(ComponentUpload);
