import React, {useEffect, useState} from "react";
import {withTranslation} from "react-i18next";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import {useHistory, useLocation} from "react-router-dom";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import * as utils from "./utils";
import UserContentTable from "../UserContentTable/UserContentTable";
import EditView from "../EditView/EditView";

let emitCustomEvent = () => {
};

let useCustomEventListener = () => {

};
if (typeof document !== "undefined") {
    emitCustomEvent = require('react-custom-events').emitCustomEvent;
    useCustomEventListener = require('react-custom-events').useCustomEventListener;
}

const EditHeader = (props) => {
    const location = useLocation();
    const {i18n} = props;
    //creates the default layout grid; adminMenu is always added and cannot be removed.
    const [elements, setElements] = useState([
        utils.createElement('grid', 'page-edit', 'grid.0', '-1', "", [{
            name: "className",
            value: "background-secondary text-secondary"
        }]),
        utils.createElement('row', 'page-edit', 'row.0', 'grid.0', "", []),
        utils.createElement('column', 'page-edit', 'column.0', 'row.0', "", [{name: 'width', value: '2'}]),
        utils.createElement('column', 'page-edit', 'column.1', 'row.0', "", [{name: 'width', value: '12'}]),
        utils.createElement('languageSelection', 'page-edit', 'languageSelection.0', 'column.1', "", []),
        utils.createElement('column', 'page-edit', 'column.2', 'row.0', "", [{name: 'width', value: '2'}]),
    ]);
    const [userHeaders, setUserHeaders] = useState([]);
    const [update] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [title, setTitle] = useState('Unnamed');
    const [sticky, setSticky] = useState(false);
    const [used, setUsed] = useState(false);
    const type = "header_builder";
    let hierarchy = [elements[0]];

    utils.updateHierarchy(elements, hierarchy);
    const history = useHistory();

    useEffect(() => {
        utils.getUserHeaders().then(headers => {
            setUserHeaders(headers)
        });
        setEditMode(location.search === "?edit-header");
    }, []);

    useCustomEventListener('updateContent', () => {
        utils.getUserHeaders().then(headers => {
            setUserHeaders(headers)
        })
    });

    useEffect(() => {
        emitCustomEvent('itemCount', elements.length);
    }, [update]);

    useCustomEventListener('setElements', _elements => {
        setElements(_elements);
    });

    useCustomEventListener('setHierarchy', _hierarchy => {
        hierarchy = _hierarchy;
    })

    useCustomEventListener('setEditMode', _editMode => {
        setEditMode(_editMode);
    });

    useCustomEventListener('setTitle', _title => {
        setTitle(_title);
    });

    useCustomEventListener('setSticky', _sticky => {
        setSticky(_sticky);
    });

    useCustomEventListener('clickedUsed', _used => {
        setUsed(_used);
    });

    return (
        <div
            key={`edit-header-0`}
            className={"admin-editor"}>
            <Grid key={`edit-header-1`}>
                <Grid.Row
                    key={`edit-header-2`}
                    columns={2}>
                    <Grid.Column key={`edit-header-3`}>
                        <h1
                            key={`edit-header-4`}
                            className={"admin-primary"}>
                            {i18n.t("adminManagement:header.header")}
                        </h1>
                        <p key={`edit-header-4-1`}>{i18n.t("adminManagement:header.description")}</p>
                    </Grid.Column>
                    <Grid.Column
                        key={`edit-header-5`}
                        textAlign={"right"}>
                        {
                            !editMode ?
                                <Button
                                    key={`edit-header-6`}
                                    className={"admin-button-primary"}
                                    onClick={() => {
                                        setUsed(false);
                                        utils.handleNew(history, setTitle, null, setEditMode, setElements, hierarchy, elements, type);
                                    }}>
                                    {i18n.t("adminManagement:header.new")}
                                </Button>
                                : null
                        }
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row key={`edit-header-7`}>
                    <Grid.Column key={`edit-header-8`}>
                        {
                            editMode ?
                                <EditView
                                    key={`edit-header-9`}
                                    type={type}
                                    hierarchy={hierarchy}
                                    elements={elements}
                                    history={history}
                                    _title={title}
                                    _link={null}
                                    isUsed={used}
                                isSticky={sticky}/>
                                : <UserContentTable
                                    key={`edit-header-10`}
                                    type={type}
                                    contentToShow={userHeaders}
                                    history={history}
                                    hierarchy={hierarchy}
                                    elements={elements}
                                    _title={title}
                                    _link={null}/>
                        }
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </div>
    )
}

export default withTranslation(['common', 'adminManagement'])(EditHeader);