import React, {useEffect, useState} from "react";
import Select from "react-select";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import {withTranslation} from "react-i18next";

import {getAllLanguageOptions, getLanguage} from "../../../../helper/util";
import * as utils from "./utils";
import {Dropdown, Input} from "semantic-ui-react";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";

let emitCustomEvent = () => {
};

if (typeof document !== "undefined") {
    emitCustomEvent = require('react-custom-events').emitCustomEvent;
}

const allLanguages = utils.getAvailableLanguages();

const EditLanguage = (props) => {
    const {i18n} = props;
    const [namespaceContent, setNamespaceContent] = useState({});
    const [oldNamespaceContent, setOldNamespaceContent] = useState({});
    const [language, setLanguage] = useState(getLanguage(i18n.language));
    const [languageToCreate, setLanguageToCreate] = useState({});
    const [activeItem, setActiveItem] = useState(-1);
    const [searchString, setSearchString] = useState('');
    const [namespaces, setNamespaces] = useState([]);
    const [showNewLanguage, setShowNewLanguage] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const [update, setUpdate] = useState(false);

    useEffect(() => {
        utils.getLanguageNameSpaces(language.languageCode, setNamespaces);
        setIsLoading(true)
        utils.getNamespaceContent("common", language.languageCode, setOldNamespaceContent).then(() => {
            utils.getNamespaceContent("common", language.languageCode, setNamespaceContent).then(() => {
                setActiveItem(2);
                setIsLoading(false);
            });
        });
    }, [])

    return (
        <div
            key={`edit-language-0`}
            className={"admin-editor"}>
            <div
                key={`edit-language-1`}
                style={{marginBottom: '1rem'}}>
                <Grid key={`edit-language-2`}>
                    <Grid.Row
                        key={`edit-language-3`}
                        columns={2}>
                        <Grid.Column key={`edit-language-4`}>
                            <h1
                                key={`edit-language-5`}
                                className={"admin-primary admin-edit-header"}>
                                {i18n.t("adminManagement:language.header")}
                            </h1>
                            <p key={`edit-language-6`}>{i18n.t("adminManagement:language.description")}</p>
                        </Grid.Column>
                        <Grid.Column
                            key={`edit-language-7`}
                            textAlign={"right"}>
                            <Button.Group>
                                <Button
                                    key={`edit-language-8`}
                                    style={{marginRight: "1rem"}}
                                    className={"admin-button-primary"}
                                    onClick={() => {
                                        setShowNewLanguage(!showNewLanguage)
                                    }}>
                                    {i18n.t("adminManagement:language.add_language")}
                                </Button>
                                <Button
                                    key={`edit-language-9`}
                                    disabled={isLoading}
                                    loading={isLoading}
                                    className={"admin-button-save"}
                                    onClick={() => {
                                        emitCustomEvent("rebuild");
                                        utils.saveNamespaceLocalization(namespaceContent, language.languageCode, namespaces[activeItem], setIsLoading);
                                        setUpdate(!update);
                                    }}>
                                    {i18n.t("common:common.save")}
                                </Button>
                            </Button.Group>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
            <div
                key={`edit-language-10`}
                style={showNewLanguage ? {width: '50%', marginBottom: '1rem'} : {display: 'none'}}>
                <Select
                    key={`edit-language-11`}
                    className={'select-new-language'}
                    options={allLanguages}
                    onChange={(option) => setLanguageToCreate(option)}/>
                {
                    utils.checkIfLanguageExists(languageToCreate) ?
                        <Button
                            key={`edit-language-12`}
                            className={"admin-button-primary"}
                            disabled={languageToCreate.code === "de"}
                            icon={'trash'}
                            color={'red'}
                            onClick={_ => {
                                emitCustomEvent("rebuild");
                                utils.deleteLanguage(languageToCreate.code, props.i18n)
                            }}/>
                        : <Button
                            key={`edit-language-13`}
                            className={"admin-button-primary"}
                            onClick={() => {
                                emitCustomEvent("rebuild");
                                utils.createNewLanguage(languageToCreate, setIsLoading).then(result => {
                                    //console.log("result: ", result)
                                    if (result < 200 || result > 300) {
                                        if (result === 429)
                                            setError(`${i18n.t("adminManagement:language.error")}: ${i18n.t("adminManagement:language.too_many_requests")} ${i18n.t("adminManagement:language.not_translated")}`);
                                        else if (result === 304)
                                            setError(`${i18n.t("adminManagement:language.error")}: ${i18n.t("adminManagement:language.already_exists")} ${i18n.t("adminManagement:language.not_translated")}`);
                                        else
                                            setError(`${i18n.t("adminManagement:language.error")}. ${i18n.t("adminManagement:language.not_translated")}`);
                                    }

                                });

                            }}
                            disabled={!languageToCreate.hasOwnProperty('label')}>
                            {i18n.t("adminManagement:language.add_language")}
                        </Button>
                }
            </div>
            <div key={`edit-language-14`}>
                {
                    error !== "" ?
                        <p key={`edit-language-15`}>{error}</p>
                        : null
                }
            </div>
            <div
                key={`edit-language-16`}
                style={{display: 'flex', alignItems: 'baseline'}}>
                <p key={`edit-language-17`}>{i18n.t("adminManagement:language.currently_editing")}</p>
                <Dropdown
                    key={`edit-language-18`}
                    className={'edit_language_dropdown'}
                    text={language.language}
                >
                    <Dropdown.Menu key={`edit-language-19`}>
                        {getAllLanguageOptions(setLanguage)}
                    </Dropdown.Menu>
                </Dropdown>
                <input
                    key={`edit-language-20`}
                    className={"admin-primary"}
                    style={{marginLeft: '1rem', marginRight: '5px'}}
                    type={'checkbox'}
                    checked={language.isActive === true}
                    onChange={e => {
                        emitCustomEvent("rebuild");
                        setLanguage({...language, isActive: e.target.checked})
                        utils.changeLanguageActive(e.target.checked, language.languageCode)
                    }}/>
                <label
                    key={`edit-language-21`}
                    className={"admin-primary"}>
                    {i18n.t("common:common.active")}
                </label>
            </div>
            <div
                key={`edit-language-22`}
                className={"admin-primary"}>
                {utils.ShowNamespaces(language.languageCode,
                    namespaces,
                    activeItem,
                    setActiveItem,
                    setNamespaceContent,
                    setOldNamespaceContent, setIsLoading)}
            </div>
            {
                activeItem !== -1 ?
                    <div
                        style={{marginTop: 15}}>
                        <Input
                            key={`edit-language-25`}
                            icon={'search'}
                            className={'admin-primary'}
                            value={searchString}
                            iconPosition='left'
                            placeholder={i18n.t("common:common.search") + '...'}
                            onChange={e => setSearchString(e.target.value)}/>
                    </div>
                    : null
            }
            {utils.ShowNamespaceContent(namespaceContent,
                oldNamespaceContent,
                language.languageCode,
                namespaces, activeItem,
                setNamespaceContent,
                searchString,
                isLoading)}
        </div>
    )
}

export default withTranslation(['common', 'adminManagement'])(EditLanguage)