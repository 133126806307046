import React, {useRef, useState} from "react";
import {withTranslation} from "react-i18next";
import PropTypes from "prop-types";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import Modal from "semantic-ui-react/dist/commonjs/modules/Modal";
import Divider from "semantic-ui-react/dist/commonjs/elements/Divider";

import * as utils from "./utils";
import userStore from "../../adminComponents/userManagement/userStore";
import {useLocation} from "react-router-dom";


let ALL_ICONS_IN_ALL_CONTEXTS = [];
if (typeof document !== "undefined") {
    ALL_ICONS_IN_ALL_CONTEXTS = require('semantic-ui-react/src/lib/SUI').ALL_ICONS_IN_ALL_CONTEXTS;
}

const EditIconComponent = (props) => {
    let {style, size, i18n, onClose, icon} = props;
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [editValue, setEditValue] = useState("");
    const [search, setSearch] = useState("");
    const searchQuery = useLocation().search;
    const displayType = (userStore.isAdmin || userStore.isContentCreator) && searchQuery !== "?preview" ? "admin" : "user";
    let allIcons = useRef();
    return (
        <Grid>
            <Grid.Row>
                <Grid.Column>
                    <Button
                        className={'circular edit_button admin-button-primary'}
                        icon="edit outline"
                        onClick={() => {
                            setEditModalOpen(true);
                            setEditValue('')//textToShow.content
                        }}/>
                    <Icon className={displayType === "admin" ? 'admin_text_block' : ''}
                          size={size}
                          name={icon}
                          style={style}/>
                </Grid.Column>
            </Grid.Row>
            <Modal
                className={"admin-primary"}
                open={editModalOpen}
                style={{"margin": "auto"}}
                onClose={_ => utils.closeInput(setEditModalOpen, setEditValue)}
                centered={true}>
                <Modal.Header className={"admin-primary"}>{i18n.t("common:common.edit")}</Modal.Header>
                <Modal.Content className={"admin-primary"} scrolling>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column>
                                <Icon size={size} name={editValue}/>
                            </Grid.Column>
                        </Grid.Row>
                        <Divider/>
                        <Grid.Row>
                            <Grid.Column>
                                <label className={"admin-primary"}>Search</label>
                                <input className={"admin-primary admin-border-secondary"}
                                       placeholder={search}
                                       value={search}
                                       onChange={(e) => setSearch(e.target.value)}/>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            {utils.showIcons(search, allIcons, setEditValue, ALL_ICONS_IN_ALL_CONTEXTS)}
                        </Grid.Row>
                    </Grid>
                </Modal.Content>
                <Modal.Actions className={"admin-primary"}>
                    <Button className={"admin-button-warn"}
                            icon='delete'
                            onClick={_ => utils.closeInput(setEditModalOpen, setEditValue)}/>
                    <Button
                        className={"admin-button-primary"}
                        icon='checkmark'
                        onClick={_ => {
                            onClose(editValue)
                            setEditModalOpen(false);
                        }}
                    />
                </Modal.Actions>
            </Modal>
        </Grid>
    );
}

EditIconComponent.propTypes = {
    icon: PropTypes.string,
    onClose: PropTypes.func,
    style: PropTypes.object,
    size: PropTypes.string
};

EditIconComponent.defaultProps = {
    icon: "",
    onClose: () => {
    },
    style: {},
    size: "huge"
};

export default withTranslation()(EditIconComponent)