import config from "../../../config/main.config";
import {getFromLocalStorage} from "../../../helper/util";

export {
    getComponents,
    handleDelete
}

async function getComponents() {
    let components = await fetch(config.BASE_URL + "component/", {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": "Bearer " + getFromLocalStorage("token")
        }
    });

    components = await components.json();

    return components;
}

async function handleDelete(name) {
    await fetch(config.BASE_URL + "component/" + name, {
        method: "DELETE",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": "Bearer " + getFromLocalStorage("token")
        }
    })
}