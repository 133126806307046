import React, {useState} from "react";
import {Grid, Label} from "semantic-ui-react";
import Form from "semantic-ui-react/dist/commonjs/collections/Form";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import {withTranslation} from "react-i18next";
import * as utils from "./utils";

let emitCustomEvent = () => {
};

if (typeof document !== "undefined") {
    emitCustomEvent = require('react-custom-events').emitCustomEvent;
}

const EditCookieBanner = (props) => {
    const {i18n, state} = props;
    const [config, setConfig] = useState(state);
    const [update, setUpdate] = useState(false);

    return (
        <div
            key={`edit-cookie-0`}
            className={"admin-editor"}>
            <Grid key={`edit-cookie-1`}>
                <Grid.Row
                    key={`edit-cookie-2`}
                    columns={2}>
                    <Grid.Column key={`edit-cookie-3`}>
                        <h1
                            key={`edit-cookie-4`}
                            className={"admin-primary admin-edit-header"}>
                            {i18n.t("adminManagement:cookie.header")}
                        </h1>
                        <p key={`edit-cookie-5`}>{i18n.t("adminManagement:cookie.description")}</p>
                    </Grid.Column>
                    <Grid.Column
                        key={`edit-cookie-6`}
                        textAlign={"right"}>
                        <Button
                            key={`edit-cookie-7`}
                            className={"admin-button-save"}
                            id='form-button-control-public'
                            onClick={() => {
                                emitCustomEvent("rebuild");
                                emitCustomEvent('updateCookie')
                                utils.saveConfig(config)
                                setUpdate(!update);
                            }}>
                            {i18n.t("common:common.save")}
                        </Button>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <Form key={`edit-cookie-8`}>
                <p key={`edit-cookie-9`}>{i18n.t("adminManagement:cookie.text")}</p>
                <Form.Group
                    key={`edit-cookie-10`}
                    inline>
                    <Form.Checkbox
                        key={`edit-cookie-11`}
                        checked={config.marketing}
                        onChange={(e, data) => {
                            setConfig({
                                marketing: data.checked,
                                statistic: config.statistic,
                                performance: config.performance
                            })
                        }}
                    />
                    <Label
                        key={`edit-cookie-12`}
                        className={"admin-primary"}>
                        {i18n.t("adminManagement:cookie.marketing")}
                    </Label>
                    <Form.Checkbox
                        key={`edit-cookie-13`}
                        checked={config.statistic}
                        onChange={(e, data) => {
                            setConfig({
                                marketing: config.marketing,
                                statistic: data.checked,
                                performance: config.performance
                            })
                        }}
                    />
                    <Label
                        key={`edit-cookie-14`}
                        className={"admin-primary"}>
                        {i18n.t("adminManagement:cookie.statistic")}
                    </Label>
                    <Form.Checkbox
                        key={`edit-cookie-15`}
                        checked={config.performance}
                        onChange={(e, data) => {
                            setConfig({
                                marketing: config.marketing,
                                statistic: config.statistic,
                                performance: data.checked
                            })
                        }}
                    />
                    <Label
                        key={`edit-cookie-16`}
                        className={"admin-primary"}>
                        {i18n.t("adminManagement:cookie.performance")}
                    </Label>
                </Form.Group>
            </Form>
        </div>
    )
}

export default withTranslation(['adminManagement', 'common'])(EditCookieBanner)