import React, {useState} from "react";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import Menu from "semantic-ui-react/dist/commonjs/collections/Menu";

let emitCustomEvent = () => {
};

let useCustomEventListener = () => {

};
if (typeof document !== "undefined") {
    emitCustomEvent = require('react-custom-events').emitCustomEvent;
    useCustomEventListener = require('react-custom-events').useCustomEventListener;
}

const HeaderButtons = ({_key, index, elements, siblings}) => {
    const [itemCount, setItemCount] = useState(1);
    useCustomEventListener('itemCount', count => {
        setItemCount(count);
    });
    return (
        <Menu secondary key={`button.group.0.${_key}.${index}`}>
            <Menu.Item
                key={`button.1.${_key}.${index}`}
                disabled={(_key.includes("row") && elements.length >= 16) || itemCount > 99}
                onClick={() => emitCustomEvent('addElement', _key)}>
                <Icon key={`icon.1.${_key}`} name={"add"}/>
            </Menu.Item>
            <Menu.Item
                key={`button.2.${_key}.${index}`}
                onClick={() => emitCustomEvent('addProps', _key)}>
                <Icon key={`icon.2.${_key}`} name={"options"}/>
            </Menu.Item>
            <Menu.Item
                key={`button.3.${_key}.${index}`}
                disabled={_key === "grid.0" || _key === "menu.0" || (_key.includes("column") && siblings >= 16) || itemCount > 99}
                onClick={() => emitCustomEvent('copyElement', _key)}>
                <Icon key={`icon.3.${_key}`} name={"copy outline"}/>
            </Menu.Item>
            <Menu.Item
                key={`button.0.${_key}.${index}`}
                disabled={elements.length > 0 || _key === "grid.0" || _key === "menu.0"}
                onClick={() => emitCustomEvent('removeElement', _key)}
                position={'right'}>
                <Icon key={`icon.0.${_key}`} name={"remove"}/>
            </Menu.Item>
        </Menu>
    )
}

export default HeaderButtons;