import React, {useEffect, useState} from "react";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import {withTranslation} from "react-i18next";
import {Label, Placeholder} from "semantic-ui-react";

import config from "../../../../config/main.config";
import {fetchAllImages, getLogo} from "../../../../helper/util";
import * as utils from "./utils";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";

let emitCustomEvent = () => {
};

if (typeof document !== "undefined") {
    emitCustomEvent = require('react-custom-events').emitCustomEvent;
}

const EditLogo = (props) => {
    const {t} = props;
    const [src, setSrc] = useState('');
    const [isUpload, setIsLUpload] = useState(false);
    const [selectModalOpen, setSelectModalOpen] = useState(false);
    const [allImages, setAllImages] = useState([]); //maybe just all svg ? if not logo should have dynamic file extension
    const [saveWasClicked, setSaveWasClicked] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [update, setUpdate] = useState(false);
     const [error,setError]=useState("");

    useEffect(() => {
        setTimeout(_ => {
            setSaveWasClicked(false)
        }, 8000);
    }, [saveWasClicked]);

    useEffect(() => {
        fetchAllImages().then(images => {
            setAllImages(images);
        })
        getLogo('logo').then(logo => {
            setSrc(config.URL + 'images/logos/' + logo);
        });
    }, [])

    return (
        <div
            key={`edit-logo-0`}
            className={"admin-editor"}>
            <Grid key={`edit-logo-1`}>
                <Grid.Row
                    key={`edit-logo-2`}
                    columns={2}>
                    <Grid.Column key={`edit-logo-3`}>
                        <h1
                            key={`edit-logo-4`}
                            className={'admin-primary admin-edit-header'}>
                            {t("adminManagement:logo.header")}
                        </h1>
                        <p key={`edit-logo-5`}>{t("adminManagement:logo.description")}</p>
                    </Grid.Column>
                    <Grid.Column
                        key={`edit-logo-6`}
                        textAlign={"right"}>
                        <Button
                            key={`edit-logo-7`}
                            disabled={isLoading}
                            className={"admin-button-save"}
                            loading={isLoading}
                            onClick={_ => {
                                emitCustomEvent("rebuild");
                                utils.saveLogo(setSrc, src, isUpload, setIsLoading);
                                setSaveWasClicked(true);
                                setUpdate(!update);
                            }}>
                            {t("common:common.save")}
                        </Button>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <div key={`edit-logo-8`}>
                {
                    src !== "" ?
                        <img
                            key={`edit-logo-9`}
                            className={'ui image'}
                            style={{maxWidth: "50%", paddingBottom: 20}}
                            src={src + '?time' + (Date.now())}
                            alt={'logo'}/>
                        : <Placeholder
                            key={`edit-logo-10`}
                            style={{height: 150, width: 150}}>
                            <Placeholder.Image key={`edit-logo-11`}/>
                        </Placeholder>
                }
            </div>
            <label
                key={`edit-logo-12`}
                htmlFor={'inputLogo'}
                className={"admin-button-primary ui button"}
                onClick={_ => {
                    setIsLUpload(true);
                }}>
                {t("common:common.upload")}
            </label>
            <input
                key={`edit-logo-13`}
                id={'inputLogo'}
                style={{display: 'none'}}
                type="file"
                accept="image/*"
                onChange={(e) => utils.onLogoSelected(e, setSrc,setError)}
            />
            <Button
                key={`edit-logo-14`}
                className={"admin-button-primary"}
                onClick={_ => {
                    setSelectModalOpen(true);
                    setIsLUpload(false);
                }}>
                {t("common:common.select")}
            </Button>
            {
                error === 'filesize' ?
                    <p className={"admin-highlight"}>{t("common:common.file_too_large")} (max. 5MB)</p>
                    :null
            }
            {utils.showSelectImage(selectModalOpen, setSelectModalOpen, allImages, setSrc, t)}
            <Label
                key={`edit-logo-15`}
                className={saveWasClicked ? 'build-hint-fade-in' : 'build-hint-fade-out'}
                pointing={"left"}>
                {t("edit:edit.wait_alert")}
            </Label>
        </div>
    )
}

export default withTranslation(['common', 'adminManagement', 'edit'])(EditLogo)