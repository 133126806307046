import React, {useEffect, useState} from "react"
import {withTranslation} from "react-i18next";
import Modal from "semantic-ui-react/dist/commonjs/modules/Modal"
import Button from "semantic-ui-react/dist/commonjs/elements/Button"

import * as utils from "./utils";

const AddPropsModal = ({
                           definitions,
                           elements,
                           element,
                           setElements,
                           hierarchy,
                           setUpdate,
                           update,
                           open,
                           setOpen,
                           i18n
                       }) => {
    const [classNamesOpen, setClassNamesOpen] = useState(false);
    const [classNames, setClassNames] = useState([]);

    useEffect(()=>{
        utils.getClassNames().then(result=>{
            setClassNames(result)
        })
    },[])

    return (
        <div key={'outer.div.0'} className={"admin-primary"}>
            <Modal
                key={'modal.0'}
                open={open}
                onClose={() => setOpen(false)}
                onOpen={() => setOpen(true)}
            >
                <Modal.Header className={"admin-primary"} key={'modal.header.0'}>
                    {i18n.t("adminManagement:props.header")}
                </Modal.Header>
                <Modal.Content className={"admin-primary"}
                               key={'modal.content.0'}
                               scrolling>
                    {
                        utils.renderTextProp(element.type, elements, element, setElements, hierarchy, setUpdate, update)
                    }
                    {
                        definitions !== undefined ?
                            Object.keys(definitions[element.type].props).map((prop, index) => (
                                <div key={`div.1.${index}`} style={{marginTop: "1%"}}>
                                    {
                                        utils.renderPropOption(definitions[element.type].props[prop].type, definitions, element, prop, elements, setElements, hierarchy, setUpdate, update, index, classNamesOpen, setClassNamesOpen, classNames)
                                    }
                                </div>
                            ))
                            : null
                    }
                </Modal.Content>
                <Modal.Actions className={"admin-primary"} key={'modal.actions.0'}>
                    <Button
                        className={"admin-button-primary"}
                        key={'button.0'}
                        content="done"
                        labelPosition='right'
                        icon='checkmark'
                        onClick={() => setOpen(false)}
                    />
                </Modal.Actions>
            </Modal>
        </div>
    )
}

export default withTranslation(['adminManagement'])(AddPropsModal)