import React, {useEffect, useState} from "react";
import {withTranslation} from "react-i18next";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import * as utils from "./utils";

const Options = (props) => {
    const {i18n} = props;
    const [updateStatus, setUpdateStatus] = useState({status: "checking for updates"});

    useEffect(() => {
        getUpdate();
    }, [])

    const getUpdate = () => {
        utils.handleGetUpdate().then(updateStatus => {
            setUpdateStatus(updateStatus);
        })
    }

    return (
        <div className={"admin-editor"}>
            <Grid>
                <Grid.Row>
                    <Grid.Column>
                        <h1 className={"admin-primary admin-edit-header"}>{i18n.t("adminManagement:adminManagement.options")}</h1>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <h2 className={"admin-primary admin-edit-header"}>{i18n.t("adminManagement:adminManagement.update")}</h2>
                        <p>
                            {
                                updateStatus.status === "success" ?
                                    utils.updateAvailable(updateStatus.currentVersion,updateStatus.newestVersion) ?
                                        i18n.t("adminManagement:adminManagement.update_available")
                                        : i18n.t("adminManagement:adminManagement.up_to_date")
                                    : updateStatus.status === "checking for updates" ?
                                        i18n.t("adminManagement:adminManagement.checking_for_updates")+"..."
                                        : updateStatus.status === "update failed" ?
                                            i18n.t("adminManagement:adminManagement.update_failed")
                                        : updateStatus.status === "failed" ?
                                                i18n.t("adminManagement:adminManagement.checking_for_updates_failed")
                                            : updateStatus.status
                            }
                        </p>
                        <div>
                            <h3 className={"admin-primary admin-edit-header"}>{i18n.t("adminManagement:adminManagement.current_version")}</h3>
                            <p>{updateStatus.currentVersion}</p>
                            <h3 className={"admin-primary admin-edit-header"}>{i18n.t("adminManagement:adminManagement.newest_version")}</h3>
                            <p>{updateStatus.newestVersion}</p>
                            <h3 className={"admin-primary admin-edit-header"}>{i18n.t("adminManagement:adminManagement.current_update")}</h3>
                            <p>{updateStatus.currentUpdate}</p>
                            <h3 className={"admin-primary admin-edit-header"}>{i18n.t("adminManagement:adminManagement.newest_update")}</h3>
                            <p>{updateStatus.newestUpdate}</p>
                        </div>
                        <Button
                            disabled={!utils.updateAvailable(updateStatus.currentVersion,updateStatus.newestVersion)}
                            className={"admin-button-save"}
                            onClick={() => {
                                utils.handleUpdate().then(()=>{})
                            }}>{i18n.t("adminManagement:adminManagement.update")}</Button>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </div>

    )
}

export default withTranslation('adminManagement')(Options)