import React, {useEffect, useState} from "react"
import Modal from "semantic-ui-react/dist/commonjs/modules/Modal"
import Button from "semantic-ui-react/dist/commonjs/elements/Button"

import * as utils from "./utils";
import Menu from "semantic-ui-react/dist/commonjs/collections/Menu";
import {withTranslation} from "react-i18next";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Table from "semantic-ui-react/dist/commonjs/collections/Table";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";

const AddComponentModal = (
    {
        elements,
        setElements,
        element,
        hierarchy,
        update,
        setUpdate,
        definitions,
        categories,
        open,
        setOpen,
        title,
        link,
        i18n,
    }) => {
    const [selected, setSelected] = useState('text');
    const [showDescription, setShowDescription] = useState(-1);

    //sets the group selected by default depending on element type
    useEffect(() => {
        setSelected(
            element.type === 'row' || element.type === 'grid' ?
                'structure'
                : element.type === 'menuItem' || element.type === 'menuHeader' ?
                    'media'
                    : element.type.includes('menu') || element.type === 'dropdown' ?
                        'menu'
                        : 'text')
    }, [element])

    return (
        <Modal
            open={open}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            className={"admin-primary"}>
            <Modal.Header
                className={"admin-primary"}
                key={'modal.header.0'}>
                {i18n.t("adminManagement:component.header")}
            </Modal.Header>
            <Modal.Content scrolling>
                <Grid>
                    <Grid.Row columns={2}>
                        <Grid.Column width={4}>
                            <Menu pointing secondary vertical>
                                {
                                    element.type === 'row' || element.type === 'grid' ?
                                        <Menu.Item
                                            key={'div.0'}
                                            active={selected === 'structure'}
                                            name={"Structure"}
                                            onClick={() => {
                                                setSelected('structure')
                                            }}/>
                                        : element.type === 'menu' || element.type === 'submenu' || element.type === 'dropdown' ?
                                            <Menu.Item
                                                key={'div.1'}
                                                active={selected === 'menu'}
                                                onClick={() => {
                                                    setSelected('menu')
                                                }}
                                                name={"Menu"}/>
                                            : element.type === 'menuItem' || element.type === 'dropdownItem' ?
                                                [
                                                    <Menu.Item
                                                        key={'div.2'}
                                                        active={selected === 'media'}
                                                        onClick={() => {
                                                            setSelected('media')
                                                        }}
                                                        name={"media"}/>,
                                                    <Menu.Item
                                                        key={'div.3'}
                                                        active={selected === 'cms'}
                                                        onClick={() => {
                                                            setSelected('cms')
                                                        }}
                                                        name={"CMS"}/>
                                                ]
                                                :
                                                categories.map((category, index) => (
                                                    <div key={`div.1.${index}`}>
                                                        {element.type === 'menuItem' && category === 'menu' ?
                                                            <div/> :
                                                            <Menu.Item
                                                                key={`button.1.${index}`}
                                                                active={selected === category}
                                                                onClick={() => {
                                                                    setSelected(category)
                                                                }}
                                                                name={category}/>
                                                        }
                                                        <br/>
                                                    </div>
                                                ))
                                }
                            </Menu>
                        </Grid.Column>
                        <Grid.Column width={12}>
                            <Table basic={'very'}>
                                <Table.Body>
                                    {
                                        Object.keys(definitions).map((type, index) => (
                                            <React.Fragment key={`add-component-0-${index}`}>
                                                {
                                                    utils.showElement(selected, element.type, type, definitions[type].category) ?
                                                        <React.Fragment>
                                                            <Table.Row onClick={() => {
                                                                if (showDescription === index)
                                                                    setShowDescription(-1)
                                                                else
                                                                    setShowDescription(index)
                                                            }} key={`add-component-1-${index}`}>
                                                                <Table.Cell
                                                                    key={`add-component-2-${index}`}>
                                                                    {
                                                                        showDescription === index ?
                                                                            <b>{type}</b>
                                                                            : <p>{type}</p>
                                                                    }
                                                                </Table.Cell>
                                                                <Table.Cell key={`add-component-3-${index}`} textAlign={'right'}>
                                                                    <Button
                                                                        key={`add-component-4-${index}`}
                                                                        className={'button-basic'}
                                                                        onClick={() => {
                                                                            utils.handleClick(type, elements, element, hierarchy, title, link, definitions, setElements, setUpdate, update, setOpen);
                                                                        }}>
                                                                        <Icon key={`add-component-5-${index}`} name={"add"}/>
                                                                    </Button>
                                                                </Table.Cell>
                                                            </Table.Row>
                                                            {
                                                                showDescription === index ?
                                                                    <Table.Row key={`add-component-6-${index}`}>
                                                                        <Table.Cell key={`add-component-7-${index}`} colSpan={2}>
                                                                            {
                                                                                i18n.t(`component:descriptions.${type}`) === `descriptions.${type}` ?
                                                                                    i18n.t(`component:descriptions.custom`)
                                                                                    : i18n.t(`component:descriptions.${type}`)
                                                                            }
                                                                        </Table.Cell>
                                                                    </Table.Row>
                                                                    : null
                                                            }
                                                        </React.Fragment>
                                                        : null
                                                }
                                            </React.Fragment>
                                        ))
                                    }
                                </Table.Body>
                            </Table>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Modal.Content>
        </Modal>
    )
}

export default withTranslation(['component'])(AddComponentModal)