import React, {useEffect, useState} from "react";
import {withTranslation} from "react-i18next";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import {useHistory, useLocation} from "react-router-dom";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import * as utils from "./utils";
import UserContentTable from "../UserContentTable/UserContentTable";
import EditView from "../EditView/EditView";

let emitCustomEvent = () => {
};

let useCustomEventListener = () => {

};
if (typeof document !== "undefined") {
    emitCustomEvent = require('react-custom-events').emitCustomEvent;
    useCustomEventListener = require('react-custom-events').useCustomEventListener;
}

const EditFooter = (props) => {
    const location = useLocation();
    const {i18n} = props;
    //creates the default layout grid.
    const [elements, setElements] = useState([
        utils.createElement('grid', 'page-edit', 'grid.0', '-1', "", []),
        utils.createElement('row', 'page-edit', 'row.0', 'grid.0', "", []),
        utils.createElement('column', 'page-edit', 'column.0', 'row.0', "", [])
    ]);
    const [userFooters, setUserFooters] = useState([]);
    const [update] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [title, setTitle] = useState('Unnamed');
    const [used, setUsed] = useState(false);
    const type = "footer_builder";
    let hierarchy = [elements[0]];

    utils.updateHierarchy(elements, hierarchy);
    const history = useHistory();

    useEffect(() => {
        utils.getUserFooters().then(pages => {
            setUserFooters(pages)
        });
        setEditMode(location.search === "?edit-footer");
    }, []);

    useCustomEventListener('updateContent', () => {
        utils.getUserFooters().then(pages => {
            setUserFooters(pages)
        })
    });

    useEffect(() => {
        emitCustomEvent('itemCount', elements.length);
    }, [update]);

    useCustomEventListener('setElements', _elements => {
        setElements(_elements);
    });

    useCustomEventListener('setHierarchy', _hierarchy => {
        hierarchy = _hierarchy;
    })

    useCustomEventListener('setEditMode', _editMode => {
        setEditMode(_editMode);
    });

    useCustomEventListener('setTitle', _title => {
        setTitle(_title);
    });

    useCustomEventListener('clickedUsed', _used => {
        setUsed(_used);
    });

    return (
        <div
            key={`edit-footer-0`}
            className={"admin-editor"}>
            <Grid key={`edit-footer-1`}>
                <Grid.Row
                    key={`edit-footer-2`}
                    columns={2}>
                    <Grid.Column key={`edit-footer-3`}>
                        <h1
                            key={`edit-footer-4`}
                            className={"admin-primary"}>{i18n.t("adminManagement:footer.header")}</h1>
                        <p key={`edit-footer-5`}>{i18n.t("adminManagement:footer.description")}</p>
                    </Grid.Column>
                    <Grid.Column
                        key={`edit-footer-6`}
                        textAlign={"right"}>
                        {
                            !editMode ?
                                <Button
                                    key={`edit-footer-7`}
                                    className={"admin-button-primary"}
                                    onClick={() => {
                                        setUsed(false);
                                        utils.handleNew(history, setTitle, null, setEditMode, setElements, hierarchy, elements, type);
                                    }}>
                                    {i18n.t("adminManagement:footer.new")}
                                </Button>
                                : null
                        }
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row key={`edit-footer-8`}>
                    <Grid.Column key={`edit-footer-9`}>
                        {
                            editMode ?
                                <EditView
                                    key={`edit-footer-10`}
                                    type={type}
                                    hierarchy={hierarchy}
                                    elements={elements}
                                    history={history}
                                    _title={title}
                                    _link={null}
                                    isUsed={used}/>
                                : <UserContentTable
                                    key={`edit-footer-11`}
                                    type={type}
                                    contentToShow={userFooters}
                                    history={history}
                                    hierarchy={hierarchy}
                                    elements={elements}
                                    _title={title}
                                    _link={null}/>
                        }
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </div>
    )
}


export default withTranslation(['common', 'adminManagement'])(EditFooter);