import React, {useEffect, useState} from "react";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import {withTranslation} from "react-i18next";
import {Label, Placeholder} from "semantic-ui-react";

import config from "../../../../config/main.config";
import {fetchAllImages, getLogo} from "../../../../helper/util";
import * as utils from "./utils";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";

let emitCustomEvent = () => {
};

if (typeof document !== "undefined") {
    emitCustomEvent = require('react-custom-events').emitCustomEvent;
}

const EditFavicon = (props) => {
    const {t} = props;
    const [isLoading, setIsLoading] = useState(false);
    const [editFavicon, setEditFavicon] = useState(false);
    const [selectModalOpen, setSelectModalOpen] = useState(false);
    const [isUpload, setIsLUpload] = useState(false);
    const [favicon, setFavicon] = useState('');
    const [allImages, setAllImages] = useState([]); //maybe just all svg ? if not logo should have dynamic file extension
    const [saveWasClicked, setSaveWasClicked] = useState(false);
    const [update, setUpdate] = useState(false);
    const [error, setError] = useState("");

    useEffect(() => {
        setTimeout(_ => {
            setSaveWasClicked(false)
        }, 8000);
    }, [saveWasClicked]);

    useEffect(() => {
        fetchAllImages().then(images => {
            setAllImages(images);
        })
        getLogo('favicon').then(favicon => {
            setFavicon(config.URL + 'favicon/' + favicon);
        });
    }, []);

    return (
        <div
            key={`edit-favicon-0`}
            className={"admin-editor"}>
            <Grid key={`edit-favicon-1`}>
                <Grid.Row
                    key={`edit-favicon-2`}
                    columns={2}>
                    <Grid.Column key={`edit-favicon-3`}>
                        <h1
                            key={`edit-favicon-4`}
                            className={"admin-primary admin-edit-header"}>
                            {t("adminManagement:favicon.header")}
                        </h1>
                        <p key={`edit-favicon-5`}>{t("adminManagement:favicon.description")}</p>
                    </Grid.Column>
                    <Grid.Column
                        key={`edit-favicon-6`}
                        textAlign={"right"}>
                        <Button
                            key={`edit-favicon-7`}
                            disabled={isLoading}
                            className={"admin-button-save"}
                            loading={isLoading}
                            onClick={_ => {
                                emitCustomEvent("rebuild");
                                utils.saveFavicon(favicon, setIsLoading, isUpload);
                                setSaveWasClicked(true);
                                setUpdate(!update);
                            }}>
                            {t("common:common.save")}
                        </Button>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <div key={`edit-favicon-8`}>
                {
                    favicon !== "" ?
                        <img
                            key={`edit-favicon-9`}
                            className={'ui image'}
                            style={{maxWidth: "50%", paddingBottom: 20}}
                            src={isUpload ? favicon : favicon + '?time' + new Date()}
                            alt={'Favicon'}/>
                        : <Placeholder
                            key={`edit-favicon-10`}
                            style={{height: 150, width: 150}}>
                            <Placeholder.Image key={`edit-favicon-11`}/>
                        </Placeholder>
                }
            </div>
            <label
                key={`edit-favicon-12`}
                htmlFor={'inputFavicon'}
                className={"admin-button-primary ui button"}
                onClick={_ => {
                    setIsLUpload(true);
                }}>
                {t("common:common.upload")}
            </label>
            <input
                key={`edit-favicon-13`}
                className={"admin-primary"}
                id={'inputFavicon'}
                style={{display: 'none'}}
                type="file"
                accept=".png, .jpg, .jpeg"
                onChange={(e) => {
                    let extension = e.target.files[0].name.split('.').pop();
                    if (extension !== "png" && extension !== "jpg" && extension !== "jpeg") {
                        setError("unsupported")
                        return alert("file extension is not compatible");
                    } else {
                        utils.onFaviconSelected(e, setFavicon, setError);
                    }
                }}
            />
            <Button
                key={`edit-favicon-14`}
                className={"admin-button-primary"}
                onClick={_ => {
                    setEditFavicon(true);
                    setSelectModalOpen(true);
                    setIsLUpload(false);
                }}>
                {t("common:common.select")}
            </Button>
            {
                error === 'filesize' ?
                    <p className={"admin-highlight"}>{t("common:common.file_too_large")} (max. 5MB)</p>
                    : error === 'unsupported' ?
                        <p className={"admin-highlight"}>{t("common:common.file_type_invalid")} (.png, .jpg, .jpeg)</p>
                    :null
            }
            {utils.showSelectImage(selectModalOpen, setSelectModalOpen, allImages, setFavicon, editFavicon, t)}
            <Label
                key={`edit-favicon-15`}
                className={saveWasClicked ? 'build-hint-fade-in' : 'build-hint-fade-out'}
                pointing={"left"}>
                {t("adminManagement:adminManagement.wait_alert")}
            </Label>
        </div>
    )
}

export default withTranslation(['common', 'adminManagement'])(EditFavicon);