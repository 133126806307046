//Bevor ein (neuer) Benutzer registreirt wird, werden die eingegebenen Werte überprüft ob Sie 0 sind. Ansonsten
// wird eine Fehlermeldung im Modal ausgegeben.
//Ebenfalls wird die Funktion "isUnique" aufgerufen, die überprüft, ob der Benutzername und/oder das Passwort schon
// vorhanden ist.
import config from "../../../config/main.config";
import {getFromLocalStorage} from "../../../helper/util";
import history from "../../../helper/browserHistory";
// all users for admin
export let users = [];
export let roles = [];
export let userRoles = [];

export function setAcceptUser(id, accept) {
    return new Promise(res => fetch(config.BASE_URL + "users/user-accepted", {
        method: "PUT",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": "Bearer " + getFromLocalStorage("token")
        },
        body: JSON.stringify({id: id, accept: accept})
    }).then(response => {
        if (response.status >= 200 && response.status < 300) {
            response.json().then(json => {
                res(json)
            });
        } else {
            alert("Error while set accepting user")
        }
    }).catch(
        error => {
            console.error(error)
        }
    ));
}

export function checkInput(newUser) {
    return !(newUser.password !== newUser.passwordRepeat ||
        newUser.password.length === 0 ||
        newUser.firstName.length === 0 ||
        newUser.lastName.length === 0 || newUser.email.length === 0);
}

export function deleteUser(userId) {
    return new Promise(res => fetch(config.BASE_URL + "users/" + userId, {
        method: "DELETE",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": "Bearer " + getFromLocalStorage("token")
        }
    }).then(response => {
        if (response.status >= 200 && response.status < 300) {
            response.json().then(json => {
                res(json);
            });
        } else {
            alert("Error while deleting user");
        }
    }).catch(
        error => {
            console.error(error);
        }
    ));
}

export async function getUserRoles() {
    try {
        return await (await fetch(config.BASE_URL + "users/user-roles", {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + getFromLocalStorage("token")
            }
        })).json();
    } catch (e) {
        console.error(e);
        if (e.status === 401) {
            history.replace("/admin-login");
        }
    }

}

export function getUsers() {
    return new Promise(res => fetch(config.BASE_URL + "users/all", {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": "Bearer " + getFromLocalStorage("token")
        }
    }).then(response => {
        if (response.status >= 200 && response.status < 300) {
            response.json().then(json => {
                return res(json);
            });

        } else {
            //TODO do something
        }
    }).catch(
        error => {
            console.error(error);
            if (error.status === 401) {
                history.replace("/admin-login");
            }
        }
    ));
}

export function getRoles() {
    return new Promise(res => fetch(config.BASE_URL + "users/roles", {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": "Bearer " + getFromLocalStorage("token")
        }
    }).then(response => {
        if (response.status >= 200 && response.status < 300) {
            response.json().then(json => {
                return res(json);
            });

        } else {
            //TODO do something
        }
    }).catch(
        error => {
            console.error(error);
            if (error.status === 401) {
                history.replace("/admin-login");
            }
        }
    ));
}

export function resetRoleForUser(userId, roleId) {
    return new Promise(res => fetch(config.BASE_URL + "users/reset-role", {
        method: "PUT",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": "Bearer " + getFromLocalStorage("token")
        },
        body: JSON.stringify({userId: userId, roleId: roleId})
    }).then(response => {
        if (response.status >= 200 && response.status < 300) {
            response.json().then(json => {
                return res(json);
            });

        } else {
            //TODO do something
        }
    }).catch(
        error => {
            console.error(error);
            if (error.status === 401) {
                history.replace("/admin-login");
            }
        }
    ));
}

export function setRoleForUser(userId, roleId) {
    return new Promise(res => fetch(config.BASE_URL + "users/set-role", {
        method: "PUT",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": "Bearer " + getFromLocalStorage("token")
        },
        body: JSON.stringify({userId: userId, roleId: roleId})
    }).then(response => {
        if (response.status >= 200 && response.status < 300) {
            response.json().then(json => {
                res(json);
            });

        } else {
            //TODO do something
        }
    }).catch(
        error => {
            console.error(error);
            if (error.status === 401) {
                history.replace("/admin-login");
            }
        }
    ));
}