import React from "react";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Modal from "semantic-ui-react/dist/commonjs/modules/Modal";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";

import config from "../../../../config/main.config";
import {getFromLocalStorage} from "../../../../helper/util";

export {
    onFaviconSelected,
    saveFavicon,
    showSelectImage
}

function onFaviconSelected(e, setFavicon, setError) {

        if (e.target.files && e.target.files.length > 0) {
            if (e.target.files[0].size <= 5000000) {
                e.preventDefault();
                let reader = new FileReader();
                let file = e.target.files[0];

                reader.onloadend = () => {
                    setFavicon(reader.result);
                }
                reader.readAsDataURL(file)
                setError("")
            } else
                setError("filesize")
        }
}

function saveFavicon(image, setIsLoading, isUpload) {
    setIsLoading(true);
    image = image.replace("/favicon/", "");
    let timestamp = image.split('?').pop();
    let faviconName = image.replace(`?${timestamp}`, '');
    fetch(config.BASE_URL + "theme/favicon", {
        method: "PUT",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": "Bearer " + getFromLocalStorage("token")
        },
        body: JSON.stringify({image: faviconName, isUpload: isUpload})
    }).then(response => {
        if (response.status >= 200 && response.status < 300) {
            return response.json().then(json => {
                //Do smth
                setIsLoading(false);
                return json;
            });
        } else {
            setIsLoading(false);
        }
    })
        .catch(
            error => {
                if (process.env.NODE_ENV === "production") {
                    setIsLoading(false);
                }
                console.error(error);
            }
        );
}

function showSelectImage(selectModalOpen, setSelectModalOpen, allImages, setFavicon, editFavicon, t) {
    let showAllImages = allImages.map((images, index) =>
        <Grid.Column
            key={`edit-favicon-16-${index}`}
            width={4}>
            <img
                key={`edit-favicon-17-${index}`}
                className={'ui image'}
                src={config.URL + 'images/all-images/' + images.link}
                alt={'images' + index}
                onClick={_ => {
                    let extension = images.link.split('.').pop();
                    if (extension !== "png" && extension !== "jpg" && extension !== "jpeg") {
                        //TODO: Error handling for wrong file extension
                        return alert("file extension is not compatible");
                    } else {
                        setFavicon(config.URL + 'images/all-images/' + images.link);
                        setSelectModalOpen(false);
                    }
                }}/>
        </Grid.Column>
    )

    return (
        <Modal
            key={`edit-favicon-18`}
            className={"admin-primary"}
            open={selectModalOpen}>
            <Modal.Header
                key={`edit-favicon-19`}
                className={"admin-primary"}>
                {t("edit:edit.select_image")}
            </Modal.Header>
            <Modal.Content
                key={`edit-favicon-20`}
                className={"admin-primary"}>
                <Grid key={`edit-favicon-21`}>
                    <Grid.Row key={`edit-favicon-22`}>
                        {showAllImages}
                    </Grid.Row>
                </Grid>
            </Modal.Content>
            <Modal.Actions
                key={`edit-favicon-23`}
                className={"admin-primary"}>
                <Button
                    key={`edit-favicon-24`}
                    className={"admin-button-primary"}>{t("common:common.select")}</Button>
                <Button
                    key={`edit-favicon-25`}
                    className={"admin-button-warn"}
                    onClick={_ => setSelectModalOpen(false)}>{t("common:common.cancel")}</Button>
            </Modal.Actions>
        </Modal>
    )
}