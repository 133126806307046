import React from "react";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import {Loader, Menu} from "semantic-ui-react";

import config from "../../../../config/main.config";
import {getFromLocalStorage} from "../../../../helper/util";
import history from "../../../../helper/browserHistory";
import activeLanguages from "../../../../../shared/activeLanguages.json";
import availableLanguages from "../../../../../shared/availableLanguages.json";

export {
    deleteLanguage,
    createNewLanguage,
    getLanguageNameSpaces,
    getNamespaceContent,
    checkIfLanguageExists,
    changeLanguageActive,
    getAvailableLanguages,
    saveNamespaceLocalization,
    // eslint-disable-next-line no-use-before-define
    ShowNamespaceContent,
    // eslint-disable-next-line no-use-before-define
    ShowNamespaces
}

function deleteLanguage(languageToDelete, i18n) {
    let languageCode = languageToDelete + '-' + languageToDelete.toUpperCase();
    fetch(config.BASE_URL + "theme/language/" + languageCode, {
        method: "DELETE",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": "Bearer " + getFromLocalStorage("token")
        }
    }).then(response => {
        if (response.status >= 200 && response.status < 300) {
            response.json().then(json => {
                if (json && i18n.language === languageCode) {
                    history.push("/de-DE/admin-management")
                }
            })
        } else {
            //Error
        }
    }).catch(e => {
        console.error(e)
    })
}

async function createNewLanguage(newLanguage, setIsLoading) {
    setIsLoading(true)
    let response = await fetch(config.BASE_URL + "theme/language/" + newLanguage.code, {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": "Bearer " + getFromLocalStorage("token")
        },
        body: JSON.stringify({newLanguage: newLanguage})
    })
    //console.log("response: ", response)
    setIsLoading(false)
    return response.status;
}

function getLanguageNameSpaces(language, setNamespaces) {
    fetch(config.BASE_URL + "theme/namespaces/" + language, {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": "Bearer " + getFromLocalStorage("token")
        }
    }).then(response => {
        if (response.status >= 200 && response.status < 300) {
            response.json().then(json => {
                setNamespaces(json);
            })
        } else {
            //Error
        }
    }).catch(e => {
        console.error(e)
    })
}

function getNamespaceContent(namespace, language, setNamespaceContent) {
    return new Promise(result => fetch(config.BASE_URL + "theme/" + language + '/' + namespace, {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": "Bearer " + getFromLocalStorage("token")
        }
    }).then(response => {
        if (response.status >= 200 && response.status < 300) {
            response.json().then(json => {
                setNamespaceContent(json);
                return result(json);
            })
        } else {
            //Error
        }
    }).catch(e => {
        console.error(e)
    }))
}

function checkIfLanguageExists(languageToCheck) {
    let languageExists = false;
    activeLanguages.LANGUAGES.map((language) => {
        if (language.language === languageToCheck.name) {
            languageExists = true
        }
        return languageExists
    })
    return languageExists
}

function changeLanguageActive(active, language) {
    fetch(config.BASE_URL + "theme/changeLanguageActive", {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": "Bearer " + getFromLocalStorage("token")
        },
        body: JSON.stringify({activeState: active, language: language})
    }).then(response => {
        if (response.status >= 200 && response.status < 300) {
        }
    })
}

function getAvailableLanguages() {
    let allLanguages = [];
    availableLanguages.ALL_LANGUAGES.map((country, index) =>
        allLanguages.push({
            value: index,
            label: country.name + '/' + country.nativeName,
            code: country.code,
            name: country.name,
            nativeName: country.nativeName
        })
    )
    return allLanguages
}

function saveNamespaceLocalization(newContent, language, namespace, setIsLoading) {
    setIsLoading(true);
    fetch(config.BASE_URL + "theme/namespace", {
            method: "PUT",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + getFromLocalStorage("token")
            },
            body: JSON.stringify({newContent: newContent, language: language, namespace: namespace})
        }
    ).then(response => {
        if (response.status >= 200 && response.status < 300) {
            response.json().then(json => {
                setIsLoading(false);
                return json;
            })
        } else {
            setIsLoading(false);
            //Error
        }
    }).catch(e => {
        if (process.env.NODE_ENV === "production") {
            setIsLoading(false);
            history.go(0);
        }
        console.error(e)
    })
}

const ShowNamespaceContent = (content, oldContent, language, namespaces, activeItem, setContent, filterString, isLoading) => {


    if (activeItem === -1 || isLoading) {
        return
    }
    const namespaceContent = Object.keys(content).map((namespaceSection, index) => [
        <div
            key={`edit-language-26-${index}`}
            className={'grid ui admin-primary'}
            style={{marginTop: '1rem'}}>
            {Object.keys(content[namespaceSection]).map((item, i) => (
                <div
                    key={`edit-language-28-${index}-${i}`}
                    className={'row'}
                    style={item.includes(filterString) ? {} : {display: 'none'}}>
                    <div
                        key={`edit-language-29-${index}-${i}`}
                        className={'seven wide column'}>
                        <label
                            key={`edit-language-30-${index}-${i}`}
                            className={"admin-primary"}>
                            <b key={`edit-language-31-${index}-${i}`}>{item}</b>
                        </label><br key={`edit-language-32-${index}-${i}`}/>
                        <input
                            key={`edit-language-33-${index}-${i}`}
                            className={"admin-disabled admin-border-secondary"}
                            style={{
                                marginTop: '5px',
                                width: '100%',
                                borderStyle: 'dashed',
                                borderWidth: '2px',
                                borderRadius: '3px',
                                padding: '8px 4px'
                            }}
                            disabled
                            value={oldContent[namespaceSection][item]}/>
                    </div>
                    <div
                        key={`edit-language-34-${index}-${i}`}
                        style={{width: 'fit-content'}}
                        className={'column'}>
                        <br key={`edit-language-35-${index}-${i}`}/>
                        <Icon
                            key={`edit-language-36-${index}-${i}`}
                            style={{marginTop: '10px'}}
                            name={'chevron right'}/>
                    </div>
                    <div
                        key={`edit-language-37-${index}-${i}`}
                        className={'seven wide column'}>
                        <br key={`edit-language-38-${index}-${i}`}/>
                        <input
                            key={`edit-language-39-${index}-${i}`}
                            className={"admin-primary admin-border-secondary"}
                            style={{
                                marginTop: '6px',
                                width: '100%',
                                outline: 'none',
                                borderWidth: '1px',
                                borderRadius: '3px',
                                padding: '8px 4px'
                            }} value={content[namespaceSection][item]}
                            onChange={(e) => {
                                const updatedContent = {...content};
                                updatedContent[namespaceSection][item] = e.target.value;
                                setContent(updatedContent)
                            }}/>
                    </div>
                </div>
            ))
            }
        </div>
    ])

    return <div key={`edit-language-40`}>
        {namespaceContent ? namespaceContent : <Loader key={`edit-language-41`}/>}
    </div>

}

const ShowNamespaces = (language, namespaces, activeItem, setActiveItem, setNamespaceContent, setOldNamespaceContent, setIsLoading) => {
    const allNamespaces = namespaces.map((namespace, index) => (
        <Menu.Item
            key={`edit-language-56-${index}`}
            name={namespace}
            active={activeItem === index}
            onClick={_ => {
                setIsLoading(true);
                getNamespaceContent(namespace, language, setNamespaceContent).then(_ => {
                    getNamespaceContent(namespace, language, setOldNamespaceContent).then(_ => {
                        setActiveItem(index);
                        setIsLoading(false);
                    });
                });
            }}

        />
    ))
    return <Menu
        key={`edit-language-57`}
        pointing
        secondary>
        {allNamespaces}
    </Menu>;
}