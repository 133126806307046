import React, {useEffect, useState} from "react";
import {useHistory, useLocation} from "react-router-dom";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import * as utils from "./utils";
import {withTranslation} from "react-i18next";
import UserContentTable from "../UserContentTable/UserContentTable";
import EditView from "../EditView/EditView";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";

let emitCustomEvent = () => {
};

let useCustomEventListener = () => {

};
if (typeof document !== "undefined") {
    emitCustomEvent = require('react-custom-events').emitCustomEvent;
    useCustomEventListener = require('react-custom-events').useCustomEventListener;
}

const EditPage = (props) => {
    const location = useLocation();
    const {i18n} = props;
    //creates the default layout grid.
    const [elements, setElements] = useState([
        utils.createElement('grid', 'page-edit', 'grid.0', '-1', "", [{
            name: "className",
            value: "background-primary text-primary"
        }]),
        utils.createElement('row', 'page-edit', 'row.0', 'grid.0', "", []),
        utils.createElement('column', 'page-edit', 'column.0', 'row.0', "", [])
    ]);
    const [userPages, setUserPages] = useState([]);
    const [update] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [title, setTitle] = useState('Unnamed');
    const [link, setLink] = useState('unnamed');
    const type = "page_builder";
    let hierarchy = [elements[0]];

    utils.updateHierarchy(elements, hierarchy);
    const history = useHistory();

    useEffect(() => {
        utils.getUserPages().then(pages => {
            setUserPages(pages);
        });
        setEditMode(location.search === "?edit-page");
    }, []);

    useCustomEventListener('updateContent', () => {
        utils.getUserPages().then(pages => {
            setUserPages(pages);
        })
    });

    useEffect(() => {
        emitCustomEvent('itemCount', elements.length);
    }, [update]);

    useCustomEventListener('setElements', _elements => {
        setElements(_elements);
    });

    useCustomEventListener('setHierarchy', _hierarchy => {
        hierarchy = _hierarchy;
    })

    useCustomEventListener('setEditMode', _editMode => {
        setEditMode(_editMode);
    });

    useCustomEventListener('setTitle', _title => {
        setTitle(_title);
    });

    useCustomEventListener('setLink', _link => {
        setLink(_link);
    });

    return (
        <div
            key={`edit-page-0`}
            className={"admin-editor"}>
            <Grid key={`edit-page-1`}>
                <Grid.Row
                    key={`edit-page-2`}
                    columns={2}>
                    <Grid.Column key={`edit-page-3`}>
                        <h1
                            key={`edit-page-4`}
                            className={"admin-primary"}>
                            {i18n.t("adminManagement:page.header")}
                        </h1>
                        <p key={`edit-page-5`}>{i18n.t("adminManagement:page.description")}</p>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column
                        key={`edit-page-6`}
                        textAlign={"right"}>
                        {
                            !editMode ?
                                <Button
                                    key={`edit-page-7`}
                                    className={"admin-button-primary"}
                                    onClick={() => {
                                        utils.handleNew(history, setTitle, setLink, setEditMode, setElements, hierarchy, elements, type);
                                    }}>
                                    {i18n.t("adminManagement:page.new")}
                                </Button>
                                : null
                        }
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row key={`edit-page-8`}>
                    <Grid.Column key={`edit-page-9`}>
                        {
                            editMode ?
                                <EditView
                                    key={`edit-page-10`}
                                    type={type}
                                    hierarchy={hierarchy}
                                    elements={elements}
                                    history={history}
                                    _title={title}
                                    _link={link}/>
                                : <UserContentTable
                                    key={`edit-page-11`}
                                    type={type}
                                    contentToShow={userPages}
                                    history={history}
                                    hierarchy={hierarchy}
                                    elements={elements}
                                    _title={title}
                                    _link={link}/>
                        }
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </div>
    )
}

export default withTranslation(['common', 'adminManagement'])(EditPage);