import {
    buildConfig,
    copyElement,
    createElement,
    createKey,
    flattenHierarchy,
    getCategories,
    getDefinitions,
    handleBack,
    handleEdit,
    handleNew,
    insertIntoHierarchy,
    loadConfig,
    loadElements,
    removeElement,
    updateElements,
    updateHierarchy,
    updateKey,
    updateParentKey,
    validateLink,
    validateName
} from "../utils";
import config from "../../../../config/main.config";
import {getFromLocalStorage} from "../../../../helper/util";

export {
    savePageConfig,
    buildPage,
    getUserPages,
    deleteUserPage,
    handleBack,
    handleNew,
    handleEdit,
    validateName,
    createElement,
    createKey,
    updateHierarchy,
    insertIntoHierarchy,
    updateElements,
    flattenHierarchy,
    loadConfig,
    loadElements,
    buildConfig,
    getDefinitions,
    getCategories,
    validateLink,
    removeElement,
    copyElement,
    updateKey,
    updateParentKey
}

async function savePageConfig(title, link, hierarchy) {
    title = title.replace(" ", "_");
    link = link.replace(" ", "_");
    if (title === '')
        title = 'Unnamed';
    if (link === '')
        link = 'unnamed';
    let pageConfig = {
        title: title,
        link: link,
        components: []
    };
    let components = [];
    buildConfig(components, hierarchy[0]);
    pageConfig.components = components;

    let response = await fetch(config.BASE_URL + "siteBuilder/save", {
        method: "PUT",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": "Bearer " + getFromLocalStorage("token")
        },
        body: JSON.stringify({config: pageConfig})
    });

    if (response.status === 200) {
        let returnVal = await buildPage(title);
        if (returnVal === 0)
            return await saveRoute(title, link);
        else
            return returnVal;
    } else
        return -1;
}

async function saveRoute(name, link) {
    let response = await fetch(config.BASE_URL + "siteBuilder/save/route", {
        method: "PUT",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": "Bearer " + getFromLocalStorage("token")
        },
        body: JSON.stringify({name: name, link: link})
    });

    if (response === 200)
        return 0;
    else
        return -1;
}

async function buildPage(name) {
    let response = await fetch(config.BASE_URL + "siteBuilder/build", {
        method: "PUT",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": "Bearer " + getFromLocalStorage("token")
        },
        body: JSON.stringify({name: name})
    });
    if (response.status === 200)
        return 0;
    else
        return -1;
}

function getUserPages() {
    return new Promise(resolve => fetch(config.BASE_URL + "siteBuilder/userPages", {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": "Bearer " + getFromLocalStorage("token")
        }
    }).then(response => {
        if (response.status >= 200 && response.status < 300) {
            response.json().then(json => {
                    return resolve(json);
                }
            );
        }
    })).catch(error => console.error(error))
}

function deleteUserPage(name) {
    return new Promise(resolve => fetch(config.BASE_URL + "siteBuilder/userPages/" + name, {
        method: "DELETE",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": "Bearer " + getFromLocalStorage("token")
        }
    }).then(response => {
        if (response.status >= 200 && response.status < 300) {
            response.json().then(json => {
                    return resolve(json);
                }
            );
        }
    })).catch(error => console.error(error))
}