import React from "react";
import {updateHierarchy} from "../adminComponents/siteBuilder/utils";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import Label from "semantic-ui-react/dist/commonjs/elements/Label";
import TextArea from "semantic-ui-react/dist/commonjs/addons/TextArea";
import Checkbox from "semantic-ui-react/dist/commonjs/modules/Checkbox";
import Select from "react-select";
import Modal from "semantic-ui-react/dist/commonjs/modules/Modal";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Table from "semantic-ui-react/dist/commonjs/collections/Table";
import fetch from "node-fetch";
import config from "../../config/main.config";
import {getFromLocalStorage} from "../../helper/util";

export {
    getPropValue,
    updateElementContent,
    updateElementProps,
    handleAddProp,
    renderTextProp,
    renderPropOption,
    getClassNames,
    handleRemoveProp
}

function getClassNames() {
    return new Promise(resolve => fetch(config.BASE_URL + "theme/classNames", {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": "Bearer " + getFromLocalStorage("token")
        }
    }).then(response => {
        if (response.status >= 200 && response.status < 300) {
            response.json().then(json => {
                return resolve(json);
            })
        } else {
            //Error
        }
    }).catch(e => {
        console.log(e)
    }))
}

function getPropValue(element, prop) {
    let value = '';
    for (let i = 0; i < element.props.length; i++) {
        if (element.props[i].name === prop) {
            value = element.props[i].value;
        }
    }
    return value;
}

function updateElementContent(elements, element, content, setElements, hierarchy, setUpdate, update) {
    let tmp = elements;
    for (let i = 0; i < tmp.length; i++) {
        if (tmp[i].key === element.key)
            tmp[i].content = content;
    }
    setElements(tmp);
    setUpdate(!update);
    updateHierarchy(tmp, hierarchy);
}

function updateElementProps(elements, element, props, setElements, hierarchy, setUpdate, update) {
    let tmp = elements;
    for (let i = 0; i < tmp.length; i++) {
        if (tmp[i].key === element.key)
            tmp[i].props = props;
    }
    setElements(tmp);
    setUpdate(!update);
    updateHierarchy(tmp, hierarchy);
}

function handleAddProp(element, prop, value, elements, setElements, hierarchy, setUpdate, update) {
    let props = element.props;
    let newProp = true;
    for (let i = 0; i < props.length; i++) {
        if (props[i].name === prop) {
            newProp = false;
            props[i].value = value;
            break;
        }
    }
    if (newProp) {
        props.push({
            name: prop,
            value: value
        });
    }
    updateElementProps(elements, element, props, setElements, hierarchy, setUpdate, update);
}

function handleRemoveProp(element, prop, elements, setElements, hierarchy, setUpdate, update) {
    let props = element.props;
    let newProps = [];
    for (let i = 0; i < props.length; i++) {
        if(props[i].name !== prop)
            newProps.push(props[i]);
    }
    updateElementProps(elements, element, newProps, setElements, hierarchy, setUpdate, update);
}

function renderTextProp(type, elements, element, setElements, hierarchy, setUpdate, update) {
    switch (type) {
        case 'grid':
        case 'row':
        case 'column':
        case 'image':
        case 'icon':
        case 'flag':
        case 'input':
        case 'breadCrumbs':
        case 'cookieBanner':
        case 'editHeader':
        case 'editHTML':
        case 'editImage':
        case 'editIcon':
        case 'editText':
        case 'div':
        case 'menu':
        case 'submenu':
        case 'dropdown':
            return null;
        default:
            return (
                <Grid key={`add-props-modal-0-${element.key}`}>
                    <Grid.Row columns={4} key={`add-props-modal-1-${element.key}`}>
                        <Grid.Column
                            key={`add-props-modal-2-${element.key}`}>
                            <h4
                                key={`add-props-modal-3-${element.key}`}
                                className={"admin-primary"}>
                                Text
                            </h4>
                        </Grid.Column>
                        <Grid.Column
                            key={`add-props-modal-4-${element.key}`}>
                            <Label className={"admin-primary admin-border-highlight"}
                                   key={`add-props-modal-5-${element.key}`}>
                                for text with translation use EditText, EditHeader or EditHTML instead
                            </Label>
                        </Grid.Column>
                        <Grid.Column width={1} key={`add-props-modal-6-${element.key}`}>
                            <Icon
                                key={`add-props-modal-7-${element.key}`}
                                style={{marginTop: '10px'}}
                                name={'chevron right'}/>
                        </Grid.Column>
                        <Grid.Column
                            key={`add-props-modal-8-${element.key}`}>
                            <TextArea
                                key={`add-props-modal-9-${element.key}`}
                                className={'admin-primary '}
                                style={{
                                    marginTop: '6px',
                                    width: '100%',
                                    outline: 'none',
                                    borderWidth: '1px',
                                    borderRadius: '3px',
                                    padding: '8px 4px'
                                }}
                                placeholder='Text...'
                                value={element.content}
                                onChange={(e) => {
                                    updateElementContent(elements, element, e.target.value, setElements, hierarchy, setUpdate, update);
                                }}/>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            )
    }
}

function renderPropOption(type, definitions, element, prop, elements, setElements, hierarchy, setUpdate, update, index, classNamesOpen, setClassNamesOpen, classNames) {
    let defaultValue;
    if (prop !== 'children' && prop !== 'content' && prop !== 'textStore' && prop !== 'namespace' && prop !== 'breadCrumbData' && prop !== 'id') {
        switch (type) {
            case 'boolean':
                return (
                    <Grid key={`add-props-modal-12-${index}`}>
                        <Grid.Row columns={4} key={`add-props-modal-13-${index}`}>
                            <Grid.Column
                                key={`add-props-modal-14-${index}`}>
                                <h4
                                    key={`add-props-modal-15-${index}`}
                                    className={"admin-primary"}>
                                    {prop}
                                </h4>
                            </Grid.Column>
                            <Grid.Column
                                key={`add-props-modal-17-${index}`}>
                                <Label className={"admin-primary"} key={`add-props-modal.4`}>
                                    {definitions[element.type].props[prop].tooltip}
                                </Label>
                            </Grid.Column>
                            <Grid.Column width={1} key={`add-props-modal-18-${index}`}>
                                <Icon
                                    key={`add-props-modal-19-${index}`}
                                    style={{marginTop: '10px'}}
                                    name={'chevron right'}/>
                            </Grid.Column>
                            <Grid.Column
                                key={`add-props-modal-20-${index}`}>
                                <Checkbox
                                    key={`add-props-modal-21-${index}`}
                                    className={'admin-primary '}
                                    checked={typeof getPropValue(element, prop) === 'boolean' ? getPropValue(element, prop) : false}
                                    onChange={() => {
                                        handleAddProp(element, prop, !getPropValue(element, prop), elements, setElements, hierarchy, setUpdate, update);
                                    }}/>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                )
            case 'enum':
                let options = [{label: '-clear-', value: `clear-${element.key}`}];
                for (let i = 0; i < definitions[element.type].props[prop].options.length; i++) {
                    let option = {
                        label: definitions[element.type].props[prop].options[i],
                        value: i.toString() + element.key,
                    }
                    options.push(option)
                }
                defaultValue = getIndex(getPropValue(element, prop), options);
                return (
                    <Grid key={`add-props-modal-22-${index}`}>
                        <Grid.Row columns={4} key={`add-props-modal-23-${index}`}>
                            <Grid.Column
                                key={`add-props-modal-24-${index}`}>
                                <h4
                                    key={`add-props-modal-25-${index}`}
                                    className={"admin-primary"}>
                                    {prop}
                                </h4>
                                {
                                    definitions[element.type].props[prop].required ?
                                        <h5 key={`add-props-modal-26-${index}`} className={"admin-highlight"}>
                                            required
                                        </h5>
                                        : null
                                }
                            </Grid.Column>
                            <Grid.Column
                                key={`add-props-modal-27-${index}`}>
                                <Label className={"admin-primary"} key={`add-props-modal-28-${index}`}>
                                    {definitions[element.type].props[prop].tooltip}
                                </Label>
                            </Grid.Column>
                            <Grid.Column width={1} key={`add-props-modal-29-${index}`}>
                                <Icon
                                    key={`add-props-modal-30-${index}`}
                                    style={{marginTop: '10px'}}
                                    name={'chevron right'}/>
                            </Grid.Column>
                            <Grid.Column
                                key={`add-props-modal-31-${index}`}>
                                <Select
                                    style={{
                                        marginTop: '6px',
                                        width: '100%',
                                        outline: 'none',
                                        borderWidth: '1px',
                                        borderRadius: '3px',
                                        padding: '8px 4px'
                                    }}
                                    defaultValue={options[defaultValue]}
                                    key={`edit-language-11`}
                                    className={'select-new-language'}
                                    options={options}
                                    onChange={(option) => {
                                        if(option.label === "-clear-")
                                            handleRemoveProp(element, prop, elements, setElements, hierarchy, setUpdate, update);
                                        else
                                            handleAddProp(element, prop, option.label, elements, setElements, hierarchy, setUpdate, update);
                                    }}/>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                )
            default:
                defaultValue = getPropValue(element, prop);
                return (
                    <Grid key={`add-props-modal-32-${index}`}>
                        <Grid.Row columns={4} key={`add-props-modal-33-${index}`}>
                            <Grid.Column
                                key={`add-props-modal-34-${index}`}>
                                <h4
                                    key={`add-props-modal-35-${index}`}
                                    className={"admin-primary"}>
                                    {prop}
                                </h4>
                                {
                                    definitions[element.type].props[prop].required ?
                                        <h5 key={`add-props-modal-36-${index}`} className={"admin-highlight"}>
                                            required
                                        </h5>
                                        : null
                                }
                            </Grid.Column>
                            <Grid.Column
                                key={`add-props-modal-37-${index}`}>
                                <Label className={"admin-primary"} key={`add-props-modal-38-${index}`}>
                                    {definitions[element.type].props[prop].tooltip}
                                </Label>
                            </Grid.Column>
                            <Grid.Column width={1} key={`add-props-modal-39-${index}`}>
                                <Icon
                                    key={`add-props-modal-40-${index}`}
                                    style={{marginTop: '10px'}}
                                    name={'chevron right'}/>
                            </Grid.Column>
                            <Grid.Column
                                key={`add-props-modal-41-${index}`}>
                                {
                                    prop === "className" ?
                                        renderClassName(type, definitions, element, prop, elements, setElements, hierarchy, setUpdate, update, index, classNamesOpen, setClassNamesOpen, classNames)
                                        : <input
                                            key={`add-props-modal-42-${index}`}
                                            className={'admin-primary '}
                                            style={{
                                                marginTop: '6px',
                                                width: '100%',
                                                outline: 'none',
                                                borderWidth: '1px',
                                                borderRadius: '3px',
                                                padding: '8px 4px'
                                            }}
                                            placeholder={prop}
                                            value={getPropValue(element, prop)}
                                            onChange={(e) => {
                                                handleAddProp(element, prop, e.target.value, elements, setElements, hierarchy, setUpdate, update);
                                            }}/>
                                }

                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                )
        }
    }
}

function renderClassName(type, definitions, element, prop, elements, setElements, hierarchy, setUpdate, update, index, open, setOpen, classNames) {
    return (
        <>
            <textarea
                key={`add-props-modal-42-${index}`}
                className={'admin-primary '}
                style={{
                    marginTop: '6px',
                    width: '100%',
                    outline: 'none',
                    borderWidth: '1px',
                    borderRadius: '3px',
                    padding: '8px 4px'
                }}
                placeholder={prop}
                value={getPropValue(element, prop)}
                onChange={(e) => {
                    handleAddProp(element, prop, e.target.value, elements, setElements, hierarchy, setUpdate, update);
                }}/>
            <Modal
                key={`add-props-modal-43-${index}`}
                open={open}
                onOpen={() => setOpen(true)}
                trigger={<Button>Class Names</Button>}
            >
                <Modal.Header key={`add-props-modal-44-${index}`}>Class Names</Modal.Header>
                <Modal.Content key={`add-props-modal-45-${index}`} scrolling>
                    <Table key={`add-props-modal-46-${index}`} basic={'very'}>
                        <Table.Body key={`add-props-modal-47-${index}`}>
                            {
                                classNames.map((className, i) => (
                                    <Table.Row key={`add-props-modal-48-${index}-${i}`}>
                                        <Table.Cell
                                            key={`add-props-modal-49-${index}-${i}`}>
                                            {className}
                                        </Table.Cell>
                                        <Table.Cell key={`add-props-modal-50-${index}-${i}`} textAlign={'right'}>
                                            <Button
                                                key={`add-props-modal-51-${index}-${i}`}
                                                className={'button-basic'}
                                                onClick={() => {
                                                    if (getPropValue(element, prop).includes(`${className} `))
                                                        handleAddProp(element, prop, getPropValue(element, prop).replace(` ${className} `, '').replace(/\s+/g, " "), elements, setElements, hierarchy, setUpdate, update);
                                                    else
                                                        handleAddProp(element, prop, `${getPropValue(element, prop)} ${className} `, elements, setElements, hierarchy, setUpdate, update);
                                                }}>
                                                {
                                                    getPropValue(element, prop).includes(`${className} `) ?
                                                        <Icon color={'green'} key={`add-props-modal-52-${index}-${i}`}
                                                              name={"check"}/>
                                                        : <Icon key={`add-props-modal-52-${index}-${i}`} name={"add"}/>
                                                }
                                            </Button>
                                        </Table.Cell>
                                    </Table.Row>
                                ))
                            }
                        </Table.Body>
                    </Table>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        className={"admin-button-primary"}
                        key={`add-props-modal-52-${index}`}
                        content="done"
                        labelPosition='right'
                        icon='checkmark'
                        onClick={() => setOpen(false)}
                    />
                </Modal.Actions>
            </Modal>
        </>
    )
}

function getIndex(value, array) {
    for (let i = 0; i < array.length; i++) {
        if (array[i].label === value)
            return i;
    }
    return -1;
}