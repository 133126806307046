import config from "../../../config/main.config";
import {getFromLocalStorage} from "../../../helper/util";

export {
    handleUpdate,
    handleGetUpdate,
    handleGetUpgrade,
    updateAvailable
}

async function handleUpdate() {
    let result;
    result = await fetch(config.BASE_URL + "build/update", {
        method: "PUT",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": "Bearer " + getFromLocalStorage("token")
        },
    })
    result = result.status;
    return result;
}

async function handleGetUpdate() {
    let result;
    result = await fetch(config.BASE_URL + "build/update", {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": "Bearer " + getFromLocalStorage("token")
        },
    })
    result = await result.json();
    return result;
}

async function handleGetUpgrade() {
    let result;
    result = await fetch(config.BASE_URL + "build/upgrade", {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": "Bearer " + getFromLocalStorage("token")
        },
    })
    result = await result.json();
    return result;
}

function updateAvailable(current, newest) {
    if(current === undefined || newest === undefined)
        return false;
    let currentNumbers = current.split('.');
    let newestNumbers = newest.split('.');

    return !(parseInt(newestNumbers[0]) <= parseInt(currentNumbers[0]) && parseInt(newestNumbers[1]) <= parseInt(currentNumbers[1]) && parseInt(newestNumbers[2]) <= parseInt(currentNumbers[2]))
}