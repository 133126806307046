import {createElement, updateHierarchy} from "../adminComponents/siteBuilder/utils";

export {
    handleClick,
    showElement
}

//creates a new element by type with `element` being the new elements parent
function handleClick(type, elements, element, hierarchy, title, link, definitions, setElements, setUpdate, update, setOpen) {
    let props = [];
    let tmp = elements;
    let key = createKey(type);
    let parentkey = element.key;

    if (definitions[type].props['breadCrumbsData'])
        props.push({name: 'breadCrumbsData', value: `[{title: ${title}, url: '/${link}'}]`});
    if (definitions[type].props['textStore'])
        props.push({name: 'textStore', value: 'textStore'});
    if (definitions[type].props['namespace'])
        props.push({name: 'namespace', value: `namespace`});
    if (definitions[type].props['id'])
        props.push({name: 'id', value: key});


    tmp.push(createElement(type, '', key, parentkey, '', props));
    if (type === 'grid') {
        parentkey = key;
        key = createKey('row');
        tmp.push(createElement('row', '', key, parentkey, '', props));
        parentkey = key;
        key = createKey('column');
        tmp.push(createElement('column', '', key, parentkey, '', props));
    }
    if (type === 'row') {
        parentkey = key;
        key = createKey('column');
        tmp.push(createElement('column', '', key, parentkey, '', props));
    }
    setElements(tmp);
    updateHierarchy(elements, hierarchy);
    setUpdate(!update);
    setOpen(false);
}

function createKey(type) {
    const min = 1;
    const max = Number.MAX_SAFE_INTEGER;
    let random = (min + Math.random() * (max - min)).toFixed(0);
    return type + "." + random.toString();
}

function showElement(selectedCategory, parentType, elementType, elementCategory){
    switch(selectedCategory){
        case 'structure':
            switch (parentType){
                case 'grid':
                    return elementType === 'row';
                case 'row':
                    return elementType === 'column';
                case 'column':
                case 'div':
                    return elementType === 'grid' || elementType === 'div';
                default:
                    return false;
            }
        case 'menu':
            switch(parentType){
                case 'menu':
                    return elementType === 'dropdown' || elementType === 'submenu' || elementType === 'menuItem';
                case 'submenu':
                    return elementType === 'dropdown' || elementType === 'menuItem';
                case 'dropdown':
                    return elementType === 'dropdownItem';
                case 'column':
                    return selectedCategory === elementCategory && elementType !== 'dropdown' && elementType !== 'menu' && elementType !== 'menuItem' && elementType !== 'submenu' && elementType !== 'dropdownItem';
                default:
                    return selectedCategory === elementCategory;
            }
        case 'media':
            switch(parentType){
                case 'menuItem':
                case 'dropdownItem':
                    return elementType === 'icon'
                default:
                    return selectedCategory === elementCategory;
            }
        case 'cms':
            switch(parentType){
                case 'menuItem':
                case 'dropdownItem':
                    return elementType === 'login' || elementType === 'languageSelection' || elementType === 'logo' || elementType === 'editLink' || elementType === 'editHTML' || elementType === 'editText' || elementType === 'socialIcons'
                default:
                    return selectedCategory === elementCategory;
            }
        default:
            return selectedCategory === elementCategory;
    }
}