export {
    handleOnDragOver
}

function handleOnDragOver(event, items, hierarchy, emitCustomEvent) {
    if (event.active === null || event.over === null)
        return;
    if (isAncestor(event.active.id, event.over.id, hierarchy))
        return;
    let newHierarchy = items;
    let activeContainer = event.active.data.current.sortable.containerId;
    let overContainer = event.over.data.current.sortable.containerId;

    let activeParent = getParentByChildKey(event.active.id, newHierarchy[0]);
    let activeParentDroppable = getDroppableType(activeParent);

    let over = getElementByKey(event.over.id, newHierarchy[0]);
    let overDroppable = getDroppableType(over);

    let overParent = getParentByChildKey(event.over.id, newHierarchy[0]);
    let overParentDroppable = getDroppableType(overParent);

    //is the element over an element with the same type of droppable or without one?
    if ((overDroppable === 'none' || overDroppable !== activeParentDroppable) && overParentDroppable === activeParentDroppable) {
        //is the element over an element of the same type?
        if (activeContainer === overContainer) {
            let target = getElementByKey(event.active.id, newHierarchy[0]);
            let targetParent = getParentByChildKey(event.active.id, newHierarchy[0]);
            let sourceIndex = targetParent.children.indexOf(target);
            let targetIndex = targetParent.children.indexOf(getElementByKey(event.over.id, newHierarchy[0]));
            if (sourceIndex !== targetIndex) {
                targetParent.children.splice(sourceIndex, 1);
                targetParent.children.splice(targetIndex, 0, target);
            }
        } else {
            let target = getElementByKey(event.active.id, newHierarchy[0]);
            let targetParent = getParentByChildKey(event.over.id, newHierarchy[0]);
            let targetIndex = targetParent.children.indexOf(getElementByKey(event.over.id, newHierarchy[0]));
            target.parentKey = targetParent.key;
            removeElementByKey(target.key, newHierarchy[0]);
            targetParent.children.splice(targetIndex, 0, target);
        }
        //is the element over an element with the same type of droppable as parent?
    } else if (overDroppable === activeParentDroppable) {
        let target = getElementByKey(event.active.id, newHierarchy[0]);
        let targetParent = over;
        let targetIndex = 0;
        target.parentKey = targetParent.key;
        removeElementByKey(target.key, newHierarchy[0]);
        if (targetParent.children.length === 0)
            targetParent.children.push(target);
        else
            targetParent.children.splice(targetIndex, 0, target);
    }
    emitCustomEvent('itemDropped', newHierarchy);
}

function getDroppableType(element) {
    if (element === undefined)
        return 'none';
    switch (element.type) {
        case 'grid':
            return 'grid';
        case 'row':
            return 'row';
        case 'column':
            return 'container';
        case 'div':
            return 'container';
        case 'menu':
            return 'menu';
        case 'submenu':
            return 'menu';
        case 'menuItem':
            return 'menuItem';
        case 'dropdownItem':
            return 'menuItem';
        default:
            return 'none';
    }
}

function isAncestor(ancestor, descendant, hierarchy) {
    let ancestorNode = getElementByKey(ancestor, hierarchy[0]);
    let descendantNode = getElementByKey(descendant, ancestorNode);
    return descendantNode !== undefined;
}

function getParentByChildKey(key, node) {
    if (key === 'grid.0')
        return {type: "grid"};
    if (key === 'menu.0')
        return {type: "menu"}
    let element = getElementByKey(key, node);
    return getElementByKey(element.parentKey, node);
}

function getElementByKey(key, node) {
    if(node === undefined) {
        console.error("getElementByKey: node is undefined!");
        return -1;
    }
    for (let i = 0; i < node.children.length; i++) {
        let currentItem = getElementByKey(key, node.children[i]);
        if (currentItem && currentItem.key === key)
            return currentItem;
    }
    if (node.key === key)
        return node;
}

function removeElementByKey(key, node) {
    let found = false;
    let index = 0;
    for (let i = 0; i < node.children.length; i++) {
        if (node.children[i].key === key) {
            found = true;
            index = i;
            break;
        } else {
            removeElementByKey(key, node.children[i]);
        }
    }
    if (found)
        node.children.splice(index, 1);
}