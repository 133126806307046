import config from "../../../../config/main.config";
import {getFromLocalStorage} from "../../../../helper/util";

export {
    saveConfig
}

function saveConfig(gtmConfig) {
    fetch(config.BASE_URL + "gtm/save", {
        method: "PUT",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": "Bearer " + getFromLocalStorage("token")
        },
        body: JSON.stringify({config: gtmConfig})
    }).then(response => {
        if (response.status >= 200 && response.status < 300) {
            return response.json().then(json => {
                return json;
            });
        }
    }).catch(error => console.error(error))
}