import Grid from "./index";
import Row from "../Row";
import Column from "../Column";
import Container from "../Container";
import Item from "../Item";
import React from "react";
import { SortableContext } from "@dnd-kit/sortable";

export { showContent, renderPreview };

function renderPreview(
	setNodeRef,
	style,
	_key,
	index,
	attributes,
	listeners,
	elements,
	verticalListSortingStrategy,
) {
	return (
		<div
			className={"dndActive"}
			ref={setNodeRef}
			style={style}
			key={`draggable.0.${_key}.${index}`}
		>
			<div key={`div.0.${_key}.${index}`}>
				<div key={`div.1.${_key}.${index}`}>
					<div
						className={"grab admin-dnd-item-header"}
						{...attributes}
						{...listeners}
					></div>
				</div>
				<SortableContext
					items={elements}
					strategy={verticalListSortingStrategy}
				>
					<div key={`div.2.${_key}.${index}`} />
				</SortableContext>
			</div>
		</div>
	);
}

function showContent(elements, _key, activeElement) {
	return elements.map((element, index) => (
		<div key={`div.3.${_key}.${index}`} className={"flexGrow"}>
			{element.type === "grid" ? (
				<Grid
					key={`grid.0.${_key}.${index}`}
					elements={element.children}
					index={index}
					_key={element.key}
					_props={element.props}
					siblings={elements.length}
					active={activeElement}
				/>
			) : element.type === "row" ? (
				<Row
					key={`row.0.${_key}.${index}`}
					elements={element.children}
					index={index}
					_key={element.key}
					_props={element.props}
					siblings={elements.length}
					active={activeElement}
				/>
			) : element.type === "column" ? (
				<Column
					key={`column.0.${_key}.${index}`}
					elements={element.children}
					index={index}
					_key={element.key}
					_props={element.props}
					siblings={elements.length}
					active={activeElement}
				/>
			) : element.type === "div" ? (
				<Container
					key={`container.0.${_key}.${index}`}
					elements={element.children}
					index={index}
					_key={element.key}
					_props={element.props}
					siblings={elements.length}
					active={activeElement}
				/>
			) : (
				<Item
					key={`item.0.${_key}.${index}`}
					element={element}
					index={index}
					_props={element.props}
					siblings={elements.length}
					active={activeElement}
				/>
			)}
		</div>
	));
}
