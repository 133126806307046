/**
 * BlogAuthorOverview Page
 * @namespace BlogAuthorOverviewPage
 */
import React, {useEffect, useState} from "react";
import {withTranslation} from "react-i18next";
import Checkbox from "semantic-ui-react/dist/commonjs/modules/Checkbox";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Table from "semantic-ui-react/dist/commonjs/collections/Table";
import Modal from "semantic-ui-react/dist/commonjs/modules/Modal";
import Form from "semantic-ui-react/dist/commonjs/collections/Form";

import history from "../../../helper/browserHistory";
import userStore from "./userStore";
import {
    checkInput,
    deleteUser,
    getRoles,
    getUserRoles,
    getUsers,
    resetRoleForUser,
    setAcceptUser,
    setRoleForUser
} from "./utils";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";

function deleteUserClicked(user, setUsers) {
    deleteUser(user.id).then(() => {
        getUsers().then(users => {
            setUsers(users);
        })
    })
}

function addUser(user, setUsers) {
    if (checkInput(user)) {
        userStore.registerUser(user).then(() => {
            getUsers().then(users => {
                setUsers(users);
            })
        })
    } else {
        alert("Alle Felder müssen befüllt werden und das Passwort muss übereinstimmen");
    }
}

function userClicked() {
//TODO: ?
}

function roleChecked(user, role, setUserRoles) {
    setRoleForUser(user.id, role.id).then(() => {
        getUserRoles().then(userRoles => {
            setUserRoles(userRoles);
        })
    });
}


function roleUnchecked(user, role, setUserRoles) {
    resetRoleForUser(user.id, role.id).then(() => {
        getUserRoles().then(userRoles => {
            setUserRoles(userRoles);
        })
    });
}

function changeAcceptUser(user, setUser) {
    setAcceptUser(user.id, !user.accepted).then(res => {
        setUser(prevState => ({...prevState, accepted: res}));
    });
}

function handleInputChange(name, e, setUser) {
    let value = e.target.value
    setUser(prevState => ({...prevState, [name]: value}));
}

function showRows(users, roles, userRoles, setUsers, setUser, setUserRoles, i18n) {
    console.log("USerRoles: ", userRoles);
    console.log("USerRoles: ", userRoles);
    return users.map((user, index) =>
        (<Table.Row
                key={`user-management-4-${index}`}
                className={"admin-table"}>
                <Table.Cell
                    key={`user-management-5-${index}`}
                    onClick={() => userClicked()}>
                    {user.username}
                </Table.Cell>
                <Table.Cell
                    key={`user-management-6-${index}`}
                    onClick={() => userClicked()}>
                    {user.firstName}
                </Table.Cell>
                <Table.Cell
                    key={`user-management-7-${index}`}
                    onClick={() => userClicked()}>
                    {user.lastName}
                </Table.Cell>
                <Table.Cell
                    key={`user-management-8-${index}`}
                    onClick={() => userClicked()}>
                    {user.email}
                </Table.Cell>
                <Table.Cell key={`user-management-9-${index}`}>
                    <Checkbox
                        key={`user-management-10-${index}`}
                        checked={user.accepted} onChange={() => {
                        changeAcceptUser(user, setUser)
                        user.accepted = !user.accepted;
                    }}/>
                </Table.Cell>
                {roles.map((role, i) => (
                    <Table.Cell key={`user-management-0-${index}-${i}`}>
                        <Checkbox
                            key={`user-management-1-${index}-${i}`}
                            defaultChecked={userRoles.some((userRole) =>( userRole.roleId === role.id && userRole.userId === user.id))}
                            onChange={(e) => {
                                if (e.currentTarget.checked) {
                                    roleUnchecked(user, role, setUserRoles)
                                    e.currentTarget.checked = false;
                                } else {
                                    roleChecked(user, role, setUserRoles)
                                    e.currentTarget.checked = true;
                                }
                            }}/>
                    </Table.Cell>))}
                <Table.Cell key={`user-management-11-${index}`}>
                    <Button
                        key={`user-management-12-${index}`}
                        className={"admin-button-warn"}
                        disabled={userStore.userFromServer.id === user.id}
                        onClick={_ => deleteUserClicked(user, setUsers)}>
                        {i18n.t('login:user.delete')}
                    </Button>
                </Table.Cell>
            </Table.Row>
        )
    );
}


const UserManagement = (props) => {
    const {i18n} = props;
    const [isUserModalOpen, setIsUserModalOpen] = useState(false);
    const [mounted, setMounted] = useState(false);
    const [users, setUsers] = useState([]);
    const [userRoles, setUserRoles] = useState([]);
    const [roles, setRoles] = useState([]);
    const [user, setUser] = useState({
        firstName: "",
        lastName: "",
        username: "",
        email: "",
        password: "",
        passwordRepeat: "",
        admin: false,
        accepted: false,
        roles: [],
        rolesEdit: false
    });

    //ComponentDidMount
    useEffect(() => {
        if (!userStore.isAdmin) {
            history.push("/" + props.i18n.language + '/');
        } else {
            window.scrollTo(0, 0);
            getUsers().then(users => {
                setUsers(users);
            });
            getRoles().then(roles => {
                setRoles(roles);
            });
            getUserRoles().then(userRoles => {
                setUserRoles(userRoles);
                setMounted(true)
            });
        }
    }, []);


    let userRolesHeaders = roles.map((role, index) =>
        <Table.HeaderCell
            key={`user-management-13-${index}`}
            className={'admin-primary'}>
            {role.title}
        </Table.HeaderCell>
    );

    return (
        [
            <div
                key={`user-management-14`}
                className={"admin-editor"}>
                <Grid
                    key={`user-management-15`}
                    className={"admin-primary"}>
                    <Grid.Row
                        key={`user-management-16`}
                        columns={2}>
                        <Grid.Column key={`user-management-17`}>
                            <h1
                                key={`user-management-18`}
                                className={"admin-primary admin-edit-header"}>
                                {i18n.t("login:user.title")}
                            </h1>
                        </Grid.Column>
                        <Grid.Column
                            key={`user-management-19`}
                            textAlign={"right"}
                            className={'admin-edit-top-column'}>
                            <Button
                                key={`user-management-20`}
                                icon
                                labelPosition='right'
                                className={"admin-button-primary"}
                                onClick={() => setIsUserModalOpen(true)}>
                                <Icon
                                    key={`user-management-21`}
                                    name='add'/>
                                {i18n.t("login:user.add")}
                            </Button>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row key={`user-management-22`}>
                        <Grid.Column key={`user-management-23`}>
                            <Table
                                key={`user-management-24`}
                                basic={'very'}>
                                <Table.Header key={`user-management-25`}>
                                    <Table.Row key={`user-management-26`}>
                                        <Table.HeaderCell
                                            key={`user-management-27`}
                                            className={'admin-primary'}>
                                            {i18n.t("login:user.username")}
                                        </Table.HeaderCell>
                                        <Table.HeaderCell
                                            key={`user-management-28`}
                                            className={'admin-primary'}>
                                            {i18n.t("login:user.first_name")}
                                        </Table.HeaderCell>
                                        <Table.HeaderCell
                                            key={`user-management-29`}
                                            className={'admin-primary'}>
                                            {i18n.t("login:user.last_name")}
                                        </Table.HeaderCell>
                                        <Table.HeaderCell
                                            key={`user-management-30`}
                                            className={'admin-primary'}>
                                            {i18n.t("login:user.email")}
                                        </Table.HeaderCell>
                                        <Table.HeaderCell
                                            key={`user-management-31`}
                                            className={'admin-primary'}>
                                            {i18n.t("login:user.accepted")}
                                        </Table.HeaderCell>
                                        {userRolesHeaders}
                                        <Table.HeaderCell
                                            key={`user-management-32`}
                                            className={'admin-primary'}>
                                            {i18n.t("login:user.delete")}
                                        </Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body
                                    key={`user-management-33`}
                                    className={"admin-primary"}>
                                    {mounted && showRows(users, roles, userRoles, setUsers, setUser, setUserRoles, i18n)}
                                </Table.Body>
                            </Table>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>,
            <Modal
                key={`user-management-34`}
                open={isUserModalOpen}
                onClose={() => setIsUserModalOpen(false)}>
                <Modal.Header
                    key={`user-management-35`}
                    className={"admin-primary"}>
                    {i18n.t("login:user.add")}
                </Modal.Header>
                <Modal.Content
                    key={`user-management-36`}
                    className={"admin-primary"}
                    scrolling>
                    <Form key={`user-management-37`}>
                        <Form.Field key={`user-management-38`}>
                            <label
                                key={`user-management-39`}
                                className={"admin-primary"}>
                                {i18n.t("login:user.first_name")}
                            </label>
                            <input
                                key={`user-management-40`}
                                className={"admin-primary admin-border-primary"}
                                value={users.firstName}
                                onChange={e => handleInputChange('firstName', e, setUser)}/>
                        </Form.Field>
                        <Form.Field key={`user-management-41`}>
                            <label
                                key={`user-management-42`}
                                className={"admin-primary"}>
                                {i18n.t("login:user.last_name")}
                            </label>
                            <input
                                key={`user-management-43`}
                                className={"admin-primary admin-border-primary"}
                                value={users.lastName}
                                onChange={e => handleInputChange('lastName', e, setUser)}/>
                        </Form.Field>
                        <Form.Field key={`user-management-44`}>
                            <label
                                key={`user-management-45`}
                                className={"admin-primary"}>
                                {i18n.t("login:user.username")}
                            </label>
                            <input
                                key={`user-management-46`}
                                className={"admin-primary admin-border-primary"}
                                value={users.username}
                                onChange={e => handleInputChange('username', e, setUser)}/>
                        </Form.Field>
                        <Form.Field key={`user-management-47`}>
                            <label
                                key={`user-management-48`}
                                className={"admin-primary"}>
                                {i18n.t("login:user.email")}
                            </label>
                            <input
                                key={`user-management-49`}
                                className={"admin-primary admin-border-primary"}
                                value={users.email}
                                type='email'
                                onChange={e => handleInputChange('email', e, setUser)}/>
                        </Form.Field>
                        <Form.Field key={`user-management-50`}>
                            <label
                                key={`user-management-51`}
                                className={"admin-primary"}>
                                {i18n.t("login:user.password")}
                            </label>
                            <input
                                key={`user-management-52`}
                                className={"admin-primary admin-border-primary"}
                                value={users.password}
                                type='password'
                                onChange={e => handleInputChange('password', e, setUser)}/>
                        </Form.Field>
                        <Form.Field key={`user-management-53`}>
                            <label
                                key={`user-management-54`}
                                className={"admin-primary"}>
                                {i18n.t("login:user.confirm_password")}
                            </label>
                            <input
                                key={`user-management-55`}
                                className={"admin-primary admin-border-primary"}
                                value={users.passwordRepeat}
                                type='password'
                                onChange={e => handleInputChange('passwordRepeat', e, setUser)}/>
                        </Form.Field>
                    </Form>
                    <div
                        key={`user-management-56`}
                        style={{marginTop: "10px"}}>
                        <Button
                            key={`user-management-57`}
                            className={"admin-button-save"}
                            onClick={() => {
                                addUser(user, setUsers);
                                setIsUserModalOpen(false);
                            }}>
                            {i18n.t("common:common.save")}
                        </Button>
                        <Button
                            key={`user-management-58`}
                            className={"admin-button-warn"}
                            onClick={() => {
                                setIsUserModalOpen(false);
                            }}>
                            {i18n.t("common:common.cancel")}
                        </Button>
                    </div>
                </Modal.Content>
            </Modal>
        ]
    )
}

export default withTranslation(['login', 'common'])(UserManagement)
