import React, {useRef} from "react";
import {Card, CardContent} from "semantic-ui-react";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";

import config from "../../../../config/main.config";
import EditIconComponent from "../../../editImage/EditIconComponent/EditIconComponent";
import {getFromLocalStorage} from "../../../../helper/util";

let emitCustomEvent = () => {
};

if (typeof document !== "undefined") {
    emitCustomEvent = require('react-custom-events').emitCustomEvent;
}

export {
    saveIcon,
    // eslint-disable-next-line no-use-before-define
    ShowEnabledSocialMedia
}

function saveIcon(socialMedia) {
    fetch(config.BASE_URL + "theme/icons", {
        method: "PUT",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": "Bearer " + getFromLocalStorage("token")
        },
        body: JSON.stringify({socialMediaIcons: socialMedia})
    }).then(response => {
        if (response.status >= 200 && response.status < 300) {
            return response.json().then(json => {
                return json;
            });
        }
    }).catch(error => {
        console.error(error)
    })
}

const ShowEnabledSocialMedia = (socialMedia, setSocialMedia, isEdit, setIsEdit, newContent, setNewContent, confirmDelete, setConfirmDelete, t, update, setUpdate) => {

    let showAllSocialMedia = useRef();
    showAllSocialMedia.current = socialMedia.map((social, index) =>
        (
            <Card
                key={`edit-social-6-${index}`}
                className={'icon-card admin-primary'}>
                <Card.Header
                    key={`edit-social-7-${index}`}
                    className={'icon-card admin-primary'}>
                    {
                        isEdit[index] ?
                            [
                                <div
                                    key={`edit-social-8-${index}`}
                                    style={{marginBottom: '10px'}}
                                    className={"admin-primary"}>
                                    <label
                                        key={`edit-social-9-${index}`}
                                        className={'social-icon-card-align'}>
                                        {t("adminManagement:social_media.edit_title")}
                                    </label>,
                                    <input
                                        key={`edit-social-10-${index}`}
                                        className={'social-input social-icon-card-align'}
                                        value={newContent.name}
                                        onChange={(e) => setNewContent({...newContent, name: e.target.value})}/>
                                </div>
                            ]
                            : <h3
                                key={`edit-social-11-${index}`}
                                className={"admin-primary"}
                                style={{textAlign: 'center', padding: '5px,2px'}}>
                                {social.name}
                            </h3>
                    }
                </Card.Header>
                <Card.Content
                    key={`edit-social-12-${index}`}
                    className={"admin-primary"}>
                    {
                        isEdit[index] ?
                            [
                                <label
                                    key={`edit-social-13-${index}`}>{t("adminManagement:social_media.edit_icon")}</label>,
                                <EditIconComponent
                                    key={`edit-social-14-${index}`}
                                    icon={newContent.image}
                                    onClose={(link) => {
                                        setNewContent({...newContent, image: link})
                                    }}/>,
                                <label
                                    key={`edit-social-15-${index}`}>{t("adminManagement:social_media.edit_link")}</label>,
                                <input
                                    key={`edit-social-16-${index}`}
                                    className={'social-input'}
                                    value={newContent.link}
                                    onChange={(e) => setNewContent({...newContent, link: e.target.value})}/>
                            ] : [
                                <Icon
                                    key={`edit-social-17-${index}`}
                                    className={'social-icon'}
                                    name={social.image}
                                    size={'huge'}/>,
                                <div
                                    key={`edit-social-18-${index}`}
                                    style={{marginTop: '20px', textAlign: 'center'}}>
                                    <a
                                        key={`edit-social-19-${index}`}
                                        href={social.link}
                                        target={'_blank'}
                                        rel={'noreferrer'}>
                                        {social.link}
                                    </a>
                                </div>
                            ]
                    }
                </Card.Content>
                <CardContent
                    key={`edit-social-20-${index}`}
                    className={'icon-card admin-primary'}
                    extra>
                    <Button
                        key={`edit-social-21-${index}`}
                        className={"admin-button-primary"}
                        onClick={_ => {
                            if (isEdit[index])
                                emitCustomEvent("rebuild");
                            setUpdate(!update);
                            if (isEdit[index]) {
                                let saveContent = [...socialMedia];
                                saveContent[index] = newContent
                                setSocialMedia(saveContent);
                                saveIcon(saveContent);
                                let newState = [...isEdit];
                                newState[index] = false;
                                setIsEdit(newState);
                                emitCustomEvent('updateSocial',saveContent);
                            } else {
                                let newState = [...isEdit];
                                newState[index] = true;
                                setIsEdit(newState);
                                setNewContent(social);
                            }
                        }}>
                        {
                            isEdit[index] ?
                                t("common:common.save")
                                : t("common:common.edit")
                        }
                    </Button>
                    <Button
                        key={`edit-social-22-${index}`}
                        floated={'right'}
                        className={"admin-button-warn"}
                        onClick={_ => {
                            if (isEdit[index]) {
                                let newState = [...isEdit];
                                newState[index] = false;
                                setIsEdit(newState);
                            } else {
                                if (confirmDelete[index] === 0) {
                                    let deleteConfirmed = [...confirmDelete];
                                    deleteConfirmed[index] = 1;
                                    setConfirmDelete(deleteConfirmed);
                                } else {
                                    socialMedia.splice(index, 1);
                                    setSocialMedia([...socialMedia]);
                                    saveIcon(socialMedia);
                                    setConfirmDelete(0);
                                    emitCustomEvent('updateSocial',socialMedia);
                                }
                            }
                        }}>
                        {
                            isEdit[index] ?
                                t("common:common.cancel")
                                : t("common:common.delete")
                        }
                    </Button>
                </CardContent>
            </Card>
        )
    )
    return (
        <Grid
            key={`edit-social-23`}
            className={"admin-primary"}>
            <Grid.Row key={`edit-social-24`}>
                <Grid.Column
                    key={`edit-social-25`}
                    width={6}
                    style={{display: 'contents'}}>
                    <Card.Group key={`edit-social-26`}>
                        {showAllSocialMedia.current}
                        <Card
                            key={`edit-social-27`}
                            className={'icon-card admin-primary'}
                            onClick={_ => {
                                setSocialMedia([...socialMedia, {name: 'new', image: '', link: ''}]);
                                let newState = [...isEdit];
                                newState[socialMedia.length] = true;
                                setIsEdit(newState);
                            }}>
                            <Card.Content
                                key={`edit-social-28`}
                                style={{
                                    margin: 'auto',
                                    display: 'block',
                                    maxHeight: '50px'
                                }}>
                                <Icon
                                    key={`edit-social-29`}
                                    style={{
                                        display: 'block',
                                        margin: 'auto',
                                        width: '100%',
                                        height: '100%',
                                        background: 'transparent'
                                    }} name={'plus'}
                                    size={'large'}
                                />
                            </Card.Content>
                        </Card>
                    </Card.Group>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
}