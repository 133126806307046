import React, {useEffect, useState} from "react";
import {
    rectIntersection,
    DndContext,
    DragOverlay,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors
} from '@dnd-kit/core';
import {SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy,} from '@dnd-kit/sortable';

import * as utils from "./utils";
import Grid from "../Grid";
import {flattenHierarchy} from "../../adminComponents/siteBuilder/utils";
import OverlayItem from "../OverlayItem";
import Menu from "../Menu";

let emitCustomEvent = () => {
};

if (typeof document !== "undefined") {
    emitCustomEvent = require('react-custom-events').emitCustomEvent;
}

const Editor = ({hierarchy}) => {
    const minDelta = 5;
    const [items, setItems] = useState(hierarchy);
    const [activeElement, setActiveElement] = useState(null);
    const [lastUpdate, setLastUpdate] = useState(-1);
    const [vertical, setVertical] = useState(false);
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    useEffect(()=>{
        setItems(hierarchy);
        for (let i = 0; i < items.length; i++) {
            for (let j = 0; j < items[i].props.length; j++) {
                if (items[i].props[j].name === 'vertical')
                    setVertical(items[i].props[j].value);
            }
        }
    },[hierarchy]);

    const onDragOver = event => {
        let now = Date.now();
        let delta = now - lastUpdate;
        setLastUpdate(now);
        if (delta > minDelta)
        utils.handleOnDragOver(event, items, hierarchy, emitCustomEvent);
    }
    const onDragStart = event => {
        let elements = [];
        flattenHierarchy(items[0], elements);
        for (let i = 0; i < elements.length; i++) {
            if (elements[i].key === event.active.id) {
                setActiveElement(elements[i]);
                break;
            }
        }
    }
    const onDragEnd = () => {
        setActiveElement(null);
    }

    return (
        <DndContext
            sensors={sensors}
            onDragStart={onDragStart}
            onDragOver={onDragOver}
            onDragEnd={onDragEnd}
            collisionDetection={rectIntersection}
        >
            <SortableContext
                items={items}
                strategy={verticalListSortingStrategy}
            >
                {
                    items.map((element, index) => (
                        <div key={`div.1.${index}`}>
                            {
                                (element.type === 'grid') ?
                                    <Grid key={`grid.0.${index}`}
                                          style={element.style}
                                          elements={element.children}
                                          index={index}
                                          _key={element.key}
                                          _props={element.props}
                                          siblings={items.length}
                                          active={activeElement}/>
                                    : (element.type === 'menu') ?
                                        <Menu key={`menu.0.${index}`}
                                              style={element.style}
                                              elements={element.children}
                                              index={index}
                                              _key={element.key}
                                              vertical={vertical}
                                              type={element.type}
                                              _props={element.props}
                                              siblings={items.length}
                                              active={activeElement}/>
                                        : <></>
                            }
                        </div>
                    ))
                }
            </SortableContext>
            <DragOverlay>
                {
                    activeElement ?
                        <OverlayItem
                            key={`overlay.0.${activeElement.key}.${0}`}
                            elements={activeElement.children}
                            index={0}
                            _key={activeElement.key}
                            _props={activeElement.props}
                            siblings={0}
                            element={activeElement}
                            horizontal={!vertical}
                        />
                        : null
                }
            </DragOverlay>
        </DndContext>
    );
};

export default Editor