import config from "../../../config/main.config";
import {getFromLocalStorage} from "../../../helper/util";
import {deleteUserPage, savePageConfig} from "./EditPage/utils";
import {buildHeader, deleteUserHeader, saveHeaderConfig} from "./EditHeader/utils";
import {buildFooter, deleteUserFooter, saveFooterConfig} from "./EditFooter/utils";
import {deleteUserMenu, saveMenuConfig} from "./EditMenu/utils";

export {
    handleBack,
    handleNew,
    handleEdit,
    validateName,
    createElement,
    createKey,
    updateHierarchy,
    insertIntoHierarchy,
    updateElements,
    flattenHierarchy,
    loadConfig,
    loadElements,
    buildConfig,
    getDefinitions,
    getCategories,
    validateLink,
    removeElement,
    copyElement,
    updateKey,
    updateParentKey,
    handleDelete,
    handleSave,
    getDynamicRoutes,
    parseDynamicRoutes,
    deleteDisabled,
    buildFooter,
    buildHeader,
    updateUsed,
    updateKeys
}

//type: page_builder, menu_builder, header_builder, footer_builder
function handleDelete(name, type) {
    switch (type) {
        case "page_builder":
            deleteUserPage(name).then(() => {
            });
            break;
        case "menu_builder":
            deleteUserMenu(name).then(() => {
            });
            break;
        case "header_builder":
            deleteUserHeader(name).then(() => {
            });
            break;
        case "footer_builder":
            deleteUserFooter(name).then(() => {
            });
            break;
        default:
            break;
    }
}

function handleSave(title, link, sticky, hierarchy, type, used) {
    switch (type) {
        case "page_builder":
            savePageConfig(title, link, hierarchy).then(returnVal =>{
                if(returnVal === -1)
                    console.error("save page failed!");
            });
            break;
        case "menu_builder":
            saveMenuConfig(title, hierarchy).then(returnVal =>{
                if(returnVal === -1)
                    console.error("save menu failed!");
            });
            break;
        case "header_builder":
            saveHeaderConfig(title, sticky, hierarchy).then(returnVal =>{
                if(returnVal === -1)
                    console.error("save header failed!");
                else{
                    fetchUpdateUsed(title, used, 'headerBuilder').then(returnVal =>{
                        if(returnVal === -1)
                            console.error("update used failed!");
                    });
                    if (used)
                        buildHeader(title).then(returnVal =>{
                            if(returnVal === -1)
                                console.error("build header failed!");
                        });
                }
            });
            break;
        case "footer_builder":
            saveFooterConfig(title, hierarchy).then(returnVal =>{
                if(returnVal === -1)
                    console.error("save footer failed!");
            });
            fetchUpdateUsed(title, used, 'footerBuilder').then(returnVal =>{
                if(returnVal === -1)
                    console.error("update used failed!");
            });
            if (used)
                buildFooter(title).then(returnVal =>{
                    if(returnVal === -1)
                        console.error("build footer failed!");
                });
            break;
        default:
            console.error("invalid type!");
            break;
    }
}

//type: page_builder, menu_builder, header_builder, footer_builder
function handleBack(history, title, setTitle, link, setLink, setEditMode, type) {
    history.push({search: `${type.replace("_builder", "")}`});
    if (title === '')
        setTitle('Unnamed');
    if (type === "page_builder" && link === '')
        setLink('unnamed');
    setEditMode(false);
}

//type: page_builder, menu_builder, header_builder, footer_builder
function handleNew(history, setTitle, setLink, setEditMode, setElements, hierarchy, elements, type) {
    //console.log("type: ", type)
    history.push({search: `edit-${type.replace("_builder", "")}`});
    //creates the default layout grid.
    switch (type) {
        case "page_builder":
            setElements([
                createElement('grid', 'page-edit', 'grid.0', '-1', "", [{
                    name: "className",
                    value: "background-primary text-primary"
                }]),
                createElement('row', 'page-edit', 'row.0', 'grid.0', "", []),
                createElement('column', 'page-edit', 'column.0', 'row.0', "", [])
            ]);
            break;
        case "menu_builder":
            setElements([createElement('menu', '', 'menu.0', '-1', "", [{name: "vertical", value: false}])]);
            break;
        case "header_builder":
            setElements([
                createElement('grid', 'page-edit', 'grid.0', '-1', "", [{
                    name: "className",
                    value: "background-secondary text-secondary"
                }]),
                createElement('row', 'page-edit', 'row.0', 'grid.0', "", []),
                createElement('column', 'page-edit', 'column.0', 'row.0', "", [{name: 'width', value: '2'}]),
                createElement('column', 'page-edit', 'column.1', 'row.0', "", [{name: 'width', value: '12'}]),
                createElement('languageSelection', 'page-edit', 'languageSelection.0', 'column.1', "", []),
                createElement('column', 'page-edit', 'column.2', 'row.0', "", [{name: 'width', value: '2'}]),
            ]);
            break;
        case "footer_builder":
            setElements([
                createElement('grid', 'page-edit', 'grid.0', '-1', "", []),
                createElement('row', 'page-edit', 'row.0', 'grid.0', "", []),
                createElement('column', 'page-edit', 'column.0', 'row.0', "", [])
            ]);
            break;
        default:
            setElements([
                createElement('grid', 'page-edit', 'grid.0', '-1', "", []),
                createElement('row', 'page-edit', 'row.0', 'grid.0', "", []),
                createElement('column', 'page-edit', 'column.0', 'row.0', "", [])
            ]);
            break;
    }
    hierarchy = [elements[0]];
    updateHierarchy(elements, hierarchy);
    setTitle('Unnamed');
    if (type === "page_builder")
        setLink('unnamed');
    setEditMode(true);
}

//type: page_builder, menu_builder, header_builder, footer_builder
function handleEdit(history, setEditMode, config, hierarchy, setElements, elements, setTitle, setLink, type, setSticky) {
    loadConfig(config, hierarchy, setElements, elements, setTitle, setLink, setSticky);
    history.push({search: `edit-${type.replace("_builder", "")}`});
    setEditMode(true);
}

//type: page_builder, menu_builder, header_builder, footer_builder
function validateName(value, setTitleUsed, titles, setTitle, type) {
    let regex;
    switch (type) {
        case "page_builder":
            regex = /\.?-?\+?\u0020?/g;
            value = value.replace(regex, '');
            value = value.charAt(0).toUpperCase() + value.slice(1);
            setTitleUsed(false);
            for (let i = 0; i < titles.length; i++) {
                if (titles[i] === value)
                    setTitleUsed(true);
            }
            setTitle(value);
            break;
        default:
            regex = /\.?\u0020?/g;
            value = value.replace(regex, '');
            value = value.charAt(0).toUpperCase() + value.slice(1);
            setTitle(value);
            break;
    }
}

function createElement(type, className, key, parentKey, content, props) {
    return (
        {
            type: type,
            className: className,
            key: key,
            parentKey: parentKey,
            content: content,
            children: [],
            props: props,
        }
    )
}

function createKey(type) {
    const min = 1;
    const max = Number.MAX_SAFE_INTEGER;
    let random = (min + Math.random() * (max - min)).toFixed(0);
    return type + "." + random.toString();
}

function updateHierarchy(elements, hierarchy) {
    if (hierarchy.length <= 0)
        return;
    hierarchy[0].children = [];
    for (let i = 0; i < elements.length; i++) {
        insertIntoHierarchy(elements[i], hierarchy[0]);
    }
}

function insertIntoHierarchy(element, node) {
    if (node.key === element.parentKey) {
        if (node.children.length === 0)
            node.children.push(element);
        else {
            let elementFound = false;
            for (let i = 0; i < node.children.length; i++) {
                if (node.children[i].key === element.key) {
                    elementFound = true;
                    if (node.children[i].top !== element.top || node.children[i].left !== element.left) {
                        node.children[i].top = element.top;
                        node.children[i].left = element.left;
                    }
                }
            }
            if (!elementFound)
                node.children.push(element);
        }
    }

    for (let i = 0; i < node.children.length; i++) {
        insertIntoHierarchy(element, node.children[i]);
    }
}

function updateElements(hierarchy, setElements) {
    let newElements = [];
    newElements.push(hierarchy[0]);
    flattenHierarchy(hierarchy[0], newElements);
    setElements(newElements);
}

function flattenHierarchy(node, elements) {
    for (let i = 0; i < node.children.length; i++) {
        elements.push(node.children[i]);
        flattenHierarchy(node.children[i], elements);
    }
}

function loadConfig(config, hierarchy, setElements, elements, setTitle, setLink, setSticky) {
    let tmp = [];
    loadElements(tmp, config.components[0], '-1');
    let configElements = [];

    for (let c = tmp.length - 1; c >= 0; c--) {
        configElements.push(tmp[c]);
    }

    hierarchy = [];
    for (let i = 0; i < configElements.length; i++) {
        if (configElements[i].parentKey === -1)
            hierarchy.push(configElements[i]);
        for (let c = 0; c < configElements.length; c++) {
            if (configElements[c].parentKey === configElements[i].key)
                configElements[i].children.push(configElements[c]);
        }
    }
    setElements(configElements);
    updateHierarchy(configElements, hierarchy);
    setTitle(config.title);
    if (setLink)
        setLink(config.link);
    if (setSticky)
        setSticky(config.sticky)
}

function loadElements(elements, component, parent) {
    for (let i = component.components.length - 1; i >= 0; i--) {
        loadElements(elements, component.components[i], component.id);
    }
    elements.push({
        type: component.componentName,
        className: '',
        key: component.id,
        parentKey: parent,
        content: component.content,
        children: [],
        props: component.props
    });
}

function buildConfig(components, element) {
    let next = [];
    for (let i = 0; i < element.children.length; i++) {
        buildConfig(next, element.children[i], element);
    }
    components.push({
        componentName: element.type,
        id: element.key,
        props: element.props,
        content: element.content,
        components: next
    });
}

function getDefinitions() {
    return new Promise(resolve => fetch(config.BASE_URL + "siteBuilder/definitions", {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": "Bearer " + getFromLocalStorage("token")
        }
    }).then(response => {
        if (response.status >= 200 && response.status < 300) {
            response.json().then(json => {
                    return resolve(json);
                }
            );
        }
    })).catch(error => console.error(error))
}

function getCategories() {
    return new Promise(resolve => fetch(config.BASE_URL + "siteBuilder/categories", {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": "Bearer " + getFromLocalStorage("token")
        }
    }).then(response => {
        if (response.status >= 200 && response.status < 300) {
            response.json().then(json => {
                    return resolve(json);
                }
            );
        }
    })).catch(error => console.error(error))
}

function validateLink(value, setLinkUsed, links, setLink) {
    let regex = /%?:?\/?\??#?\[?]?@?!?\$?&?'?\(?\)?\*?\+?,?;?=?\.?\u0020?/g;
    value = value.replace(regex, '');
    value = value.charAt(0).toLowerCase() + value.slice(1);
    setLinkUsed(false);
    for (let i = 0; i < links.length; i++) {
        if (links[i] === value)
            setLinkUsed(true);
    }
    setLink(value);
}

function removeElement(key, elements, setElements, hierarchy, update, setUpdate) {
    let tmp = [];
    hierarchy = [];
    for (let i = 0; i < elements.length; i++) {
        elements[i].children = [];
        if (elements[i].key !== key)
            tmp.push(elements[i]);
    }
    for (let i = 0; i < tmp.length; i++) {
        if (tmp[i].parentKey === -1)
            hierarchy.push(tmp[i]);
        for (let c = 0; c < tmp.length; c++) {
            if (tmp[c].parentKey === tmp[i].key)
                tmp[i].children.push(tmp[c]);
        }
    }
    setElements(tmp);
    updateHierarchy(tmp, hierarchy);
    setUpdate(!update);
}

function updateKeys(hierarchy, elements, setElements, update, setUpdate) {
    let element = {
        ...hierarchy[0],
        children: [...deepCopyChildren(hierarchy[0])],
        props: [...deepCopyProps(hierarchy[0])]
    };
    if (element.key !== 'grid.0' && element.key !== 'menu.0')
        updateKey(element);
    updateParentKey(element, element.parentKey);
    let newElements = [{
        ...element,
        children: [...deepCopyChildren(element)],
        props: [...deepCopyProps(element)]
    }];
    hierarchy = [{
        ...element,
        children: [...deepCopyChildren(element)],
        props: [...deepCopyProps(element)]
    }];
    flattenHierarchy(hierarchy[0], newElements);
    setElements(newElements);
    setUpdate(!update);
}

function copyElement(elementKey, elements, setElements, hierarchy, update, setUpdate) {
    let newElements = [...elements];
    let element = {};
    hierarchy = [];
    for (let i = 0; i < newElements.length; i++) {
        if (newElements[i].key === elementKey) {
            element = {
                ...newElements[i],
                children: [...deepCopyChildren(newElements[i])],
                props: [...deepCopyProps(newElements[i])]
            };
            break;
        }
    }
    updateKey(element);
    updateParentKey(element, element.parentKey);
    newElements.push(element);
    hierarchy.push(newElements[0]);
    updateHierarchy(newElements, hierarchy);
    newElements = [newElements[0]];
    flattenHierarchy(hierarchy[0], newElements);
    setElements(newElements);
    setUpdate(!update);
}

function deepCopyChildren(element) {
    let copy = [];
    for (let i = 0; i < element.children.length; i++) {
        copy = [
            ...copy,
            {
                ...element.children[i],
                props: [...deepCopyProps(element.children[i])],
                children: [...deepCopyChildren(element.children[i])]
            }
        ];
    }
    return copy;
}

function deepCopyProps(element) {
    let copy = [];
    for (let i = 0; i < element.props.length; i++) {
        copy = [
            ...copy,
            {
                ...element.props[i]
            }
        ];
    }
    return copy;
}

function updateKey(element) {
    for (let i = 0; i < element.children.length; i++) {
        updateKey(element.children[i]);
    }
    element.key = createKey(element.type);
    for (let i = 0; i < element.props.length; i++) {
        if (element.props[i].name === 'id')
            element.props[i].value = element.key;
    }
}

function updateParentKey(element, parent) {
    for (let i = 0; i < element.children.length; i++) {
        updateParentKey(element.children[i], element.key);
    }
    element.parentKey = parent;
}

function getDynamicRoutes() {
    return new Promise(resolve => fetch(config.BASE_URL + "siteBuilder/dynamicRoutes", {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": "Bearer " + getFromLocalStorage("token")
        }
    }).then(response => {
        if (response.status >= 200 && response.status < 300) {
            response.json().then(json => {
                    return resolve(json);
                }
            );
        }
    })).catch(error => console.error(error))
}

function parseDynamicRoutes(dynamicRoutes, setTitles, setLinks) {
    let titles = [];
    let links = [];
    for (let i = 0; i < dynamicRoutes.length; i++) {
        titles.push(dynamicRoutes[i].name);
        links.push(dynamicRoutes[i].link);
    }
    setTitles(titles);
    setLinks(links);
}

function deleteDisabled(name) {
    const presets = ["Triple-A-Soft", "HeaderNav", "QuickLinks", "DataPrivacy", "Home", "Imprint", "About", "Blog"];
    for (let i = 0; i < presets.length; i++) {
        if (presets[i] === name)
            return true;
    }
    return false;
}

function updateUsed(used, elements, type) {
    for (let i = 0; i < elements.length; i++) {
        if ((elements[i].used && used !== i) || (!elements[i].used && used === i)) {
            switch (type) {
                case 'header_builder':
                    fetchUpdateUsed(elements[i].title, used === i, 'headerBuilder').then(returnVal =>{
                        if(returnVal === -1)
                            console.error("update used failed!");
                    });
                    break;
                case 'footer_builder':
                    fetchUpdateUsed(elements[i].title, used === i, 'footerBuilder').then(returnVal =>{
                        if(returnVal === -1)
                            console.error("update used failed!");
                    });
                    break;
                default:
                    break;
            }
        }
    }
}

async function fetchUpdateUsed(name, used, url) {
    let response = await fetch(config.BASE_URL + url + "/updateUsed", {
        method: "PUT",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": "Bearer " + getFromLocalStorage("token")
        },
        body: JSON.stringify({name: name, used: used})
    });
    if(response.status === 200)
        return 0;
    else
        return -1;
}