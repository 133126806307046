import React from "react";
import {
	SortableContext,
	useSortable,
	verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import HeaderButtons from "../HeaderButtons";
import * as utils from "../Grid/utils";
import PropsList from "../PropsList";

const Container = ({ elements, _key, index, _props, siblings, active }) => {
	const { attributes, listeners, setNodeRef, transform, transition } =
		useSortable({ id: _key });

	const style = {
		transform: CSS.Transform.toString(transform),
		transition,
	};
	if (active && active.key === _key) {
		return utils.renderPreview(
			setNodeRef,
			style,
			_key,
			index,
			attributes,
			listeners,
			elements,
			verticalListSortingStrategy,
		);
	}
	return (
		<div ref={setNodeRef} style={style} key={`draggable.0.${_key}.${index}`}>
			<div
				key={`div.0.${_key}.${index}`}
				className={"dndItem admin-dnd-item-border"}
			>
				<div key={`div.1.${_key}.${index}`} className={"dndHeader"}>
					<div
						className={"grab admin-dnd-item-header"}
						{...attributes}
						{...listeners}
					>
						<h2 className={"admin-primary"} key={`h2.0.${_key}.${index}`}>
							{_key.split(".")[0]}
						</h2>
						<PropsList _props={_props} />
						<br />
					</div>
					<HeaderButtons
						_key={_key}
						index={index}
						elements={elements}
						siblings={siblings}
					/>
				</div>
				<SortableContext
					items={elements}
					strategy={verticalListSortingStrategy}
				>
					<div
						key={`div.2.${_key}.${index}`}
						className={"dndContentVertical admin-dnd-item-content"}
					>
						{utils.showContent(elements, _key, active)}
					</div>
				</SortableContext>
			</div>
		</div>
	);
};

export default Container;
