import React, {useEffect, useState} from "react";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import {withTranslation} from "react-i18next";
import PropTypes from "prop-types";
import {Label} from "semantic-ui-react";

import sharedConfig from "../../../../../shared/sharedConfig";
import NEWSLETTER_CONFIG from "../../../../../server/config/config.newsletter.json"
import {regexpMail} from "../../../../helper/util";
import * as utils from "./utils";
import Checkbox from "semantic-ui-react/dist/commonjs/modules/Checkbox";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";

let emitCustomEvent = () => {
};

if (typeof document !== "undefined") {
    emitCustomEvent = require('react-custom-events').emitCustomEvent;
}

const EditMail = (props) => {
    const {t, type, state} = props;
    const [newMailConfig, setNewMailConfig] =useState(state.email)
    const [newNewsletterConfig, setNewNewsletterConfig] = useState(state.newsletter)
    const [isLoading, setIsLoading] = useState(false);
    const [saveWasClicked, setSaveWasClicked] = useState(false);
    const [update, setUpdate] = useState(false);

    useEffect(() => {
        setTimeout(_ => {
            setSaveWasClicked(false)
        }, 8000);
    }, [saveWasClicked]);

    if (type === "mail") {
        return (
            <div
                key={`edit-mail-0`}
                className={"admin-editor"}>
                <Grid key={`edit-mail-1`}>
                    <Grid.Row
                        key={`edit-mail-2`}
                        columns={2}>
                        <Grid.Column key={`edit-mail-3`}>
                            <h1
                                key={`edit-mail-4`}
                                className={'admin-primary admin-edit-header'}>
                                {t("adminManagement:mail.header")}
                            </h1>
                            <p key={`edit-mail-5`}>{t("adminManagement:mail.description")}</p>
                        </Grid.Column>
                        <Grid.Column
                            key={`edit-mail-6`}
                            textAlign={"right"}>
                            <Button
                                key={`edit-mail-7`}
                                disabled={!newMailConfig.user.match(regexpMail) || isLoading}
                                style={{marginRight: '1rem', floated: 'right'}}
                                loading={isLoading}
                                className={"admin-button-save"}
                                onClick={_ => {
                                    emitCustomEvent("rebuild");
                                    setSaveWasClicked(true);
                                    utils.saveEmailConfig(newMailConfig, setIsLoading);
                                    utils.saveNewsletterConfig(newNewsletterConfig, setIsLoading);
                                    emitCustomEvent('updateEmail',newMailConfig);
                                    emitCustomEvent('updateNewsletter',newNewsletterConfig);
                                    setUpdate(!update);
                                }}>
                                {t("common:common.save")}
                            </Button>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <Label
                    key={`edit-mail-8`}
                    className={saveWasClicked ? 'build-hint-fade-in' : 'build-hint-fade-out'}
                    pointing={"right"}
                    style={{float: "right"}}>
                    {t("adminManagement:adminManagement.wait_alert")}
                </Label>
                <Grid key={`edit-mail-9`}>
                    <Grid.Row key={`edit-mail-10`}>
                        <Grid.Column key={`edit-mail-11`}>
                            <h4
                                key={`edit-mail-12`}
                                className={"admin-primary"}>
                                {t("adminManagement:mail.user")}
                            </h4>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row key={`edit-mail-13`}>
                        <Grid.Column
                            key={`edit-mail-14`}
                            width={7}>
                            <input
                                key={`edit-mail-15`}
                                className={"admin-disabled admin-border-secondary"}
                                style={{
                                    marginTop: '5px',
                                    width: '100%',
                                    borderStyle: 'dashed',
                                    borderWidth: '2px',
                                    borderRadius: '3px',
                                    padding: '8px 4px'
                                }}
                                disabled
                                value={sharedConfig.MAILER.USER}/>
                        </Grid.Column>
                        <Grid.Column key={`edit-mail-16`}>
                            <Icon
                                key={`edit-mail-17`}
                                style={{marginTop: '10px'}}
                                name={'chevron right'}/>
                        </Grid.Column>
                        <Grid.Column
                            key={`edit-mail-18`}
                            width={7}>
                            <input
                                key={`edit-mail-19`}
                                className={'admin-primary admin-border-secondary'}
                                style={{
                                    marginTop: '6px',
                                    width: '100%',
                                    outline: 'none',
                                    borderWidth: '1px',
                                    borderRadius: '3px',
                                    padding: '8px 4px'
                                }}
                                value={newMailConfig.user}
                                onChange={(e) => {
                                    setNewMailConfig({
                                        ...newMailConfig,
                                        user: e.target.value
                                    })
                                }}/>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row key={`edit-mail-20`}>
                        <Grid.Column key={`edit-mail-21`}>
                            <h4
                                key={`edit-mail-22`}
                                className={"admin-primary"}>
                                {t("adminManagement:mail.password")}
                            </h4>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row key={`edit-mail-22`}>
                        <Grid.Column
                            key={`edit-mail-23`}
                            width={7}>
                            <input
                                key={`edit-mail-24`}
                                className={"admin-disabled admin-border-secondary"}
                                style={{
                                    marginTop: '5px',
                                    width: '100%',
                                    borderStyle: 'dashed',
                                    borderWidth: '2px',
                                    borderRadius: '3px',
                                    padding: '8px 4px'
                                }}
                                value={"**********"}
                                disabled/>
                        </Grid.Column>
                        <Grid.Column key={`edit-mail-25`}>
                            <Icon
                                key={`edit-mail-26`}
                                style={{marginTop: '10px'}}
                                name={'chevron right'}/>
                        </Grid.Column>
                        <Grid.Column
                            key={`edit-mail-27`}
                            width={7}>
                            <input
                                key={`edit-mail-28`}
                                className={'admin-primary admin-border-secondary'}
                                style={{
                                    marginTop: '6px',
                                    width: '100%',
                                    outline: 'none',
                                    borderWidth: '1px',
                                    borderRadius: '3px',
                                    padding: '8px 4px'
                                }}
                                value={newMailConfig.password}
                                onChange={(e) => {
                                    setNewMailConfig({
                                        ...newMailConfig,
                                        password: e.target.value
                                    })
                                }}/>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row key={`edit-mail-29`}>
                        <Grid.Column key={`edit-mail-30`}>
                            <h4
                                key={`edit-mail-31`}
                                className={"admin-primary"}>
                                {t("adminManagement:mail.email")}
                            </h4>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row key={`edit-mail-31`}>
                        <Grid.Column
                            key={`edit-mail-32`}
                            width={7}>
                            <input
                                key={`edit-mail-33`}
                                className={"admin-disabled admin-border-secondary"}
                                style={{
                                    marginTop: '5px',
                                    width: '100%',
                                    borderStyle: 'dashed',
                                    borderWidth: '2px',
                                    borderRadius: '3px',
                                    padding: '8px 4px'
                                }}
                                disabled
                                value={sharedConfig.MAILER.TO_USER}/>
                        </Grid.Column>
                        <Grid.Column key={`edit-mail-34`}>
                            <Icon
                                key={`edit-mail-35`}
                                style={{marginTop: '10px'}}
                                name={'chevron right'}/>
                        </Grid.Column>
                        <Grid.Column
                            key={`edit-mail-36`}
                            width={7}>
                            <input
                                key={`edit-mail-37`}
                                className={'admin-primary admin-border-secondary'}
                                style={{
                                    marginTop: '6px',
                                    width: '100%',
                                    outline: 'none',
                                    borderWidth: '1px',
                                    borderRadius: '3px',
                                    padding: '8px 4px'
                                }}
                                value={newMailConfig.to}
                                onChange={(e) => {
                                    setNewMailConfig({
                                        ...newMailConfig,
                                        to: e.target.value
                                    })
                                }}/>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row key={`edit-mail-38`}>
                        <Grid.Column key={`edit-mail-39`}>
                            <h4
                                key={`edit-mail-40`}
                                className={"admin-primary"}>
                                {t("adminManagement:mail.host")}
                            </h4>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row key={`edit-mail-41`}>
                        <Grid.Column
                            key={`edit-mail-42`}
                            width={7}>
                            <input
                                key={`edit-mail-43`}
                                className={"admin-disabled admin-border-secondary"}
                                style={{
                                    marginTop: '5px',
                                    width: '100%',
                                    borderStyle: 'dashed',
                                    borderWidth: '2px',
                                    borderRadius: '3px',
                                    padding: '8px 4px'
                                }}
                                disabled
                                value={sharedConfig.MAILER.HOST}/>
                        </Grid.Column>
                        <Grid.Column key={`edit-mail-44`}>
                            <Icon
                                key={`edit-mail-45`}
                                style={{marginTop: '10px'}}
                                name={'chevron right'}/>
                        </Grid.Column>
                        <Grid.Column
                            key={`edit-mail-46`}
                            width={7}>
                            <input
                                key={`edit-mail-47`}
                                className={'admin-primary admin-border-secondary'}
                                style={{
                                    marginTop: '6px',
                                    width: '100%',
                                    outline: 'none',
                                    borderWidth: '1px',
                                    borderRadius: '3px',
                                    padding: '8px 4px'
                                }}
                                value={newMailConfig.host}
                                onChange={(e) => {
                                    setNewMailConfig({
                                        ...newMailConfig,
                                        host: e.target.value
                                    })
                                }}/>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row key={`edit-mail-48`}>
                        <Grid.Column key={`edit-mail-49`}>
                            <h4
                                key={`edit-mail-50`}
                                className={"admin-primary"}>
                                {t("adminManagement:mail.port")}
                            </h4>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row key={`edit-mail-51`}>
                        <Grid.Column
                            key={`edit-mail-52`}
                            width={7}>
                            <input
                                key={`edit-mail-53`}
                                className={"admin-disabled admin-border-secondary"}
                                style={{
                                    marginTop: '5px',
                                    width: '100%',
                                    borderStyle: 'dashed',
                                    borderWidth: '2px',
                                    borderRadius: '3px',
                                    padding: '8px 4px'
                                }}
                                disabled
                                value={sharedConfig.MAILER.PORT}/>
                        </Grid.Column>
                        <Grid.Column key={`edit-mail-54`}>
                            <Icon
                                key={`edit-mail-55`}
                                style={{marginTop: '10px'}}
                                name={'chevron right'}/>
                        </Grid.Column>
                        <Grid.Column
                            key={`edit-mail-56`}
                            width={7}>
                            <input
                                key={`edit-mail-57`}
                                className={'admin-primary admin-border-secondary'}
                                style={{
                                    marginTop: '6px',
                                    width: '100%',
                                    outline: 'none',
                                    borderWidth: '1px',
                                    borderRadius: '3px',
                                    padding: '8px 4px'
                                }}
                                value={newMailConfig.port}
                                onChange={(e) => {
                                    setNewMailConfig({
                                        ...newMailConfig,
                                        port: e.target.value
                                    })
                                }}/>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row key={`edit-mail-58`}>
                        <Grid.Column key={`edit-mail-59`}>
                            <h4
                                key={`edit-mail-60`}
                                className={"admin-primary"}>
                                {t("adminManagement:mail.confirmation")}
                            </h4>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row key={`edit-mail-61`}>
                        <Grid.Column
                            key={`edit-mail-62`}
                            width={7}>
                            <Checkbox
                                key={`edit-mail-63`}
                                label={t("adminManagement:mail.send_confirmation")}
                                checked={newMailConfig.sendConfirm}
                                onChange={() => {
                                    setNewMailConfig({
                                        ...newMailConfig,
                                        sendConfirm: !newMailConfig.sendConfirm
                                    })
                                }}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row key={`edit-mail-64`}>
                        <Grid.Column key={`edit-mail-65`}>
                            <p key={`edit-mail-66`}>{t("adminManagement:mail.info")}</p>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        )
    } else if (type === "newsletter") {
        return (
            <div
                key={`edit-mail-67`}
                className={"admin-editor"}>
                <Grid key={`edit-mail-68`}>
                    <Grid.Row
                        key={`edit-mail-69`}
                        columns={2}>
                        <Grid.Column key={`edit-mail-70`}>
                            <h1
                                key={`edit-mail-71`}
                                className={'admin-primary admin-edit-header'}>
                                {t("login:user.login")} Cleverreach
                            </h1>
                            <p key={`edit-mail-72`}>{t("adminManagement:mail.description")}</p>
                        </Grid.Column>
                        <Grid.Column
                            key={`edit-mail-73`}
                            textAlign={"right"}>
                            <Button
                                key={`edit-mail-74`}
                                disabled={!newNewsletterConfig.login.match(regexpMail) || isLoading}
                                style={{marginRight: '1rem'}}
                                loading={isLoading}
                                className={"admin-button-save"}
                                onClick={() => {
                                    emitCustomEvent("rebuild");
                                    setSaveWasClicked(true);
                                    utils.saveNewsletterConfig(newNewsletterConfig, setIsLoading);
                                    emitCustomEvent('updateNewsletter',newNewsletterConfig);
                                    setUpdate(!update);
                                }}>
                                {t("common:common.save")}
                            </Button>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <Label
                    key={`edit-mail-75`}
                    className={saveWasClicked ? 'build-hint-fade-in' : 'build-hint-fade-out'}
                    pointing={"right"}
                    style={{float: "right"}}>
                    {t("adminManagement:adminManagement.wait_alert")}
                </Label>
                <Grid key={`edit-mail-76`}>
                    <Grid.Row key={`edit-mail-77`}>
                        <Grid.Column key={`edit-mail-78`}>
                            <h4
                                key={`edit-mail-79`}
                                className={"admin-primary"}>
                                {t("adminManagement:mail.user")} id
                            </h4>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row key={`edit-mail-77-1`}>
                        <Grid.Column
                            key={`edit-mail-78`}
                            width={7}>
                            <input
                                key={`edit-mail-79`}
                                className={"admin-disabled admin-border-secondary"}
                                style={{
                                    marginTop: '5px',
                                    width: '100%',
                                    borderStyle: 'dashed',
                                    borderWidth: '2px',
                                    borderRadius: '3px',
                                    padding: '8px 4px'
                                }}
                                disabled
                                value={NEWSLETTER_CONFIG.client_id}/>
                        </Grid.Column>
                        <Grid.Column key={`edit-mail-80`}>
                            <Icon
                                key={`edit-mail-81`}
                                style={{marginTop: '10px'}}
                                name={'chevron right'}/>
                        </Grid.Column>
                        <Grid.Column
                            key={`edit-mail-82`}
                            width={7}>
                            <input
                                key={`edit-mail-83`}
                                className={'admin-primary admin-border-secondary'}
                                style={{
                                    marginTop: '6px',
                                    width: '100%',
                                    outline: 'none',
                                    borderWidth: '1px',
                                    borderRadius: '3px',
                                    padding: '8px 4px'
                                }}
                                value={newNewsletterConfig.clientId}
                                onChange={(e) => {
                                    setNewNewsletterConfig({
                                        ...newNewsletterConfig,
                                        clientId: e.target.value
                                    })
                                }}/>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row key={`edit-mail-94-1`}>
                        <Grid.Column key={`edit-mail-95`}>
                            <h4
                                key={`edit-mail-96`}
                                className={"admin-primary"}>
                                {t("adminManagement:mail.groupid")}
                            </h4>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row key={`edit-mail-97-1`}>
                        <Grid.Column
                            key={`edit-mail-98`}
                            width={7}>
                            <input
                                key={`edit-mail-99`}
                                className={"admin-disabled admin-border-secondary"}
                                style={{
                                    marginTop: '5px',
                                    width: '100%',
                                    borderStyle: 'dashed',
                                    borderWidth: '2px',
                                    borderRadius: '3px',
                                    padding: '8px 4px'
                                }}
                                value={NEWSLETTER_CONFIG.group_id}
                                disabled/>
                        </Grid.Column>
                        <Grid.Column key={`edit-mail-100`}>
                            <Icon
                                key={`edit-mail-101`}
                                style={{marginTop: '10px'}}
                                name={'chevron right'}/>
                        </Grid.Column>
                        <Grid.Column
                            key={`edit-mail-102`}
                            width={7}>
                            <input
                                key={`edit-mail-103`}
                                className={'admin-primary admin-border-secondary'}
                                style={{
                                    marginTop: '6px',
                                    width: '100%',
                                    outline: 'none',
                                    borderWidth: '1px',
                                    borderRadius: '3px',
                                    padding: '8px 4px'
                                }}
                                value={newNewsletterConfig.groupId}
                                onChange={(e) => {
                                    setNewNewsletterConfig({
                                        ...newNewsletterConfig,
                                        groupId: e.target.value
                                    })
                                }}/>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row key={`edit-mail-84`}>
                        <Grid.Column key={`edit-mail-85`}>
                            <h4
                                key={`edit-mail-86`}
                                className={"admin-primary"}>
                                {t("adminManagement:mail.user")}
                            </h4>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row key={`edit-mail-87`}>
                        <Grid.Column
                            key={`edit-mail-88`}
                            width={7}>
                            <input
                                key={`edit-mail-89`}
                                className={"admin-disabled admin-border-secondary"}
                                style={{
                                    marginTop: '5px',
                                    width: '100%',
                                    borderStyle: 'dashed',
                                    borderWidth: '2px',
                                    borderRadius: '3px',
                                    padding: '8px 4px'
                                }}
                                disabled
                                value={NEWSLETTER_CONFIG.login}/>
                        </Grid.Column>
                        <Grid.Column key={`edit-mail-90`}>
                            <Icon
                                key={`edit-mail-91`}
                                style={{marginTop: '10px'}}
                                name={'chevron right'}/>
                        </Grid.Column>
                        <Grid.Column
                            key={`edit-mail-92`}
                            width={7}>
                            <input
                                key={`edit-mail-93`}
                                className={'admin-primary admin-border-secondary'}
                                style={{
                                    marginTop: '6px',
                                    width: '100%',
                                    outline: 'none',
                                    borderWidth: '1px',
                                    borderRadius: '3px',
                                    padding: '8px 4px'
                                }}
                                value={newNewsletterConfig.login}
                                onChange={(e) => {
                                    setNewNewsletterConfig({
                                        ...newNewsletterConfig,
                                        login: e.target.value
                                    })
                                }}/>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row key={`edit-mail-94`}>
                        <Grid.Column key={`edit-mail-95`}>
                            <h4
                                key={`edit-mail-96`}
                                className={"admin-primary"}>
                                {t("adminManagement:mail.password")}
                            </h4>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row key={`edit-mail-97`}>
                        <Grid.Column
                            key={`edit-mail-98`}
                            width={7}>
                            <input
                                key={`edit-mail-99`}
                                className={"admin-disabled admin-border-secondary"}
                                style={{
                                    marginTop: '5px',
                                    width: '100%',
                                    borderStyle: 'dashed',
                                    borderWidth: '2px',
                                    borderRadius: '3px',
                                    padding: '8px 4px'
                                }}
                                value={"**********"}
                                disabled/>
                        </Grid.Column>
                        <Grid.Column key={`edit-mail-100`}>
                            <Icon
                                key={`edit-mail-101`}
                                style={{marginTop: '10px'}}
                                name={'chevron right'}/>
                        </Grid.Column>
                        <Grid.Column
                            key={`edit-mail-102`}
                            width={7}>
                            <input
                                key={`edit-mail-103`}
                                className={'admin-primary admin-border-secondary'}
                                style={{
                                    marginTop: '6px',
                                    width: '100%',
                                    outline: 'none',
                                    borderWidth: '1px',
                                    borderRadius: '3px',
                                    padding: '8px 4px'
                                }}
                                value={newNewsletterConfig.password}
                                onChange={(e) => {
                                    setNewNewsletterConfig({
                                        ...newNewsletterConfig,
                                        password: e.target.value
                                    })
                                }}/>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        )
    }
}

EditMail.propTypes =
    {
        type: PropTypes.string,
    };

EditMail.defaultProps =
    {
        type: "",
    };

export default withTranslation(['common', 'adminManagement', 'login'])(EditMail);