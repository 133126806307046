import React, {useEffect, useState} from "react";
import Table from "semantic-ui-react/dist/commonjs/collections/Table";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import {withTranslation} from "react-i18next";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import Popup from "semantic-ui-react/dist/commonjs/modules/Popup";
import {NavLink} from "react-router-dom";
import * as utils from "../utils";

let emitCustomEvent = () => {
};

if (typeof document !== "undefined") {
    emitCustomEvent = require('react-custom-events').emitCustomEvent;
}

//type: page_builder, menu_builder, header_builder, footer_builder
const UserContentTable = (props) => {
    const {type, contentToShow, history, hierarchy, elements, _title, _link, i18n} = props;
    const [_elements, setElements] = useState(elements);
    const [title, setTitle] = useState(_title);
    const [link, setLink] = useState(_link);
    const [deleting, setDeleting] = useState('');
    const [editMode, setEditMode] = useState(false);
    const [sticky, setSticky] = useState(false);
    const [used, setUsed] = useState(-1);

    useEffect(() => {
        for (let i = 0; i < contentToShow.length; i++) {
            if (contentToShow[i].used)
                setUsed(i);
        }
    }, [contentToShow])

    useEffect(() => {
        utils.updateUsed(used, contentToShow, type);
    }, [used])

    useEffect(() => {
        emitCustomEvent("setElements", _elements);
    }, [_elements]);

    useEffect(() => {
        emitCustomEvent("setEditMode", editMode);
    }, [editMode]);

    useEffect(() => {
        emitCustomEvent("updateContent", "");
        setDeleting('');
    }, [deleting, editMode])

    useEffect(() => {
        emitCustomEvent("setTitle", title);
    }, [title]);

    useEffect(() => {
        emitCustomEvent("setLink", link);
    }, [link]);

    useEffect(() => {
        emitCustomEvent("setSticky", sticky);
    }, [sticky]);

    return (
        <Table
            key={`user-content-0`}
            basic={"very"}>
            <Table.Header key={`user-content-1`}>
                <Table.Row key={`user-content-2`}>
                    <Table.HeaderCell
                        key={`user-content-3`}>{i18n.t("adminManagement:adminManagement.name")}</Table.HeaderCell>
                    {
                        type === "page_builder" ?
                            <Table.HeaderCell
                                key={`user-content-4`}>{i18n.t("adminManagement:adminManagement.link")}</Table.HeaderCell>
                            : null
                    }
                    <Table.HeaderCell
                        key={`user-content-5`}
                        textAlign={"right"}>
                        {i18n.t("adminManagement:adminManagement.options")}
                    </Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body key={`user-content-6`}>
                {
                    contentToShow.map((item, index) => (
                        <Table.Row key={`user-content-7-${index}`}>
                            <Table.Cell key={`user-content-8-${index}`}>{item.title}</Table.Cell>
                            {
                                type === "page_builder" ?
                                    <Table.Cell key={`user-content-9-${index}`}>{item.link}</Table.Cell>
                                    : null
                            }
                            <Table.Cell
                                key={`user-content-10-${index}`}
                                textAlign={"right"}>
                                <Button
                                    key={`user-content-11-${index}`}
                                    className={"admin-button-primary"}
                                    onClick={() => {
                                        emitCustomEvent("clickedUsed", index === used);
                                        utils.handleEdit(history, setEditMode, item, hierarchy, setElements, elements, setTitle, setLink, type, setSticky);
                                    }}>
                                    {i18n.t("common:common.edit")}
                                </Button>
                                {
                                    type === "page_builder" ?
                                        <Button
                                            key={`user-content-12-${index}`}
                                            className={"admin-button-primary"}>
                                            <NavLink
                                                key={`user-content-13-${index}`}
                                                className={"link-admin"}
                                                to={`/${i18n.language}/${item.link}`}>
                                                {i18n.t("adminManagement:page.view")}
                                            </NavLink>
                                        </Button>
                                        : type === "footer_builder" ?
                                            <Button
                                                disabled={used === index}
                                                key={`user-content-14-${index}`}
                                                className={"admin-button-primary"}
                                                onClick={() => {
                                                    emitCustomEvent("rebuild");
                                                    utils.buildFooter(item.title).then(returnVal => {
                                                        if(returnVal === -1)
                                                            console.error("build footer failed!");
                                                        else
                                                            setUsed(index);
                                                    });
                                                }}>
                                                {
                                                    used === index ?
                                                        i18n.t("adminManagement:footer.used")
                                                        : i18n.t("adminManagement:footer.use")
                                                }
                                            </Button>
                                            : type === "header_builder" ?
                                                <Button
                                                    disabled={used === index}
                                                    key={`user-content-15-${index}`}
                                                    className={"admin-button-primary"}
                                                    onClick={() => {
                                                        emitCustomEvent("rebuild");
                                                        utils.buildHeader(item.title).then(returnVal => {
                                                            if(returnVal === -1)
                                                                console.error("build header failed!");
                                                            else
                                                                setUsed(index);
                                                        });
                                                    }}>
                                                    {
                                                        used === index ?
                                                            i18n.t("adminManagement:header.used")
                                                            : i18n.t("adminManagement:header.use")
                                                    }
                                                </Button>
                                                : null
                                }
                                <Popup
                                    key={`user-content-16-${index}`}
                                    trigger={
                                        <Button
                                            key={`user-content-17-${index}`}
                                            disabled={utils.deleteDisabled(item.title)}
                                            className={"admin-button-warn"}>
                                            {
                                                deleting === item.title ?
                                                    i18n.t("common:common.deleting")
                                                    : i18n.t("common:common.delete")
                                            }
                                            <Icon
                                                key={`user-content-18-${index}`}
                                                style={{marginLeft: "0.5rem"}}
                                                name={
                                                    deleting === item.title ?
                                                        'erase'
                                                        : 'trash alternate outline'}/>
                                        </Button>
                                    }
                                    content={
                                        <Button
                                            key={`user-content-19-${index}`}
                                            className={"admin-button-warn"}
                                            onClick={() => {
                                                emitCustomEvent("rebuild");
                                                setDeleting(item.title)
                                                utils.handleDelete(item.title, type)
                                            }}>
                                            {i18n.t("common:common.im_sure")}
                                        </Button>
                                    }
                                    on='click'
                                    position='top right'
                                />
                            </Table.Cell>
                        </Table.Row>
                    ))
                }
            </Table.Body>
        </Table>
    )
}

export default withTranslation(['common', 'adminManagement'])(UserContentTable);