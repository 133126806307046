import React, { useEffect, useState } from "react";
import moment from "moment";
import { withTranslation } from "react-i18next";
import Form from "semantic-ui-react/dist/commonjs/collections/Form";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import TextArea from "semantic-ui-react/dist/commonjs/addons/TextArea";
import Divider from "semantic-ui-react/dist/commonjs/elements/Divider";
import {
	addArticle,
	getArticleByBlogLink,
	getArticleByBlogLinkAdmin,
	getImagesInFolder,
} from "../../../pages/blog/BlogUtil";
import config from "../../../config/main.config";
import userStore from "../userManagement/userStore";
import { getFolders } from "../../../helper/util";
import ImageCropComponent from "../../editImage/ImageCropComponent";
import sharedConfig from "../../../../shared/sharedConfig";
import Autocomplete from "../../Autocomplete";

let Editor = null;
if (typeof window !== "undefined") {
	Editor = require("../../editText/Editor").default;
}

function showImagesInFolder(
	images,
	activeFolder,
	setArticle,
	showButtonMode,
	setThumbnailURL,
	setBlogImageURL,
) {
	return (
		<Grid.Column
			key={"bloog-author-0"}
			style={{ marginTop: "10px" }}
			className={"admin-primary"}
		>
			{images.map((image, index) => (
				<picture key={`blog-author-1-${index}`}>
					<source
						key={`blog-author-2-${index}`}
						media={"(min-width: 1024px)"}
						srcSet={
							config.BASE_URL_IMAGES +
							"desktop/" +
							(/\.(gif|jpe?g|tiff?|png|webp|bmp)$/i.test(image.name)
								? image.name.substring(0, image.name.lastIndexOf("."))
								: image.name) +
							`.webp ${sharedConfig.IMAGE_SIZES.LARGE}w` +
							"," +
							config.BASE_URL_IMAGES +
							"desktop/" +
							image.name
						}
					/>
					<source
						key={`blog-author-3-${index}`}
						media={"(min-width: 321px)"}
						srcSet={
							config.BASE_URL_IMAGES +
							"tablet/" +
							(/\.(gif|jpe?g|tiff?|png|webp|bmp)$/i.test(image.name)
								? image.name.substring(0, image.name.lastIndexOf("."))
								: image.name) +
							`.webp ${sharedConfig.IMAGE_SIZES.LARGE}w` +
							"," +
							config.BASE_URL_IMAGES +
							"tablet/" +
							image.name
						}
					/>
					<source
						key={`blog-author-4-${index}`}
						media={"(max-width: 320px)"}
						srcSet={
							config.BASE_URL_IMAGES +
							"mobile/" +
							(/\.(gif|jpe?g|tiff?|png|webp|bmp)$/i.test(image.name)
								? image.name.substring(0, image.name.lastIndexOf("."))
								: image.name) +
							`.webp ${sharedConfig.IMAGE_SIZES.LARGE}w` +
							"," +
							config.BASE_URL_IMAGES +
							"mobile/" +
							image.name
						}
					/>
					<img
						key={`blog-author-5-${index}`}
						style={{
							width: "300px",
							height: "auto",
							border: "1px solid #000000",
							marginLeft: "5px",
							marginRight: "5px",
							cursor: "pointer",
						}}
						onClick={() =>
							setImage(
								image.name,
								setArticle,
								showButtonMode,
								setThumbnailURL,
								setBlogImageURL,
							)
						}
						src={
							config.BASE_URL_IMAGES +
							`userFolders/${activeFolder}/${image.name}`
						}
						alt={""}
					/>
				</picture>
			))}
		</Grid.Column>
	);
}

function showUserFolders(folders, setActiveFolder, setImages, activeFolder) {
	return folders.map((folder, index) => (
		<Button
			key={`blog-author-6-${index}`}
			active={activeFolder === folder.name}
			icon={"folder"}
			className={"admin-button-primary"}
			content={folder.name}
			onClick={() => {
				setActiveFolder(folder.name);
				getImagesInFolder(folder.name).then((images) => setImages(images));
			}}
		/>
	));
}

function getArticle(
	link,
	setThumbnailURL,
	setBlogImageURL,
	setArticle,
	setLoaded,
) {
	getArticleByBlogLinkAdmin(link).then((res) => {
		setThumbnailURL(config.BASE_URL_IMAGES + "all-images/" + res.thumbnail);
		setBlogImageURL(config.BASE_URL_IMAGES + "all-images/" + res.blogImage);
		setArticle(res);
		setLoaded(true);
	});
}

function checkForBlogLink(article, onClose, isNewArticle, setIsBlogLinkInUse) {
	getArticleByBlogLink(article.blogLink).then((result) => {
		console.log(result, article);
		if (
			Object.getOwnPropertyNames(result).length > 0 &&
			article.id !== result.id
		) {
			setIsBlogLinkInUse(true);
			alert("Blog Link wird Bereits verwendet!");
		} else {
			addArticle(article, isNewArticle).then(() => {
				onClose();
			});
			setIsBlogLinkInUse(false);
		}
	});
}

function valueChanged(name, e, setArticle) {
	let value = e.target.value;
	if (name === "blogLink") {
		let regex = /:?\/?\??#?\[?]?@?!?\$?&?'?\(?\)?\*?\+?,?;?=?\.?\u0020?/g;
		value = value.replace(regex, "");
		value = value.charAt(0).toLowerCase() + value.slice(1);
	}
	setArticle((prevState) => ({ ...prevState, [name]: value }));
}

function setImage(
	image,
	setArticle,
	showButtonMode,
	setThumbnailURL,
	setBlogImageURL,
) {
	let url = config.BASE_URL_IMAGES + "all-images/" + image;
	switch (showButtonMode) {
		case "thumbnail":
			setArticle((prevState) => ({ ...prevState, thumbnail: image }));
			setThumbnailURL(url);
			break;
		case "blogImage":
			setArticle((prevState) => ({ ...prevState, blogImage: image }));
			setBlogImageURL(url);
			break;
		default:
			break;
	}
}

const cropImage = (cropModal, folders, setFolders, setCropModal) => {
	if (cropModal) {
		return (
			<ImageCropComponent
				key={`blog-author-7`}
				id={"blog"} //needs to be Blog so the Image Upload works correct!
				cropModal={cropModal}
				folders={folders}
				onClose={() => {
					getFolders().then((folders) => {
						setFolders(folders);
					});
					setCropModal(false);
				}}
			/>
		);
	}
};

const BlogAuthorEdit = (props) => {
	let { blogLink, newArticle, i18n, onClose } = props;
	const [isBlogLinkInUse, setIsBlogLinkInUse] = useState(false);
	const [loaded, setLoaded] = useState(false);
	const [cropModal, setCropModal] = useState(false);
	const [isEditSource, setIsEditSource] = useState(false);
	const [folders, setFolders] = useState([]);
	const [images, setImages] = useState([]);
	const [activeFolder, setActiveFolder] = useState("");
	const [thumbnailURL, setThumbnailURL] = useState("");
	const [blogImageURL, setBlogImageURL] = useState("");
	const [showButtonMode, setShowButtonMode] = useState("blogImage");
	const [formErrors, setFormErrors] = useState({
		header: false,
		category: false,
		blogLink: false,
		description: false,
	});
	const [article, setArticle] = useState({
		header: "",
		thumbnail: "",
		blogImage: "",
		text: "",
		category: "",
		description: "",
		date: moment().format("YYYY-MM-DD"),
		published: false,
		seen: 0,
		userId: "",
		blogLink: "",
	});
	useEffect(() => {
		getImagesInFolder(activeFolder).then((images) => setImages(images));
	}, [cropModal]);

	useEffect(() => {
		getFolders().then((folders) => {
			setFolders(folders);
		});
		if (blogLink !== "new") {
			getArticle(
				blogLink,
				setThumbnailURL,
				setBlogImageURL,
				setArticle,
				setLoaded,
			);
		} else {
			setLoaded(true);
		}
	}, []);

	function checkForErrors() {
		const validateAndSetFormErrors = (fieldName, value) => {
			let isValid = !!value; // prüft, ob Wert vorhanden ist
			setFormErrors((prevState) => ({
				...prevState,
				[fieldName]: !isValid,
			}));
			return isValid;
		};

		if (
			validateAndSetFormErrors("header", article.header) &&
			validateAndSetFormErrors("category", article.category) &&
			validateAndSetFormErrors("blogLink", article.blogLink) &&
			validateAndSetFormErrors("description", article.description)
		) {
			checkForBlogLink(article, onClose, newArticle, setIsBlogLinkInUse);
		}
	}

	return (
		<div key={`blog-author-8`} className={"admin-editor"}>
			<div key={`blog-author-9`} className={"grid ui  admin-primary"}>
				<Grid.Row key={`blog-author-10`} columns={2}>
					<Grid.Column key={`blog-author-11`}>
						<h1
							key={`blog-author-12`}
							className={"admin-primary admin-edit-header"}
						>
							{newArticle
								? i18n.t("blog:blog.page_header_create")
								: i18n.t("blog:blog.page_header_edit")}
						</h1>
					</Grid.Column>
					<Grid.Column key={`blog-author-13`} textAlign={"right"}>
						<Button
							key={`blog-author-14`}
							className={"admin-button-warn"}
							onClick={() => onClose()}
						>
							{i18n.t("common:common.cancel")}
						</Button>
						<Button
							key={`blog-author-15`}
							className={"admin-button-save"}
							onClick={() => checkForErrors()}
						>
							{i18n.t("blog:blog.button_save")}
						</Button>
					</Grid.Column>
				</Grid.Row>
				<div key={`blog-author-16`} className={"row "}>
					<div key={`blog-author-17`} className={"column"}>
						<Form key={`blog-author-18`}>
							<Form.Field>
								<label key={`blog-author-19`}>
									{i18n.t("blog:blog.check_to_publish")}
								</label>
								<Form.Checkbox
									key={`blog-author-20`}
									indeterminate={false}
									checked={article.published}
									label={i18n.t("blog:blog.publish")}
									disabled={
										!userStore.isAdmin &&
										!userStore.isContentCreator &&
										!userStore.isAuthor
									}
									onChange={() => {
										setArticle((prev) => ({
											...prev,
											published: !article.published,
										}));
									}}
								/>
							</Form.Field>
							<Form.Field key={`blog-author-21`}>
								<label key={`blog-author-22`}>{i18n.t("blog:blog.date")}</label>
								<input
									key={`blog-author-23`}
									value={article.date}
									type="date"
									placeholder={
										i18n.t("blog:blog.date") + " " + i18n.t("blog:blog.input")
									}
									onChange={(e) => valueChanged("date", e, setArticle)}
								/>
							</Form.Field>
							<Form.Field key={`blog-author-24`}>
								<label key={`blog-author-25`}>
									{i18n.t("blog:blog.author_name")}
								</label>
								<input
									key={`blog-author-26`}
									value={article.authorName}
									placeholder={
										i18n.t("blog:blog.author_name") +
										" " +
										i18n.t("blog:blog.input")
									}
									onChange={(e) => valueChanged("authorName", e, setArticle)}
								/>
							</Form.Field>
							<Form.Field key={`blog-author-27`} error={formErrors.header}>
								<label key={`blog-author-28`}>
									{i18n.t("blog:blog.header")}
								</label>
								<input
									key={`blog-author-29`}
									value={article.header}
									placeholder={
										i18n.t("blog:blog.header") + " " + i18n.t("blog:blog.input")
									}
									onChange={(e) => valueChanged("header", e, setArticle)}
								/>
							</Form.Field>
							<Form.Field key={`blog-author-30`} error={formErrors.description}>
								<label key={`blog-author-31`}>
									{i18n.t("blog:blog.short_description")}
								</label>
								<input
									key={`blog-author-32`}
									value={article.description}
									placeholder={
										i18n.t("blog:blog.short_description") +
										" " +
										i18n.t("blog:blog.input")
									}
									onChange={(e) => valueChanged("description", e, setArticle)}
								/>
							</Form.Field>
							<Form.Field key={`blog-author-33`} error={formErrors.blogLink}>
								<label key={`blog-author-34`}>
									{i18n.t("blog:blog.blog_link")}
								</label>
								<input
									key={`blog-author-35`}
									value={article.blogLink}
									className={isBlogLinkInUse ? "error input" : ""}
									placeholder={
										i18n.t("blog:blog.blog_link") +
										" " +
										i18n.t("blog:blog.input")
									}
									onChange={(e) => {
										valueChanged("blogLink", e, setArticle);
									}}
								/>
							</Form.Field>
							<Divider key={`blog-author-36`} />
							<Form.Field
								key={`blog-author-category`}
								error={formErrors.category}
							>
								<label>{i18n.t("blog:blog.category")}</label>
								<div>
									<Autocomplete
										label={
											i18n.t("blog:blog.category") +
											" " +
											i18n.t("blog:blog.input")
										}
										suggestions={sharedConfig.categories}
										value={article.category}
										onChange={(value) =>
											valueChanged(
												"category",
												{ target: { value: value } },
												setArticle,
											)
										}
									/>
								</div>
							</Form.Field>
							<Form.Field key={`blog-author-37`}>
								<label key={`blog-author-38`}>
									{i18n.t("blog:blog.blog_text")}
								</label>
								<Form.Field
									key={`blog-author-39`}
									style={{ display: "inline-flex" }}
								>
									<label
										key={`blog-author-40`}
										style={{ margin: "auto", paddingRight: "5px" }}
									>
										Edit Source as HTML
									</label>
									<Form.Radio
										key={`blog-author-41`}
										toggle={true}
										checked={isEditSource}
										onChange={() => setIsEditSource(!isEditSource)}
									>
										HTML
									</Form.Radio>
								</Form.Field>
								<div key={`blog-author-42`} style={{ margin: "5px" }} />
								<Form.Field key={`blog-author-43`}>
									{!isEditSource ? (
										Editor &&
										loaded && (
											<Editor
												key={`blog-author-44`}
												text={article.text}
												format={"html"}
												updateText={(text) => {
													setArticle((prevState) => ({
														...prevState,
														text: text,
													}));
												}}
											/>
										)
									) : (
										<Form.Field key={`blog-author-45`}>
											<label key={`blog-author-46`}>HTML:</label>
											<TextArea
												error
												key={`blog-author-47`}
												className={"editSourceTextarea admin-primary"}
												value={article.text}
												onChange={(e) => {
													valueChanged("text", e, setArticle);
												}}
											/>
										</Form.Field>
									)}
								</Form.Field>
							</Form.Field>

							<Divider key={`blog-author-48`} />
							<Form.Field key={`blog-author-49`}>
								<label key={`blog-author-50`}>
									{i18n.t("blog:blog.thumbnail") + ": " + article.thumbnail}
								</label>
								<img
									key={`blog-author-51`}
									src={thumbnailURL}
									className={"articleThumbnail"}
									alt={""}
								/>
							</Form.Field>
							<Form.Field key={`blog-author-52`}>
								<label key={`blog-author-53`}>
									{i18n.t("blog:blog.blog_image") + ": " + article.blogImage}
								</label>
								<img
									key={`blog-author-54`}
									src={blogImageURL}
									className={"articleThumbnail"}
									alt={""}
								/>
							</Form.Field>
							<Divider key={`blog-author-55`} />
						</Form>
					</div>
				</div>
				<div key={`blog-author-56`} className={"row "}>
					<div key={`blog-author-57`} className={"column"}>
						<div key={`blog-author-58`} className="no-padding thumbnail-form">
							<label key={`blog-author-59`} htmlFor="file">
								<b key={`blog-author-60`}>{i18n.t("blog:blog.upload_image")}</b>
							</label>
							<br key={`blog-author-61`} />
							<Button
								key={`blog-author-62`}
								className={"admin-button-primary"}
								onClick={() => setCropModal(true)}
							>
								{i18n.t("common:common.upload")}
							</Button>
						</div>
					</div>
				</div>
				<div key={`blog-author-63`} className={"row "}>
					<div key={`blog-author-64`} className={"column"}>
						<Button.Group key={`blog-author-65`}>
							<Button
								key={`blog-author-66`}
								onClick={() => setShowButtonMode("blogImage")}
								className={"admin-button-primary"}
								toggle
								active={showButtonMode === "blogImage"}
							>
								{i18n.t("blog:blog.chose_image")}
							</Button>
							<Button
								key={`blog-author-67`}
								onClick={() => setShowButtonMode("thumbnail")}
								toggle
								className={"admin-button-primary"}
								active={showButtonMode === "thumbnail"}
							>
								{i18n.t("blog:blog.chose_thumbnail")}
							</Button>
						</Button.Group>
					</div>
				</div>
				<div key={`blog-author-68`} className={"row "}>
					<div key={`blog-author-69`} className={"column"}>
						{showImagesInFolder(
							images,
							activeFolder,
							setArticle,
							showButtonMode,
							setThumbnailURL,
							setBlogImageURL,
						)}
						<b key={`blog-author-70`}>
							{i18n.t("blog:blog.folder")}
							<br />
						</b>
						{showUserFolders(folders, setActiveFolder, setImages)}
					</div>
				</div>
				{cropImage(cropModal, folders, setFolders, setCropModal)}
			</div>
		</div>
	);
};
export default withTranslation(["blog", "common", "nav"])(BlogAuthorEdit);
