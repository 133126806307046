import React, {useEffect, useState} from "react";
import {withTranslation} from "react-i18next";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import {Label} from "semantic-ui-react";

import * as utils from "./utils";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";

let emitCustomEvent = () => {
};

if (typeof document !== "undefined") {
    emitCustomEvent = require('react-custom-events').emitCustomEvent;
}

const EditDimensions = (props) => {
    const {t} = props;
    const [dimensions, setDimensions] = useState([]);
    const [oldDimensions, setOldDimensions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [saveWasClicked, setSaveWasClicked] = useState(false);
    const [update, setUpdate] = useState(false);

    useEffect(() => {
        setTimeout(_ => {
            setSaveWasClicked(false);
        }, 8000);
    }, [saveWasClicked]);

    useEffect(() => {
        utils.getAllDimensions().then(dimensions => {
            setDimensions(dimensions);
        })
        utils.getAllDimensions().then(dimensions => {
            setOldDimensions(dimensions);
        })
    }, []);

    return (
        <div
            key={`edit-dimensions-0`}
            className={"admin-editor"}>
            <Grid key={`edit-dimensions-1`}>
                <Grid.Row
                    key={`edit-dimensions-2`}
                    columns={2}>
                    <Grid.Column key={`edit-dimensions-3`}>
                        <h1
                            key={`edit-dimensions-4`}
                            className={"admin-primary admin-edit-header"}>
                            {t("adminManagement:dimensions.header")}
                        </h1>
                        <p key={`edit-dimensions-5`}>{t("adminManagement:dimensions.description")}</p>
                    </Grid.Column>
                    <Grid.Column
                        key={`edit-dimensions-6`}
                        textAlign={"right"}>
                        <Button
                            key={`edit-dimensions-7`}
                            disabled={isLoading}
                            className={"admin-button-save"}
                            loading={isLoading}
                            onClick={_ => {
                                emitCustomEvent("rebuild");
                                utils.saveDimensions(dimensions, setIsLoading);
                                setSaveWasClicked(true);
                                setUpdate(!update);
                            }}>
                            {t("common:common.save")}
                        </Button>
                    </Grid.Column>
                </Grid.Row>
                <Label
                    key={`edit-dimensions-8`}
                    className={saveWasClicked ? 'build-hint-fade-in' : 'build-hint-fade-out'}
                    pointing={"left"}>
                    {t("adminManagement:adminManagement.wait_alert")}
                </Label>
                {utils.showAllDimensions(dimensions, setDimensions, oldDimensions)}
            </Grid>
        </div>
    )
}

export default withTranslation(['common', 'adminManagement'])(EditDimensions);