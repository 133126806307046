import React, {useEffect, useState} from "react";
import {withTranslation} from "react-i18next";

import * as utils from "./utils";
import Table from "semantic-ui-react/dist/commonjs/collections/Table";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import Popup from "semantic-ui-react/dist/commonjs/modules/Popup";

const ComponentList = ({i18n}) => {
    const [components, setComponents] = useState([]);
    const [showError, setShowError] = useState("");
    const [deleting, setDeleting] = useState('');

    useEffect(() => {
        utils.getComponents().then(_components => {
            setComponents(_components);
        })
    }, [])

    return (
        <Table
            key={`component-list-0`}
            basic={"very"}>
            <Table.Header key={`component-list-1`}>
                <Table.Row key={`component-list-2`}>
                    <Table.HeaderCell key={`component-list-3`}>
                        {i18n.t("adminManagement:customComponent.components")}
                        <Icon
                            key={`component-list-4`}
                            style={{paddingLeft: "1rem", cursor: "pointer"}}
                            name={"sync"}
                            onClick={() => {
                                utils.getComponents().then(_components => {
                                    setComponents(_components);
                                })
                            }}/>
                    </Table.HeaderCell>
                    <Table.HeaderCell key={`component-list-5`}>
                        {i18n.t("adminManagement:customComponent.usage")}
                    </Table.HeaderCell>
                    <Table.HeaderCell key={`component-list-6`}>
                        {i18n.t("adminManagement:customComponent.state")}
                    </Table.HeaderCell>
                    <Table.HeaderCell key={`component-list-7`}>
                        {i18n.t("adminManagement:customComponent.actions")}
                    </Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body key={`component-list-8`}>
                {
                    components.map((component, index) => (
                        <React.Fragment key={`component-list-9-1-${index}`}>
                            <Table.Row
                                key={`component-list-9-${index}`}
                                warning={component.state === "unused"}
                                negative={component.state === "error"}
                                positive={component.state === "ok"}>
                                <Table.Cell key={`component-list-10-${index}`}>{component.name}</Table.Cell>
                                <Table.Cell>
                                    {
                                        component.usage.length > 0 ?
                                            component.usage.map((usage, i) => (
                                                <p key={`component-list-11-${index}-${i}`}>
                                                    {
                                                        usage.includes("Page") ?
                                                            usage.replace("Page", ` (${i18n.t("adminManagement:customComponent.page")})`)
                                                            : usage.includes("Menu") ?
                                                                usage.replace("Menu", ` (${i18n.t("adminManagement:customComponent.menu")})`)
                                                                : usage.includes("Header") ?
                                                                    usage.replace("Header", ` (${i18n.t("adminManagement:customComponent.header")})`)
                                                                    : usage.includes("Footer") ?
                                                                        usage.replace("Footer", ` (${i18n.t("adminManagement:customComponent.footer")})`)
                                                                        : null
                                                    }
                                                </p>
                                            ))
                                            : '-'
                                    }
                                </Table.Cell>
                                <Table.Cell key={`component-list-12-${index}`}>{component.state}</Table.Cell>
                                <Table.Cell key={`component-list-13-${index}`}>
                                    <Button.Group key={`component-list-14-${index}`}>
                                        <Popup
                                            key={`component-list-15-${index}`}
                                            trigger={
                                                <Button
                                                    key={`component-list-16-${index}`}
                                                    disabled={component.usage.length > 0}
                                                    className={"admin-button-warn"}>
                                                    {
                                                        deleting === component.name ?
                                                            "deleting"
                                                            : "delete"
                                                    }
                                                    <Icon
                                                        key={`component-list-17-${index}`}
                                                        style={{marginLeft: "0.5rem"}}
                                                        name={
                                                            deleting === component.name ?
                                                                'erase'
                                                                : 'trash alternate outline'}/>
                                                </Button>
                                            }
                                            content={
                                                <Button
                                                    key={`component-list-18-${index}`}
                                                    className={"admin-button-warn"}
                                                    onClick={() => {
                                                        setDeleting(component.name)
                                                        utils.handleDelete(component.name).then(() => {
                                                        })
                                                    }}>
                                                    {i18n.t("adminManagement:customComponent.sure")}
                                                </Button>
                                            }
                                            on='click'
                                            position='top right'
                                        />
                                        {
                                            component.state === "error" ?
                                                <Button
                                                    key={`component-list-19-${index}`}
                                                    onClick={() => {
                                                        if (showError === component.name)
                                                            setShowError("");
                                                        else
                                                            setShowError(component.name);
                                                    }}>
                                                    {
                                                        showError === component.name ?
                                                            i18n.t("adminManagement:customComponent.hide_error")
                                                            : i18n.t("adminManagement:customComponent.show_error")
                                                    }
                                                </Button>
                                                : null
                                        }
                                    </Button.Group>
                                </Table.Cell>
                            </Table.Row>
                            {
                                showError === component.name ?
                                    <Table.Row
                                        key={`component-list-20-${index}`}
                                        negative={component.state === "error"}>
                                        <Table.Cell
                                            key={`component-list-21-${index}`}
                                            colSpan={4}>
                                            <pre
                                                key={`component-list-22-${index}`}>
                                                {i18n.t("adminManagement:customComponent.issue")}
                                                {component.error}
                                            </pre>
                                        </Table.Cell>
                                    </Table.Row>
                                    : null
                            }
                        </React.Fragment>
                    ))
                }
            </Table.Body>
        </Table>
    )
}

export default withTranslation('adminManagement')(ComponentList);