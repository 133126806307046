import React, {useEffect, useState} from "react";
import {withTranslation} from "react-i18next";

import social_media from "../../../../../public/socialMedia/socialMedia.json";
import * as utils from "./utils";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";

let emitCustomEvent = () => {
};

if (typeof document !== "undefined") {
    emitCustomEvent = require('react-custom-events').emitCustomEvent;
}

const EditSocialMedia = (props) => {
    const {t,state} = props;
    const [socialMedia, setSocialMedia] = useState(social_media.social_media);
    const [newContent, setNewContent] = useState(socialMedia);
    const [isEdit, setIsEdit] = useState([]);
    const [confirmDelete, setConfirmDelete] = useState([]);
    const [update, setUpdate] = useState(false);

    useEffect(() => {
        setSocialMedia([
            ...state,
            ...social_media.social_media
        ])
        socialMedia.forEach(_ => {
            confirmDelete.push(0);
        })
    }, [])

    return (
        <div
            key={`edit-social-0`}
            className={"admin-editor"}>
            <Grid key={`edit-social-1`}>
                <Grid.Row
                    key={`edit-social-2`}
                    columns={2}>
                    <Grid.Column key={`edit-social-3`}>
                        <h1
                            key={`edit-social-4`}
                            className={"admin-primary"}
                            style={{display: 'contents'}}>
                            {t("adminManagement:social_media.header")}
                        </h1>
                        <p key={`edit-social-4-1`}>{t("adminManagement:social_media.description")}</p>
                    </Grid.Column>
                    <Grid.Column
                        key={`edit-social-5`}
                        textAlign={"right"}>

                    </Grid.Column>
                </Grid.Row>
            </Grid>
            {utils.ShowEnabledSocialMedia(socialMedia, setSocialMedia, isEdit, setIsEdit, newContent, setNewContent, confirmDelete, setConfirmDelete, t, update, setUpdate)}
        </div>
    )

}

export default withTranslation(['adminManagement', 'common'])(EditSocialMedia);