import React, {useEffect, useState} from "react";
import {withTranslation} from "react-i18next";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import AdminSideMenu from "../../components/adminComponents/adminSideMenu/AdminSideMenu";
import {renderComponent, validQuery} from "./utils";
import userStore from "../../components/adminComponents/userManagement/userStore";
import {useHistory, useLocation} from "react-router-dom";
import {Helmet} from "react-helmet";
import sharedConfig from "../../../shared/sharedConfig";
import NEWSLETTER_CONFIG from "../../../server/config/config.newsletter.json";
import gtm from "../../../shared/gtm.config.json";
import cookieBannerConfig from "../../../shared/cookieBanner.config.json";
import {useCookies} from "react-cookie";


let emitCustomEvent = () => {
};

let useCustomEventListener = () => {

};
if (typeof document !== "undefined") {
    emitCustomEvent = require('react-custom-events').emitCustomEvent;
    useCustomEventListener = require('react-custom-events').useCustomEventListener;
}

const AdminPage = (props) => {
    const [cookies, setCookie] = useCookies();
    const location = useLocation();
    const history = useHistory();
    const [active, setActive] = useState(location.search.replace("?", ""));
    const [locations, setLocations] = useState([]);
    const [adminState, setAdminState] = useState({
        social: [],
        email: {
            user: sharedConfig.MAILER.USER,
            password: "",
            to: sharedConfig.MAILER.TO_USER,
            host: sharedConfig.MAILER.HOST,
            port: sharedConfig.MAILER.PORT
        },
        newsletter: {
            login: NEWSLETTER_CONFIG.login,
            clientId: NEWSLETTER_CONFIG.client_id,
            groupId: NEWSLETTER_CONFIG.group_id,
            password: "",
            sendConfirm: NEWSLETTER_CONFIG.send_confirm
        },
        gtm: {gtmId: gtm.gtmId},
        cookie: {
            marketing: cookieBannerConfig.marketing,
            statistic: cookieBannerConfig.statistic,
            performance: cookieBannerConfig.performance
        }
    });

    useEffect(() => {
        if (cookies.adminState) {
            setAdminState(cookies.adminState);
        }
    }, [])

    useEffect(() => {
        setCookie('adminState', adminState, {path: '/'});
    }, [adminState])

    useCustomEventListener('updateCookie', (cookie) => {
        setAdminState({
            ...adminState,
            cookie: cookie
        })
    });

    useCustomEventListener('updateGtm', (gtm) => {
        setAdminState({
            ...adminState,
            gtm: gtm
        })
    });

    useCustomEventListener('updateEmail', (email) => {
        setAdminState({
            ...adminState,
            email: email
        })
    });

    useCustomEventListener('updateNewsletter', (newsletter) => {
        setAdminState({
            ...adminState,
            newsletter: newsletter
        })
    });

    useCustomEventListener('updateSocial', (social) => {
        setAdminState({
            ...adminState,
            social: social
        })
    });

    useEffect(() => {
        setActive(location.search.replace("?", ""));
        if (!validQuery(location.search))
            history.replace({search: '?logo'});
        if ((!userStore.isAdmin && userStore.isContentCreator && location.search !== "?blog" && location.search !== "?faq" && location.search !== "?mailManagement") || (!userStore.isAdmin && !userStore.isContentCreator && userStore.isAuthor && location.search !== "?blog"))
            history.replace({search: '?blog'});
        if (locations.length === 0 || location.search !== "") {
            setLocations([...locations, location])
        } else {
            let search = locations.pop().search;
            history.replace({search: search});
            setLocations([]);
        }
    }, [location]);

    useEffect(() => {
        if (userStore.isAdmin) {
            if (location.search === "")
                history.push({search: "?logo"})
        } else if (userStore.isContentCreator || userStore.isAuthor) {
            if (location.search === "")
                history.push({search: "?blog"})
        } else
            history.push("/" + props.i18n.language + "/");
    }, [])

    if (userStore.isAdmin || userStore.isContentCreator || userStore.isAuthor)
        return (
            <Grid style={{minHeight: "100vh"}}>
                <Helmet>
                    <title>SIMPL | Admin</title>
                </Helmet>
                <Grid.Row columns={2} style={{padding: 0}}>
                    <Grid.Column
                        style={{paddingRight: 0}}
                        widescreen={2}
                        computer={3}
                        tablet={4}
                        mobile={5}
                        className={"admin-secondary"}>
                        <AdminSideMenu/>
                    </Grid.Column>
                    <Grid.Column
                        style={{paddingLeft: 0}}
                        widescreen={14}
                        computer={13}
                        tablet={12}
                        mobile={11}
                        className={"admin-primary"}>
                        {renderComponent(active, userStore.isAdmin, adminState)}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    else return null;
}

export default withTranslation(['adminManagement'])(AdminPage);