import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { Icon } from "semantic-ui-react";
import PropTypes from "prop-types";
import Modal from "semantic-ui-react/dist/commonjs/modules/Modal";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Form from "semantic-ui-react/dist/commonjs/collections/Form";

import config from "../../config/main.config";
import {
	backup,
	createFolder,
	deleteFolder,
	getFolders,
	getFromLocalStorage,
} from "../../helper/util";
import configCrop from "../../config/config.Aspect.json";

let ReactCrop = null;
if (typeof window !== "undefined") {
	ReactCrop = require("react-image-crop").default;
}

function uploadEditCroppedImage(
	image,
	id,
	uploadImageName,
	activeFolder,
	onClose,
	altText,
) {
	let regex = /\W/g;
	let fileExtension = uploadImageName.split(".").pop();
	uploadImageName = uploadImageName
		.replace("." + fileExtension, "")
		.replace(regex, "");
	fetch(config.BASE_URL + "admin/images/upload-single-base/" + id, {
		method: "POST",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			"Access-Control-Allow-Origin": "*",
			Authorization: "Bearer " + getFromLocalStorage("token"),
		},
		body: JSON.stringify({
			name: uploadImageName,
			image: image,
			folder: activeFolder,
			alt: altText,
			fileExtension: fileExtension,
		}),
	})
		.then(function (response) {
			if (response.status >= 200 && response.status < 300) {
				backup();
				onClose();
			}
		})
		.catch(() => {
			console.error("error");
		});
}

function onSelectFile(e, setUploadImageName, setSrc, setError) {
	if (e.target.files && e.target.files.length > 0) {
		const fileReader = new FileReader();
		fileReader.addEventListener(
			"load",
			() => {
				setSrc(fileReader.result);
			},
			false,
		);
		if (e.target.files[0].size <= 5000000) {
			setUploadImageName(e.target.files[0].name);
			fileReader.readAsDataURL(e.target.files[0]);
			setError("");
		} else setError("filesize");
	}
}

const showFolders = (folders, setActiveFolder) => {
	if (typeof folders !== "undefined") {
		return folders.map((folder, index) => (
			<Button
				icon={"folder"}
				key={index}
				content={folder.name}
				onClick={() => {
					setActiveFolder(folder.name);
				}}
			/>
		));
	}
};

async function makeClientCrop(crop, setCroppedImageURL, imageRef) {
	if (imageRef && crop.width && crop.height) {
		const croppedImageUrl = await getCroppedImage(imageRef, crop, "new.jpg");
		setCroppedImageURL(croppedImageUrl);
	}
}

function getCroppedImage(image, crop, fileName) {
	const canvas = document.createElement("canvas");
	const scaleX = image.naturalWidth / image.width;
	const scaleY = image.naturalHeight / image.height;
	canvas.width = Math.ceil(crop.width * scaleX);
	canvas.height = Math.ceil(crop.height * scaleY);
	const ctx = canvas.getContext("2d");
	ctx.drawImage(
		image,
		crop.x * scaleX,
		crop.y * scaleY,
		crop.width * scaleX,
		crop.height * scaleY,
		0,
		0,
		crop.width * scaleX,
		crop.height * scaleY,
	);
	return new Promise((resolve) => {
		canvas.toBlob((blob) => {
			if (!blob) {
				console.error("Canvas is empty");
				return;
			}
			blob.name = fileName;
			window.URL.revokeObjectURL(resolve.fileUrl);
			resolve.fileUrl = window.URL.createObjectURL(blob);
			// resolve(this.fileUrl);
			let reader = new FileReader();
			reader.readAsDataURL(blob);
			reader.onloadend = () => {
				let base64data = reader.result;
				resolve(base64data);
			};
		}, "image/jpeg");
	});
}

const ImageCropComponent = (props) => {
	const { i18n, cropModal, onClose, id, altText } = props;
	const [aspectRatio, setAspectRatio] = useState("original");
	const [activeFolder, setActiveFolder] = useState("");
	const [isNewFolder, setIsNewFolder] = useState(false);
	const [folders, setFolders] = useState([]);
	const [newFolderName, setNewFolderName] = useState("");
	const [uploadImageName, setUploadImageName] = useState("");
	const [croppedImageURL, setCroppedImageURL] = useState("");
	const [crop, setCrop] = useState("16_9");
	const [src, setSrc] = useState("");
	const [imageRef, setImageRef] = useState("");
	const [error, setError] = useState("");

	useEffect((_) => {
		getFolders().then((folders) => {
			setFolders(folders);
		});
	}, []);
	return (
		<Modal
			className={"admin-primary"}
			open={cropModal}
			style={{ margin: "auto" }}
		>
			<Modal.Header className={"admin-primary"}>Crop Modal</Modal.Header>
			<Modal.Content className={"admin-primary"} scrolling>
				<h3>Seitenverhältnis auswählen:</h3>
				<Form className={"aspectRadioButtonContainer admin-primary"}>
					<Form.Field className={"aspectRadioButton admin-primary"}>
						<Form.Radio
							className={"admin-primary"}
							label="16:9"
							name="radioGroup"
							onChange={(e, { value }) => {
								setAspectRatio(value);
								setCrop(configCrop[value].crop);
							}}
							value={"16_9"}
							checked={aspectRatio === "16_9"}
						/>
					</Form.Field>
					<Form.Field className={"aspectRadioButton admin-primary"}>
						<Form.Radio
							className={"admin-primary"}
							label="4:3"
							name="radioGroup"
							onChange={(e, { value }) => {
								setAspectRatio(value);
								setCrop(configCrop[value].crop);
							}}
							value={"4_3"}
							checked={aspectRatio === "4_3"}
						/>
					</Form.Field>
					<Form.Field className={"aspectRadioButton admin-primary"}>
						<Form.Radio
							className={"admin-primary"}
							label="1:1"
							name="radioGroup"
							onChange={(e, { value }) => {
								setAspectRatio(value);
								setCrop(configCrop[value].crop);
							}}
							value={"1_1"}
							checked={aspectRatio === "1_1"}
						/>
					</Form.Field>
					<Form.Field className={"aspectRadioButton admin-primary"}>
						<Form.Radio
							className={"admin-primary"}
							label="Original"
							name="radioGroup"
							onChange={(e, { value }) => {
								setAspectRatio(value);
								setCrop(configCrop[value].crop);
							}}
							value={"original"}
							checked={aspectRatio === "original"}
						/>
					</Form.Field>
				</Form>
				<h3>
					Ordner auswählen
					<Icon.Group
						style={{ marginLeft: "5px", cursor: "pointer" }}
						onClick={() => {
							setIsNewFolder(true);
						}}
					>
						<Icon name="open folder" className={"admin-primary"} />
						<Icon corner name="add" className={"admin-primary"} />
					</Icon.Group>
				</h3>
				{isNewFolder ? (
					<div>
						<input
							className={"admin-primary"}
							placeholder={i18n.t("blog:blog.create_folder_name")}
							value={newFolderName}
							onChange={(e) => setNewFolderName(e.target.value)}
						/>
						<Button
							className={"admin-button-primary"}
							icon={"checkmark"}
							onClick={() => {
								createFolder(newFolderName).then((folders) => {
									setFolders(folders);
									setIsNewFolder(false);
								});
							}}
						/>
						<Button
							className={"admin-button-warn"}
							icon={"delete"}
							onClick={() => setIsNewFolder(false)}
						/>
					</div>
				) : (
					""
				)}
				{activeFolder !== "" && (
					<Button
						floated={"right"}
						content={i18n.t("blog:blog.delete_folder")}
						icon={"trash alternate"}
						className={"admin-button-warn"}
						disabled={activeFolder === ""}
						onClick={() => {
							deleteFolder(activeFolder).then((folders) => {
								setFolders(folders);
							});
						}}
					/>
				)}
				<div
					style={{
						marginTop: "auto",
						marginBottom: "15px",
						marginRight: "5px",
					}}
				>
					{showFolders(folders, setActiveFolder)}
				</div>
				<h3 className={"admin-primary"}>Bild</h3>
				<input
					className={"admin-primary"}
					disabled={activeFolder === ""}
					style={{ marginTop: "auto", marginBottom: "auto" }}
					type="file"
					accept="image/*"
					onChange={(e) =>
						onSelectFile(e, setUploadImageName, setSrc, setError)
					}
				/>
				{error === "filesize" ? (
					<p className={"admin-highlight"}>
						{i18n.t("common:common.file_too_large")} (max. 5MB)
					</p>
				) : null}
				{activeFolder === "" && (
					<p>
						<i className={"admin-highlight"}>
							{i18n.t("common:common.choose_directory")}
						</i>
					</p>
				)}

				{src && ReactCrop && (
					<ReactCrop
						src={src}
						crop={crop}
						disabled={aspectRatio === "original"}
						onImageLoaded={(image) => setImageRef(image)}
						onComplete={(_) =>
							makeClientCrop(crop, setCroppedImageURL, imageRef)
						}
						onChange={(crop) => setCrop(crop)}
					/>
				)}
			</Modal.Content>
			<Modal.Actions className={"admin-primary"}>
				<Grid style={{ marginLeft: "14px" }}>
					<Grid.Column>
						<Button
							floated={"left"}
							disabled={uploadImageName === ""}
							className={"admin-button-primary"}
							icon="checkmark"
							onClick={() => {
								if (aspectRatio === "original") {
									uploadEditCroppedImage(
										src,
										id,
										uploadImageName,
										activeFolder,
										onClose,
										altText,
									);
								} else {
									uploadEditCroppedImage(
										croppedImageURL,
										id,
										uploadImageName,
										activeFolder,
										onClose,
										altText,
									);
								}
							}}
						/>
						<Button
							floated={"left"}
							className={"admin-button-warn"}
							icon="delete"
							onClick={(_) => onClose()}
						/>
					</Grid.Column>
				</Grid>
			</Modal.Actions>
		</Modal>
	);
};

ImageCropComponent.propTypes = {
	cropModal: PropTypes.bool,
	onClose: PropTypes.func,
	getFileName: PropTypes.func,
	id: PropTypes.string,
	altText: PropTypes.string,
};
ImageCropComponent.defaultProps = {
	cropModal: false,
	onClose: () => {},
	getFileName: () => {},
	id: "",
	altText: "",
};

export default withTranslation(["common", "blog"])(ImageCropComponent);
