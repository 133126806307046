import React, {useEffect, useState} from "react";
import {withTranslation} from "react-i18next";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Label from "semantic-ui-react/dist/commonjs/elements/Label";
import * as utils from "../utils";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import PageBuilderEditor from "../../../dragAndDropEditor/PageBuilderEditor";
import AddComponentModal from "../../../addComponentModal/AddComponentModal";
import AddPropsModal from "../../../addPropsModal/AddPropsModal";
import MenuBuilderEditor from "../../../dragAndDropEditor/MenuBuilderEditor";
import HeaderBuilderEditor from "../../../dragAndDropEditor/HeaderBuilderEditor";
import FooterBuilderEditor from "../../../dragAndDropEditor/FooterBuilderEditor";
import Checkbox from "semantic-ui-react/dist/commonjs/modules/Checkbox";

let emitCustomEvent = () => {
};

let useCustomEventListener = () => {

};
if (typeof document !== "undefined") {
    emitCustomEvent = require('react-custom-events').emitCustomEvent;
    useCustomEventListener = require('react-custom-events').useCustomEventListener;
}

//type: page_builder, menu_builder, header_builder, footer_builder
const EditView = (props) => {
    const {type, hierarchy, elements, history, _title, _link, i18n, isSticky = false, isUsed = false} = props;
    const [_elements, setElements] = useState(elements);
    const [title, setTitle] = useState(_title);
    const [originalTitle] = useState(_title);
    const [link, setLink] = useState(_link);
    const [addPropsElement, setAddPropsElement] = useState(elements[0]);
    const [addElementParent, setAddElementParent] = useState(elements[0]);
    const [remove, setRemove] = useState(-1);
    const [definitions, setDefinitions] = useState();
    const [titles, setTitles] = useState([]);
    const [links, setLinks] = useState([]);
    const [categories, setCategories] = useState([]);
    const [addProps, setAddProps] = useState(false);
    const [addElement, setAddElement] = useState(false);
    const [linkUsed, setLinkUsed] = useState(false);
    const [titleUsed, setTitleUsed] = useState(false);
    const [update, setUpdate] = useState(false);
    const [saveWasClicked, setSaveWasClicked] = useState(false);
    const [saved, setSaved] = useState(false);
    const [editMode, setEditMode] = useState(true);
    const [sticky, setSticky] = useState(isSticky);
    const [used] = useState(isUsed);

    useEffect(() => {
        utils.getDefinitions().then(_definitions => {
                setDefinitions(_definitions);
            }
        )
        utils.getCategories().then(_categories => {
            setCategories(_categories);
        })
        utils.getDynamicRoutes().then(dynamicRoutes => {
            utils.parseDynamicRoutes(dynamicRoutes, setTitles, setLinks);
        })
    }, []);

    useEffect(() => {
        if (remove > 0) {
            utils.removeElement(remove, elements, setElements, hierarchy, update, setUpdate);
            setRemove(-1);
        }
    }, [remove]);

    useEffect(() => {
        utils.updateHierarchy(elements, hierarchy);
        setSaved(false);
    }, [elements]);

    useEffect(() => {
        emitCustomEvent("setSaveWasClicked", saveWasClicked);
    }, [saveWasClicked]);

    useEffect(() => {
        emitCustomEvent("setElements", _elements);
    }, [_elements]);

    useEffect(() => {
        emitCustomEvent("setEditMode", editMode);
    }, [editMode]);

    useEffect(() => {
        emitCustomEvent("setTitle", title);
        if(title !== originalTitle)
            utils.updateKeys(hierarchy, _elements, setElements, update, setUpdate);
    }, [title]);

    useEffect(() => {
        emitCustomEvent("setLink", link);
    }, [link]);

    //itemDropped event is emmited by dnd editor when a drag event was successfull and the elements array has changed.
    useCustomEventListener('itemDropped', items => {
        setSaved(false);
        emitCustomEvent("setHierarchy", items);
        utils.updateElements(items, setElements);
    });

    //addProps event is emmited by an element when the add props button is clicked.
    useCustomEventListener('addProps', elementKey => {
        setSaved(false);
        setAddProps(true);
        for (let i = 0; i < elements.length; i++) {
            if (elements[i].key === elementKey)
                setAddPropsElement(elements[i]);
        }
    });

    //addElement event is emmited by an element when the add element button is clicked.
    useCustomEventListener('addElement', parentKey => {
        setSaved(false);
        setAddElement(true);
        for (let i = 0; i < elements.length; i++) {
            if (elements[i].key === parentKey)
                setAddElementParent(elements[i]);
        }
    });

    //removeElement event is emmited by an element when the remove element button is clicked.
    useCustomEventListener('removeElement', elementKey => {
        utils.removeElement(elementKey, elements, setElements, hierarchy, update, setUpdate);
    })

    //copyElement event is emmited by an element when the copy button is clicked.
    useCustomEventListener('copyElement', elementKey => {
        utils.copyElement(elementKey, elements, setElements, hierarchy, update, setUpdate);
    })


    return (
        <Grid
            key={`edit-view-1`}
            className={"admin-primary"}>
            <Grid.Row
                key={`edit-view-2`}
                columns={2}>
                <Grid.Column key={`edit-view-3`} computer={10} style={{display: 'flex'}}>
                    <div className={"admin-labeled-input"}>
                        <label>
                            {i18n.t("common:common.title")}
                        </label>
                        <input
                            key={`edit-view-5`}
                            value={title}
                            onChange={(e) => {
                                utils.validateName(e.target.value, setTitleUsed, titles, setTitle, type);
                                utils.validateLink(e.target.value, setLinkUsed, links, setLink)
                            }}
                        />
                    </div>
                    {
                        type === "page_builder" ?
                            <>
                                <div className={"admin-labeled-input"}>
                                    <label>Link /</label>
                                    <input
                                        key={`edit-view-7`}
                                        value={link}
                                        onChange={(e) => {
                                            utils.validateLink(e.target.value, setLinkUsed, links, setLink)
                                        }}
                                    />
                                </div>
                                {
                                    linkUsed && (link !== title.toLowerCase()) ?
                                        <Label
                                            key={`edit-view-8`}
                                            className={"admin-highlight admin-border-highlight"}>
                                            Link already in use!
                                        </Label>
                                        : null
                                }
                            </>
                            : type === "header_builder" ?
                                <>
                                    <Label
                                        key={`edit-view-9`}
                                        className={"admin-primary"}>sticky</Label>
                                    <Checkbox
                                        key={`edit-view-10`}
                                        className={"admin-primary"}
                                        checked={sticky}
                                        onChange={() => {
                                            setSticky(!sticky);
                                        }}
                                    />
                                </>
                                : null
                    }
                </Grid.Column>
                <Grid.Column
                    key={`edit-view-11`}
                    textAlign={"right"} computer={6}>
                    <Button
                        key={`edit-view-12`}
                        className={"admin-button-primary"}
                        style={{marginRight: '1rem'}}
                        onClick={() => {
                            utils.handleBack(history, title, setTitle, link, setLink, setEditMode, type);
                        }}>
                        {i18n.t("common:common.back")}
                    </Button>
                    <Button
                        key={`edit-view-13`}
                        disabled={(linkUsed && (link !== title.toLowerCase()))}
                        className={"admin-button-save"}
                        style={{marginRight: '1rem'}}
                        onClick={() => {
                            emitCustomEvent("rebuild");
                            setSaved(true);
                            setSaveWasClicked(true);
                            utils.handleSave(title, link, sticky, hierarchy, type, used);
                            setUpdate(!update);
                        }}>
                        {
                            saved ?
                                i18n.t("common:common.saved")
                                : titleUsed ?
                                    i18n.t("common:common.overwrite")
                                    : i18n.t("common:common.save")}
                        <Icon
                            key={`edit-view-14`}
                            style={{marginLeft: "0.5rem"}}
                            name={saved ? 'checkmark' : 'save'}/>
                    </Button>
                    {
                        elements.length > 99 ?
                            <p
                                key={`edit-view-15`}
                                className={"text-warn border-highlight"}
                                style={{textAlign: "center", paddingBlock: "0.5%"}}>
                                <b key={`edit-view-16`}>{i18n.t("adminManagement:adminManagement.limit")} ({elements.length}/100) {i18n.t("adminManagement:adminManagement.limit_info")}</b>
                            </p>
                            : null
                    }
                </Grid.Column>
            </Grid.Row>
            <Grid.Row key={`edit-view-16`}>
                <Grid.Column
                    key={`edit-view-17`}
                    width={16}>
                    {
                        type === "page_builder" ?
                            <PageBuilderEditor
                                key={`edit-view-18`}
                                hierarchy={hierarchy}
                                elements={elements}/>
                            : type === "menu_builder" ?
                                <MenuBuilderEditor
                                    key={`edit-view-19`}
                                    hierarchy={hierarchy}
                                    elements={elements}/>
                                : type === "header_builder" ?
                                    <HeaderBuilderEditor
                                        key={`edit-view-20`}
                                        hierarchy={hierarchy}
                                        elements={elements}/>
                                    : type === "footer_builder" ?
                                        <FooterBuilderEditor
                                            key={`edit-view-21`}
                                            hierarchy={hierarchy}
                                            elements={elements}/>
                                        : null
                    }

                </Grid.Column>
                <Grid.Column key={`edit-view-22`}>
                    {
                        definitions ?
                            <div key={`edit-view-23`}>
                                <AddComponentModal
                                    key={`edit-view-24`}
                                    elements={elements}
                                    setElements={setElements}
                                    element={addElementParent}
                                    hierarchy={hierarchy}
                                    update={update}
                                    setUpdate={setUpdate}
                                    definitions={definitions}
                                    categories={categories}
                                    open={addElement}
                                    setOpen={setAddElement}
                                    title={title}
                                    link={link}/>

                                <AddPropsModal
                                    key={`edit-view-25`}
                                    definitions={definitions}
                                    elements={elements}
                                    element={addPropsElement}
                                    setElements={setElements}
                                    hierarchy={hierarchy}
                                    setUpdate={setUpdate}
                                    update={update}
                                    open={addProps}
                                    setOpen={setAddProps}/>
                            </div>
                            : <div key={`edit-view-26`}/>}
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
}

export default withTranslation(['common', 'adminManagement'])(EditView);