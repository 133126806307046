import React, {useEffect, useState} from "react";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";

let emitCustomEvent = () => {
};
let useCustomEventListener = () => {
};

if (typeof document !== "undefined") {
    emitCustomEvent = require('react-custom-events').emitCustomEvent;
    useCustomEventListener = require('react-custom-events').useCustomEventListener;
}

const ColorPicker = ({theme, index, value}) => {
    const [color, setColor] = useState(value.color);

    useEffect(()=>{
        setColor(value.color);
    },[value])
    const onColorChange = (event) => {
        setColor(event.target.value);
        let parameters = {
            color: event.target.value,
            index: index,
            theme: theme
        }
        emitCustomEvent("colorChanged", parameters);
    };

    useCustomEventListener("themeChanged", newTheme => {
        setColor(newTheme[theme][index].color)
    });

    return (
        <Grid.Row key={`color-picker-0`}>
            <Grid.Column key={`color-picker-1`}>
                <div
                    key={`color-picker-2`}
                    style={{display: "flex"}}>
                    <label
                        key={`color-picker-3`}
                        style={{
                            background: color,
                            border: "1px solid #424861",
                            borderRadius: "50%",
                            width: "5rem",
                            height: "5rem",
                            marginLeft: "auto",
                            marginRight: "auto"
                        }}
                        htmlFor={`color-${index}`}>
                        <input
                            key={`color-picker-4`}
                            id={`color-${index}`}
                            style={{visibility: "hidden"}}
                            className={'color-color-picker admin-primary'}
                            type={'color'}
                            value={value.color}
                            onChange={e => {
                                onColorChange(e);
                            }}/>
                    </label>
                </div>
            </Grid.Column>
            <Grid.Column key={`color-picker-5`}>
                <input
                    key={`color-picker-6`}
                    style={{
                        width: 80,
                        textAlign: 'center',
                        padding: 5,
                        borderRadius: 8,
                        marginTop: 15
                    }}
                    className={value.hexError ? 'input-error admin-border-highlight admin-primary' : 'theme-input admin-border-primary admin-primary'}
                    type={'text'}
                    value={color}
                    onChange={onColorChange}/>
            </Grid.Column>
        </Grid.Row>
    )
}

export default ColorPicker;